import React from 'react';
import ReasonManagement from './ReasonManagement';

const reasonTypeOptions = {
  reprint: {
    costCoverOptions: [
      { value: 'merchant', label: 'Merchant' },
      { value: 'vendor', label: 'Vendor' }
    ]
  }
};

const ReasonsManagement = ({ merchantId }) => {
  return (
    <div className="reasons-management">
      <div>
        <ReasonManagement
          merchantId={merchantId}
          reasonType="reprint"
          title="Reprint Reasons"
          reasonOptions={reasonTypeOptions.reprint}
        />
      </div>
    </div>
  );
};

export default ReasonsManagement;
