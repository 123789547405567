import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Table, TableHeader, LoadingAnimation, Card, MessageBar } from 'app/components';
import { Download } from 'react-bootstrap-icons';
import ExportFilters from '../ExportFilters';
import './index.scss';

const PreviewSection = ({
  selectedColumns,
  exportPreviewData,
  exportPreviewDataLoading,
  exportPreviewDataErrorsFound,
  activeFilters,
  onFilterChange,
  activeFilter,
  activeFilterColumn,
  onCloseFilter,
  onFilterClick,
  formatTableHeader,
  onDownloadPreview,
  onRetry,
  availableColumns,
  onColumnsReordered
}) => {
  const [orderedColumns, setOrderedColumns] = useState([]);
  
  // Initialize ordered columns when selectedColumns changes
  useEffect(() => {
    setOrderedColumns([...selectedColumns]);
  }, [selectedColumns]);
  
  const formatCellValue = (column, value) => {
    if (value === null || value === undefined) {
      return '';
    }

    const columnType = availableColumns?.columns?.find(col => col.name === column)?.type;

    // Handle boolean values
    if (columnType?.toLowerCase() === 'bool') {
      return value ? 'True' : 'False';
    }

    // Handle date values
    if (columnType?.toLowerCase() === 'date') {
      const date = moment(value, moment.ISO_8601, true);
      if (date.isValid()) {
        // Check if time components are all zeros
        return date.hour() === 0 && date.minute() === 0 && date.second() === 0
          ? date.format('YYYY-MM-DD')
          : date.format('YYYY-MM-DD HH:mm:ss');
      }
    }

    return value;
  };
  
  const handleColumnReorder = (sourceColumnId, targetColumnId) => {
    // Create a copy of the current column order
    const columns = [...orderedColumns];
    
    // Find the indexes of the source and target columns
    const sourceIndex = columns.indexOf(sourceColumnId);
    const targetIndex = columns.indexOf(targetColumnId);
    
    if (sourceIndex === -1 || targetIndex === -1) return;
    
    // Remove the source column
    columns.splice(sourceIndex, 1);
    
    // Insert it at the target position
    columns.splice(targetIndex, 0, sourceColumnId);
    
    // Update the local state
    setOrderedColumns(columns);
    
    // Notify parent component if callback exists
    if (onColumnsReordered) {
      onColumnsReordered(columns);
    }
  };

  const renderPreviewTable = () => {
    if (orderedColumns.length === 0) {
      return (
        <div className="no-columns-message">
          Select columns on the left to preview data
        </div>
      );
    }

    if (exportPreviewDataErrorsFound) {
      return (
        <MessageBar color="yellow" className="preview-error-message">
          The Data Warehouse is booting up. Please try again shortly.{' '}
          <Button
            variant="primary"
            size="tiny"
            label="Retry"
            onClick={onRetry}
          />
        </MessageBar>
      );
    }

    return (
      <>
        <ExportFilters
          activeFilters={activeFilters}
          onFilterChange={onFilterChange}
          activeFilter={activeFilter}
          activeFilterColumn={activeFilterColumn}
          onCloseFilter={onCloseFilter}
        />
        <div className="preview-table">
          {exportPreviewDataLoading && (
            <>
              <div className="loading-overlay" />
              <LoadingAnimation />
            </>
          )}
          <div className="table-scroll-container">
            <Table size="medium" highlightRows>
              <TableHeader
                onFilterClick={onFilterClick}
                onColumnReorder={handleColumnReorder}
                options={orderedColumns.map(column => ({
                  id: column,
                  label: formatTableHeader(column),
                  width: 'auto',
                  align: 'left',
                  filterActive: activeFilters[column] !== undefined
                }))}
              />
              <tbody className="table-body">
                {exportPreviewData?.data?.length === 0 ? (
                  <tr>
                    <td colSpan={orderedColumns.length} className="no-results-message">
                      No results found matching your filter criteria
                    </td>
                  </tr>
                ) : (
                  exportPreviewData?.data?.map((row, index) => (
                    <tr key={index}>
                      {orderedColumns.map(column => (
                        <td key={column}>{formatCellValue(column, row[column])}</td>
                      ))}
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </>
    );
  };

  return (
    <Card className="data-preview">
      <div className="section-header">
        <div className="section-title">Preview Data</div>
        {orderedColumns.length > 0 && (
          <Button
            icon={<Download />}
            onClick={onDownloadPreview}
            className="download-preview-button"
            variant="secondary"
            size="small"
            label="Download Preview Data"
            disabled={orderedColumns.length === 0 || !exportPreviewData?.data?.length}
          />
        )}
      </div>
      <div className="section-info">If no date-related columns are selected, the export will be sorted by Order Creation Date (newest first) and limited to 1 year of data. To adjust the date range, select a date column and apply a filter.</div>
      {renderPreviewTable()}
    </Card>
  );
};

export default PreviewSection;
