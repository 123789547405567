import React from 'react';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { Modal, Button, Dropdown, Input } from 'app/components';
import './index.scss';

const StringFilter = (props) => {
  const { title, onClose, onApply, onClear, currentFilter } = props;

  return (
    <Formik
      initialValues={{
        condition: currentFilter?.type || '',
        value: currentFilter?.value || '',
      }}
      validationSchema={object().shape({
        condition: string().required('Condition is required'),
        value: string().required('Value is required'),
      })}
      onSubmit={(values) => {
        onApply({
          columnType: 'string',
          type: values.condition,
          value: values.value,
        });
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        submitCount,
        setFieldValue,
      }) => (
        <Modal
          className="string-filter"
          title={title}
          hideButtons={true}
          onClose={onClose}
        >
          <Dropdown
            label="Condition"
            name="condition"
            value={values.condition}
            onChange={(e) => {
              handleChange(e);
              setFieldValue('condition', e.target.value);
            }}
            options={[
              { value: 'equals', label: 'Equals' },
              { value: 'notEquals', label: 'Does Not Equal' },
              { value: 'contains', label: 'Contains' },
              { value: 'startsWith', label: 'Starts With' },
              { value: 'endsWith', label: 'Ends With' },
            ]}
            errorMessage={submitCount > 0 && errors.condition}
          />
          <Input
            label="Value"
            name="value"
            value={values.value}
            onChange={(e) => {
              handleChange(e);
              setFieldValue('value', e.target.value);
            }}
            errorMessage={submitCount > 0 && errors.value}
          />
          <div className="action-buttons">
            <Button
              variant="secondary"
              size="small"
              label="Cancel"
              onClick={onClose}
            />
            <Button
              variant="secondary"
              size="small"
              label="Clear Filter"
              onClick={onClear}
            />
            <Button
              variant="primary"
              size="small"
              label="Apply Filter"
              onClick={handleSubmit}
              disabled={!values.condition || !values.value}
            />
          </div>
        </Modal>
      )}
    </Formik>
  );
};

export default StringFilter;
