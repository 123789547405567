import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Card, ButtonIcon, Button, TextArea, LoadingAnimation, MessageBar, Modal } from 'app/components'
import { getTransformationDetails, addCustomizationToTransformation, reset as resetTransformations } from 'app/store/actions/transformation';
import { webhookDetailsSelector } from 'app/store/selectors/notification';
import { transformationSelector, transformationLoadingSelector, transformationErrorSelector } from 'app/store/selectors/transformation';
import { ArrowLeft } from 'react-bootstrap-icons';
import Editor from '@monaco-editor/react';
import './index.scss';

const Customize = () => {
  const navigate = useNavigate();
  const { webhookId } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [highlighted, setHighlighted] = useState(null);
  const [lastTestStatus, setLastTestStatus] = useState(null);
  const [codeSnippet, setCodeSnippet] = useState('// add your code here');
  const [showConfirmSaveModal, setShowConfirmSaveModal] = useState(null);

  const event = searchParams.get('event');
  const subscriberId = searchParams.get('subscriberId');
  const transformationId = searchParams.get('transformationId');

  const webhookDetails = useSelector(webhookDetailsSelector);
  const transformationDetails = useSelector(transformationSelector);
  const transformationLoading = useSelector(transformationLoadingSelector);
  const transformationError = useSelector(transformationErrorSelector);

  useEffect(() => {
    // if no webhook details, forward them back to the webhook details page
    if (!webhookDetails) {
      navigate(`/admin/webhooks/${webhookId}`);
    } else {
      dispatch(resetTransformations());
      if (transformationId) {
        dispatch(getTransformationDetails({ transformationId }));
      }
    }
  }, [transformationId]);

  const editorOptions = {
    overviewRulerLanes: 0,
    renderLineHighlight: 'none',
    cursorBlinking: 'blink',
    automaticLayout: true,
    minimap: { enabled: false },
    readOnly: subscriberId ? false : true,
  };

  const testEditorOptions = {
    overviewRulerLanes: 0,
    renderLineHighlight: 'none',
    cursorBlinking: 'blink',
    automaticLayout: true,
    minimap: { enabled: false },
  };

  const handleSave = (data) => {
    setShowConfirmSaveModal(null);
    dispatch(addCustomizationToTransformation({ 
      transformationId, 
      data, 
      webhookDetails, 
      cb: (response) => {
        if (response) {
          dispatch(resetTransformations());
          navigate(`/admin/webhooks/${webhookId}/edit`);
        }
      }
    }));
  };

  const attemptSave = () => {
    const data = {
      "code": codeSnippet,
      "creatorId": subscriberId,
      "creatorType": "admin",
      "topic": event,
      "transformationType": "outbound",
      "entityId": subscriberId,
    };

    if (webhookDetails?.isEnabled) {
      setShowConfirmSaveModal(data);
    } else {
      handleSave(data);
    }
  };

  return (
    <div className="webhook-customize">
      {transformationLoading && <LoadingAnimation />}
      <Card className="webhook-customize-header">
        <div className="webhook-name">
          <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate(`/admin/webhooks/${webhookId}`)} />
          {!subscriberId ? 'View Customization' : transformationId ? 'Edit Customization' : 'Create Customization'}
        </div>
        {(!transformationId || (!transformationLoading && transformationDetails)) && (
          <div className="action-buttons">
            <Button
              variant="secondary"
              size="medium"
              label={subscriberId ? 'Cancel' : 'Back'}
              onClick={() => navigate(`/admin/webhooks/${webhookId}/edit`)}
            />
            {subscriberId && (
              <Button
                size="medium"
                label="Save"
                disabled={transformationError}
                onClick={attemptSave}
              />
            )}
          </div>
        )}
      </Card>
      {(!transformationId || (!transformationLoading && transformationDetails)) && (
        <>
          <Card className="webhook-customize-details">
            <Card.Header>
              Snippet Details
            </Card.Header>
            <Card.Body>
              <div>Code Snippet</div>
              <Editor
                className="code-snippet"
                options={editorOptions}
                height="250px"
                defaultLanguage="javascript"
                defaultValue={transformationDetails?.snippets?.[transformationDetails?.snippets?.length - 1]?.code || ''}
                onChange={(value) => setCodeSnippet(value)}
              />
            </Card.Body>
          </Card>

          <Card className="webhook-snippet-details">
            <Card.Header>
              Test Code Snippet
            </Card.Header>
            <Card.Body className="test-snippet-body">
              <div className="test-snippet-code">
                <div>Input String</div>
                <Editor
                  className="code-snippet"
                  options={testEditorOptions}
                  height="250px"
                  defaultLanguage="javascript"
                  defaultValue="// add your input string here"
                />
              </div>
              <Button
                size="medium"
                variant="secondary"
                label="Test Code Snippet"
                onClick={() => {
                  let status = null;

                  if (highlighted) {
                    clearTimeout(highlighted);
                    setHighlighted(null);
                    setLastTestStatus(null);
                  }

                  // get the value from the code snippet editor
                  const codeSnippet = window.monaco.editor.getModels()[0].getValue();
                  const snippetInput = window.monaco.editor.getModels()[1].getValue();

                  try {
                    const dynamicFunction = new Function("payload", codeSnippet);
                    const result = dynamicFunction(JSON.parse(snippetInput));
                    const resultJSON = JSON.stringify(result, null, 2);

                    document.getElementById('snippetOutput').value = resultJSON;
                    status = 'success';
                  } catch (error) {
                    console.error('Error in running the code snippet', error);
                    document.getElementById('snippetOutput').value = error;
                    status = 'error';
                  }
                  requestAnimationFrame(() => {
                    const timeoutId = setTimeout(() => {
                      setHighlighted(null);
                      setLastTestStatus(null);
                    }, 2500);
                    setLastTestStatus(status);
                    setHighlighted(timeoutId);
                  });
                }}
              />
              <div className="test-snippet-code">
                <TextArea
                  id="snippetOutput"
                  label="Customization Results"
                  className={`code-snippet-output ${highlighted ? `highlight-animation-${lastTestStatus}` : ''}`}
                  name="snippetOutput"
                  readonly={true}
                />
              </div>
            </Card.Body>
          </Card>
        </>
      )}
      {transformationError && (
        <MessageBar color="yellow">
          An error occurred while fetching data
        </MessageBar>
      )}
      {showConfirmSaveModal && (
        <Modal
          title="Saving Active Webhook"
          primaryButtonLabel="Save Changes"
          primaryButtonVariant="primary"
          primaryButtonOnClick={() => handleSave(showConfirmSaveModal)}
          secondaryButtonLabel="Cancel"
          secondaryButtonOnClick={() => setShowConfirmSaveModal(null)}
          onClose={() => setShowConfirmSaveModal(null)}
        >
          <div>You are making changes to an active webhook.</div>
          <div>If you don&apos;t want these changes to take effect, deactivate the webhook first.</div>
        </Modal>
      )}
    </div>
  )
}

export default Customize;
