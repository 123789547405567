export const convertDataSetToCSV = (dataSet) => {
  if (dataSet === null || dataSet === undefined) return;

  // first create header row...
  const header = Object.keys(dataSet.values[0]).map(key => key);

  // then create data rows from values...
  const rows = dataSet.values.map((value) => {
    return Object.keys(value).map(key => value[key]);
  });

  // merge header and rows...
  const csv = [header, ...rows].map(row => row.join(',')).join('\n');

  return csv;
}

export const downloadCSV = (csvString, filename) => {
  const blob = new Blob([csvString], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

export const convertDataSetValuesToCSVValues = (values) => {
  return values.map(value => {
    const newValue = {};
    Object.keys(value).forEach(key => {
      newValue[key] = value[key];
    });
    return newValue;
  });
}

export const convertDataSetToTableItems = (dataSet) => {
  if (dataSet === null || dataSet === undefined) return;

  const items = dataSet.variables.map(v => {
    const sapmpleRow = dataSet.values[0];
    return {
      "column-header": v.name,
      "sample-row": sapmpleRow[v.name],
      "map-to": v.mapTo,
      "source-type": v.type,
      "use-for": v.useFor.join(','),
      "default-value": v.defaultValue
    }
  });

  return items;
}