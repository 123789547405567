import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { convertCountryCodeToName } from 'app/utils'
import { PencilSquare } from 'react-bootstrap-icons';
import { Input, Dropdown, ButtonIcon, Card, Button } from 'app/components'
import { CountryList } from 'app/constants';
import { createMerchant, updateMerchant } from 'app/store/actions/merchant';
import DataPoint from 'app/components/DataPoint';
import { usePermission } from 'app/permissions';
import './index.scss';
import PropTypes from 'prop-types';
import { MerchantValidation } from './validation';

const MerchantCard = props => {
  const { merchantDetails } = props;
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // get user permissions
  const canUpdateMerchants = usePermission('merchant', 'create');

  useEffect(() => {
    if (!merchantDetails) {
      setEditMode(true);
    }
  }, []);

  const onMerchantCreated = (merchantId) => {
    navigate(`/admin/merchants/${merchantId}`);
  }

  const onMerchantUpdated = () => {
    setEditMode(false);
  }

  const customReturnAddressEditSection = (values, handleChange, submitCount, errors) => {
    return (
      <div>
        <div className="address-information">Custom Return Address</div>
        <div className={`merchant-information ${!merchantDetails ? 'new-merchant' : ''}`}>
          <Input
            label="Address Line 1"
            name="customReturnAddress.addressLine1"
            value={values.customReturnAddress.addressLine1}
            onChange={handleChange}
            placeholder="Street Address"
            errorMessage={submitCount > 0 && errors.customReturnAddress?.addressLine1}
          />
          <Input
            label="Address Line 2"
            name="customReturnAddress.addressLine2"
            value={values.customReturnAddress.addressLine2}
            onChange={handleChange}
            placeholder="Apt, Suite, unit, building, floor, etc"
            errorMessage={submitCount > 0 && errors.customReturnAddress?.addressLine2}
          />
          <Input
            label="City"
            name="customReturnAddress.city"
            value={values.customReturnAddress.city}
            onChange={handleChange}
            placeholder="City"
            errorMessage={submitCount > 0 && errors.customReturnAddress?.city}
          />
          <Input
            label="State / Province"
            name="customReturnAddress.state"
            value={values.customReturnAddress.state}
            onChange={handleChange}
            placeholder="State / Province"
            errorMessage={submitCount > 0 && errors.customReturnAddress?.state}
          />
          <Input
            label="Zip / Postal Code"
            name="customReturnAddress.postalCode"
            value={values.customReturnAddress.postalCode}
            onChange={handleChange}
            placeholder="Zip / Postal Code"
            errorMessage={submitCount > 0 && errors.customReturnAddress?.postalCode}
          />
          <Dropdown
            label="Country"
            name="customReturnAddress.countryCode"
            value={values.customReturnAddress.countryCode}
            onChange={handleChange}
            options={CountryList.map(country => (
              { value: country.CountryCode, label: `${country.CountryCode} - ${country.Name}` }
            ))}
            errorMessage={submitCount > 0 && errors.customReturnAddress?.countryCode}
          />
          <Input
            label="Phone"
            name="customReturnAddress.phone"
            value={values.customReturnAddress.phone}
            onChange={handleChange}
            placeholder="Phone"
            errorMessage={submitCount > 0 && errors.customReturnAddress?.phone}
          />
          <Input
            label="Email"
            name="customReturnAddress.email"
            value={values.customReturnAddress.email}
            onChange={handleChange}
            placeholder="Email"
            errorMessage={submitCount > 0 && errors.customReturnAddress?.email}
          />
        </div>
      </div>);
  }

  const customAddressDisplaySection = () => {
    const address = merchantDetails?.customReturnAddress;
    return address !== null && address !== undefined && address.addressLine1.length ? (
      <div>
        <div className="address-information view-mode">Custom Return Address</div>
        <div className="merchant-information">
          <DataPoint title="Address Line 1" data={address.addressLine1} />
          {address.addressLine2 && <DataPoint title="Address Line 2" data={address.addressLine2} />}
          <DataPoint title="City" data={address.city} />
          <DataPoint title="State / Province" data={address.state} />
          <DataPoint title="Zip / Postal Code" data={address.postalCode} />
          <DataPoint title="Country" data={convertCountryCodeToName(address.countryCode)} />
          <DataPoint title="Phone" data={address.phone} />
          <DataPoint title="Email" data={address.email} />
        </div>
      </div>
    ) :
      <div>
        <div className="address-information view-mode">Custom Return Address</div>
        <span>No address defined</span>
      </div>;
  };

  return (
    <Card className="merchant-card">
      <Card.Header>
        Merchant Details
        {!editMode && canUpdateMerchants && (
          <ButtonIcon
            icon={<PencilSquare />}
            onClick={() => setEditMode(true)}
          />
        )}
      </Card.Header>
      <Card.Body>
        {!editMode && merchantDetails && (
          <>
            <div className="merchant-information">
              <DataPoint title="Legal Name" data={merchantDetails.legalBusinessName} />
              <DataPoint title="Name" data={merchantDetails.name} />
              <DataPoint title="Email" data={merchantDetails.email} />
            </div>
            <div className="address-information view-mode">Address Information</div>
            <div className="merchant-information">
              <DataPoint title="Address Line 1" data={merchantDetails.address1} />
              {merchantDetails.address2 && <DataPoint title="Address Line 2" data={merchantDetails.address2} />}
              <DataPoint title="City" data={merchantDetails.city} />
              <DataPoint title="State / Province" data={merchantDetails.state} />
              <DataPoint title="Zip / Postal Code" data={merchantDetails.zipCode} />
              <DataPoint title="Country" data={convertCountryCodeToName(merchantDetails.country)} />
            </div>
            {customAddressDisplaySection()}
          </>
        )}
        {editMode && (
          <Formik
            enableReinitialize
            initialValues={{
              merchantId: merchantDetails?.id || '',
              merchantLegalName: merchantDetails?.legalBusinessName || '',
              email: merchantDetails?.email || '',
              merchantName: merchantDetails?.name || '',
              address1: merchantDetails?.address1 || '',
              address2: merchantDetails?.address2 || '',
              city: merchantDetails?.city || '',
              state: merchantDetails?.state || '',
              zipPostalCode: merchantDetails?.zipCode || '',
              countryCode: merchantDetails?.country || 'US',
              customReturnAddress: {
                countryCode: merchantDetails?.customReturnAddress?.countryCode || '',
                state: merchantDetails?.customReturnAddress?.state || '',
                city: merchantDetails?.customReturnAddress?.city || '',
                addressLine1: merchantDetails?.customReturnAddress?.addressLine1 || '',
                addressLine2: merchantDetails?.customReturnAddress?.addressLine2 || '',
                postalCode: merchantDetails?.customReturnAddress?.postalCode || '',
                phone: merchantDetails?.customReturnAddress?.phone || '',
                email: merchantDetails?.customReturnAddress?.email || '',
                firstName: merchantDetails?.customReturnAddress?.firstName || '',
                lastName: merchantDetails?.customReturnAddress?.lastName || ''
              }
            }}
            validationSchema={() =>
              object().shape({
                merchantLegalName: string().required('Enter a valid Merchant Legal Name'), // required
                merchantName: string().required('Enter a valid Merchant Name'), // required
                email: MerchantValidation.email(true),
                address1: MerchantValidation.address1(true),
                address2: MerchantValidation.address2(false),
                city: MerchantValidation.city(true),
                state: MerchantValidation.state(false),
                zipPostalCode: MerchantValidation.postalCode(false),
                countryCode: MerchantValidation.countryCode(true),
                customReturnAddress: object().shape({
                  addressLine1: MerchantValidation.address1(false),
                  addressLine2: MerchantValidation.address2(false),
                  city: MerchantValidation.city(false),
                  state: MerchantValidation.state(false),
                  postalCode: MerchantValidation.postalCode(false),
                  countryCode: MerchantValidation.countryCode(false),
                  email: MerchantValidation.email(false),
                })
              })}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              const data = {
                "legalBusinessName": values.merchantLegalName,
                "name": values.merchantName,
                "email": values.email,
                "address1": values.address1,
                "address2": values.address2,
                "city": values.city,
                "state": values.state,
                "zipCode": values.zipPostalCode,
                "country": values.countryCode,
                "customReturnAddress": {
                  "countryCode": values.customReturnAddress.countryCode,
                  "state": values.customReturnAddress.state,
                  "city": values.customReturnAddress.city,
                  "addressLine1": values.customReturnAddress.addressLine1,
                  "addressLine2": values.customReturnAddress.addressLine2,
                  "postalCode": values.customReturnAddress.postalCode,
                  "phone": values.customReturnAddress.phone,
                  "email": values.customReturnAddress.email,
                  "firstName": values.customReturnAddress.firstName,
                  "lastName": values.customReturnAddress.lastName
                }
              };

              if (merchantDetails) {
                // update existing merchant
                data.id = merchantDetails.id;
                data.isParent = merchantDetails.isParent;
                data.parentId = merchantDetails.parent?.id;

                dispatch(updateMerchant({ data, cb: onMerchantUpdated }));
              } else {
                // create new merchant
                dispatch(createMerchant({ data, cb: onMerchantCreated }));
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              submitCount,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className={`merchant-information ${!merchantDetails ? 'new-merchant' : ''}`}>
                  <Input
                    label="Legal Name"
                    name="merchantLegalName"
                    value={values.merchantLegalName}
                    onChange={handleChange}
                    placeholder="Legal Name"
                    errorMessage={submitCount > 0 && errors.merchantLegalName}
                  />
                  <Input
                    label="Merchant Name"
                    name="merchantName"
                    value={values.merchantName}
                    onChange={handleChange}
                    placeholder="Merchant Name"
                    errorMessage={submitCount > 0 && errors.merchantName}
                  />
                  <Input
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    placeholder="Email"
                    errorMessage={submitCount > 0 && errors.email}
                  />
                </div>
                <div className="address-information">Address Information</div>
                <div className={`merchant-information ${!merchantDetails ? 'new-merchant' : ''}`}>
                  <Input
                    label="Address Line 1"
                    name="address1"
                    value={values.address1}
                    onChange={handleChange}
                    placeholder="Street Address"
                    errorMessage={submitCount > 0 && errors.address1}
                  />
                  <Input
                    label="Address Line 2"
                    name="address2"
                    value={values.address2}
                    onChange={handleChange}
                    placeholder="Apt, Suite, unit, building, floor, etc"
                    errorMessage={submitCount > 0 && errors.address2}
                  />
                  <Input
                    label="City"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    placeholder="City"
                    errorMessage={submitCount > 0 && errors.city}
                  />
                  <Input
                    label="State / Province"
                    name="state"
                    value={values.state}
                    onChange={handleChange}
                    placeholder="State / Province"
                    errorMessage={submitCount > 0 && errors.state}
                  />
                  <Input
                    label="Zip / Postal Code"
                    name="zipPostalCode"
                    value={values.zipPostalCode}
                    onChange={handleChange}
                    placeholder="Zip / Postal Code"
                    errorMessage={submitCount > 0 && errors.zipPostalCode}
                  />
                  <Dropdown
                    label="Country"
                    name="countryCode"
                    value={values.countryCode}
                    onChange={handleChange}
                    options={CountryList.map(country => (
                      { value: country.CountryCode, label: `${country.CountryCode} - ${country.Name}` }
                    ))}
                    errorMessage={submitCount > 0 && errors.countryCode}
                  />
                </div>
                {customReturnAddressEditSection(values, handleChange, submitCount, errors)}
                <div className="action-buttons">
                  <Button
                    variant="secondary"
                    size="small"
                    label="Cancel"
                    disabled={isSubmitting}
                    onClick={() => !merchantDetails ? navigate('/admin/merchants/') : setEditMode(false)}
                  />
                  <Button
                    variant="primary"
                    size="small"
                    label={!merchantDetails ? 'Create Merchant' : 'Update'}
                    disabled={isSubmitting}
                    onClick={() => (isSubmitting ? null : handleSubmit())}
                  />
                </div>
              </form>
            )}
          </Formik>
        )}
      </Card.Body>
    </Card>
  )
}

MerchantCard.propTypes = {
  merchantDetails: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    email: PropTypes.string,
    enabled: PropTypes.bool,
    id: PropTypes.string,
    isParent: PropTypes.bool,
    legalBusinessName: PropTypes.string,
    name: PropTypes.string,
    parent: PropTypes.shape({
      id: PropTypes.string
    }),
    state: PropTypes.string,
    zipCode: PropTypes.string,
  })
};

export default MerchantCard;