import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { object, string, number } from 'yup';
import { Modal, Button, Dropdown, Input, TextArea } from 'app/components';
import { cancelOrder } from 'app/store/actions/order';
import './index.scss';

const CancelOrderItemModal = (props) => {
  const { orderId, itemInfo, showCancelOrderItemModal } = props;
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        quantity: itemInfo?.quantity,
        reason: '',
        otherReason: '',
      }}
      validationSchema={object().shape({
        quantity: number().when('itemInfo', {
          is: itemInfo => itemInfo && itemInfo.quantity,
          then: () => number()
            .required('Quantity is required')
            .min(1, 'Quantity must be at least 1')
            .max(itemInfo?.quantity, `Quantity must be at most ${itemInfo?.quantity}`),
          otherwise: () => number().notRequired()
        }),
        reason: string().required('Reason is required'),
        otherReason: string().when('reason', {
          is: reason => reason === 'Other',
          then: () => string().required('Other reason is required when reason is set to other'),
        }),
      })}
      onSubmit={(values) => {
        const { quantity, reason } = values;
        const data = {
          cancelReason: values.otherReason || reason,
          triggerFlow: true,
        };

        if (itemInfo) {
          data.orderItems = [{
            id: itemInfo?.id,
            quantity,
          }];
        }

        dispatch(cancelOrder({ orderId, data }));
        showCancelOrderItemModal(null);
      }}
    >
      {
      ({ values, errors, handleChange, handleSubmit, submitCount }) => (
        <Modal
          className="cancel-order-item-modal"
          title={`Cancel Item ${itemInfo?.shortId || itemInfo?.id}`}
          hideButtons={true}
          onClose={() => { showCancelOrderItemModal(null); }}
        >
          {itemInfo?.quantity > 1 && itemInfo?.quantity <= 10 && (
            <Dropdown
              label="Quantity to Cancel"
              name="quantity"
              value={values.quantity}
              onChange={handleChange}
              options={Array.from({ length: itemInfo?.quantity }, (_, i) => ({ value: i + 1, label: `${i + 1} ${i + 1 === itemInfo?.quantity ? '(All)' : ''}` }))}
            />
          )}
          {itemInfo?.quantity > 10 && (
            <Input
              type="number"
              label={`Quantity to Cancel (Max: ${itemInfo?.quantity})`}
              name="quantity"
              value={values.quantity}
              onChange={handleChange}
              placeholder="Enter the quantity to cancel"
              errorMessage={submitCount > 0 && errors.quantity}
            />
          )}
          <Dropdown
            label="Reason to Cancel the Item"
            name="reason"
            value={values.reason}
            onChange={handleChange}
            options={[
              { value: 'Damaged', label: 'Damaged' },
              { value: 'Wrong Item', label: 'Wrong Item' },
              { value: 'Other', label: 'Other' },
            ]}
            errorMessage={submitCount > 0 && errors.reason}
          />
          {values.reason === 'Other' && (
            <TextArea
              label="Other Reason"
              name="otherReason"
              value={values.otherReason}
              onChange={handleChange}
              placeholder="Add other reason here"
              errorMessage={submitCount > 0 && errors.otherReason}
            />
          )}
          <div className="action-buttons">
            <Button
              variant="secondary"
              size="small"
              label="Cancel"
              onClick={() => { showCancelOrderItemModal(null); }}
            />
            <Button
              variant="primary"
              size="small"
              label="Cancel Item"
              onClick={handleSubmit}
            />
          </div>
        </Modal>
      )}
    </Formik>
  );
};

export default CancelOrderItemModal;
