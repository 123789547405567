import { useEffect } from 'react';
import HistoryView from "./HistoryView";
import NotesView from "./NotesView";
import { useSelector } from 'react-redux';
import { orderDetailsSelector } from 'app/store/selectors/order';
import { historyLoadingSelector } from 'app/store/selectors/history';
import { shipmentsLoadingSelector } from 'app/store/selectors/shipment';
import PropTypes from 'prop-types';
import "./index.scss";

const HistoryAndNotesView = props => {
  const orderDetails = useSelector(orderDetailsSelector);
  const historyDataLoading = useSelector(historyLoadingSelector);
  const shipmentsLoading = useSelector(shipmentsLoadingSelector);

  useEffect(() => {
    const shouldScroll = window.location.hash === '#notes-section';
    if (shouldScroll && !historyDataLoading && !shipmentsLoading) {
      const element = document.getElementById('notes-section');
      if (element) {
        const headerOffset = 100;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - headerOffset;
        
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  }, [historyDataLoading, shipmentsLoading]);

  return (
    <div className="history-and-notes-view">
      <div className="history-and-notes-cards">
        <HistoryView  {...props} />
        <div id="notes-section">
          <NotesView entityType="Order" entityId={props?.orderId} merchantId={orderDetails?.merchantId} vendorId={null} />
        </div>
      </div>
    </div>
  );
};

HistoryAndNotesView.propTypes = {
  orderId: PropTypes.string.isRequired
};
export default HistoryAndNotesView;
