import React from 'react';
import { Formik } from 'formik';
import { object, number, string } from 'yup';
import { Modal, Button, Dropdown, Input } from 'app/components';
import './index.scss';

const NumericFilter = (props) => {
  const { title, onClose, onApply, onClear, currentFilter } = props;

  const getInitialValues = () => {
    if (!currentFilter) return { condition: '', value: '', value2: '' };

    return {
      condition: currentFilter.type || '',
      value: currentFilter.value || '',
      value2: currentFilter.value2 || '', // For "between" condition
    };
  };

  const getValidationSchema = (values) => {
    const baseSchema = {
      condition: string().required('Condition is required'),
      value: number()
        .typeError('Must be a number')
        .required('Value is required'),
    };

    if (values.condition === 'between') {
      baseSchema.value = number()
        .typeError('Must be a number')
        .required('From value is required');

      baseSchema.value2 = number()
        .typeError('Must be a number')
        .required('To value is required')
        .test('greater-than-value1', 'To value must be greater than From value',
          function (value2) {
            const value1 = Number(this.parent.value);
            return !value2 || !value1 || value2 > value1;
          }
        );
    }

    return object().shape(baseSchema);
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validate={(values) => {
        try {
          getValidationSchema(values).validateSync(values, { abortEarly: false });
        } catch (err) {
          return err.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {});
        }
      }}
      onSubmit={(values) => {
        onApply({
          columnType: 'numeric',
          type: values.condition,
          value: Number(values.value),
          ...(values.condition === 'between' && { value2: Number(values.value2) }),
        });
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        submitCount,
        setFieldValue,
      }) => (
        <Modal
          className="numeric-filter"
          title={title}
          hideButtons={true}
          onClose={onClose}
        >
          <Dropdown
            label="Condition"
            name="condition"
            value={values.condition}
            onChange={(e) => {
              handleChange(e);
              setFieldValue('condition', e.target.value);
              if (e.target.value !== 'between') {
                setFieldValue('value2', '');
              }
            }}
            options={[
              { value: 'equals', label: 'Equals' },
              { value: 'notEquals', label: 'Does Not Equal' },
              { value: 'lessThan', label: 'Less Than' },
              { value: 'greaterThan', label: 'Greater Than' },
              { value: 'greaterThanOrEqual', label: 'Greater Than or Equal' },
              { value: 'lessThanOrEqual', label: 'Less Than or Equal' },
              { value: 'between', label: 'Between' },
            ]}
            errorMessage={submitCount > 0 && errors.condition}
          />
          <Input
            label={values.condition === 'between' ? 'From' : 'Value'}
            name="value"
            type="number"
            value={values.value}
            onChange={(e) => {
              handleChange(e);
              setFieldValue('value', e.target.value);
            }}
            errorMessage={submitCount > 0 && errors.value}
          />
          {values.condition === 'between' && (
            <Input
              label="To"
              name="value2"
              type="number"
              value={values.value2}
              onChange={(e) => {
                handleChange(e);
                setFieldValue('value2', e.target.value);
              }}
              errorMessage={submitCount > 0 && errors.value2}
            />
          )}
          <div className="action-buttons">
            <Button
              variant="secondary"
              size="small"
              label="Cancel"
              onClick={onClose}
            />
            <Button
              variant="secondary"
              size="small"
              label="Clear Filter"
              onClick={onClear}
            />
            <Button
              variant="primary"
              size="small"
              label="Apply Filter"
              onClick={handleSubmit}
              disabled={
                !values.condition ||
                !values.value ||
                (values.condition === 'between' && !values.value2)
              }
            />
          </div>
        </Modal>
      )}
    </Formik>
  );
};

export default NumericFilter;
