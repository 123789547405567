import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'app/components';
import { deleteReason } from 'app/store/actions/merchant';
import { reasonLoadingSelector } from 'app/store/selectors/merchant';

const DeleteReasonModal = ({ isOpen, onClose, merchantId, reasonType, reason }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(reasonLoadingSelector);

  const handleDelete = () => {
    dispatch(
      deleteReason({
        merchantId,
        reasonType,
        reasonId: reason.id,
        callback: (success) => {
          if (success) {
            onClose();
          }
        }
      })
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Delete Reason"
      primaryButtonLabel="Yes, Delete it"
      primaryButtonOnClick={handleDelete}
      primaryButtonEnabled={!isLoading}
      primaryButtonDestructive={true}
      primaryButtonVariant="primary"
      secondaryButtonLabel="Cancel"
      secondaryButtonOnClick={onClose}
      secondaryButtonEnabled={!isLoading}
    >
      <p>Are you sure you want to delete reason with name <b>{reason.name}</b>?</p>
      <p>This action cannot be undone.</p>
    </Modal>
  );
};

DeleteReasonModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  merchantId: PropTypes.string.isRequired,
  reasonType: PropTypes.string.isRequired,
  reason: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

export default DeleteReasonModal;
