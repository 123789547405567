import React from 'react';
import { X } from 'react-bootstrap-icons';
import './index.scss';

const Tag = ({ label, onRemove }) => (
  <div className="gooten-tag">
    <span className="tag-label">{label}</span>
    {onRemove && (
      <X 
        className="remove-icon" 
        size={14} 
        onClick={onRemove} 
      />
    )}
  </div>
);

export default Tag;
