import { ButtonIcon, Input } from 'app/components'
import { ArrowUp, ArrowDown, PlusLg, Trash3 } from 'react-bootstrap-icons';
import Group from './Group';
import FilterInfo from './FilterInfo';
import RetryRules from './RetryRules';
import './index.scss';

const Block = props => {
  const {
    blockIndex,
    values,
    data,
    vendorsData,
    categories,
    dataSets,
    editMode,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    submitCount,
    setFieldValue,
    validateForm,
  } = props;

  const addBlock = () => {
    const newBlock = {
      blockName: '',
      groups: [{
        groupName: '',
        orderingAlgorithm: '',
        orderingAlgorithmDirection: 'asc',
        tiebreakerOrderingAlgorithm: '',
        vendors: [{
          vendorId: '',
          facilityId: '',
          rank: '0',
        }]
      }],
      filterInfo: null,
      retryRules: {
        rule1: 'Full',
        rule2: '',
        rule3: '',
      }
    };

    const blocksBeforeIndex = values.blocks.slice(0, blockIndex + 1);
    const blocksAfterIndex = values.blocks.slice(blockIndex + 1);
    const updatedBlocks = [...blocksBeforeIndex, newBlock, ...blocksAfterIndex];
    setFieldValue('blocks', updatedBlocks);
  }

  const removeBlock = () => {
    const updatedBlocks = values.blocks.filter((_, index) => index !== blockIndex);
    setFieldValue('blocks', updatedBlocks);
  };

  const promoteBlock = () => {
    if (blockIndex === 0) return;

    const updatedBlocks = [...values.blocks];
    const temp = updatedBlocks[blockIndex - 1];
    updatedBlocks[blockIndex - 1] = updatedBlocks[blockIndex];
    updatedBlocks[blockIndex] = temp;
    setFieldValue('blocks', updatedBlocks);
  };

  const demoteBlock = () => {
    if (blockIndex === values.blocks.length - 1) return;

    const updatedBlocks = [...values.blocks];
    const temp = updatedBlocks[blockIndex + 1];
    updatedBlocks[blockIndex + 1] = updatedBlocks[blockIndex];
    updatedBlocks[blockIndex] = temp;
    setFieldValue('blocks', updatedBlocks);
  };

  return (
    <div className="block-component">
      {editMode ? (
        <div className="block-header">
          <Input
            className="block-name-input"
            label="Block Name"
            name={`blocks.${blockIndex}.blockName`}
            value={data.blockName}
            onChange={handleChange}
            placeholder="Block Name"
            errorMessage={submitCount > 0 && errors.blocks && errors.blocks[blockIndex] && errors.blocks[blockIndex].blockName}
          />
          <div className="section-options">
            {blockIndex > 0 && (
              <ButtonIcon
                icon={<ArrowUp />}
                onClick={() => promoteBlock(blockIndex)}
              />
            )}
            {values.blocks.length > 0 && values.blocks.length !== blockIndex + 1 && (
              <ButtonIcon
                icon={<ArrowDown />}
                onClick={() => demoteBlock(blockIndex)}
              />
            )}
            <ButtonIcon
              icon={<PlusLg />}
              onClick={() => addBlock(blockIndex)}
            />
            {values.blocks.length > 1 && (
              <ButtonIcon
                icon={<Trash3 />}
                destructive={true}
                onClick={() => removeBlock(blockIndex)}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="block-name">{`Block ${values.blocks[blockIndex].blockName}`}</div>
      )}
      <FilterInfo
        blockIndex={blockIndex}
        data={data.filterInfo}
        editMode={editMode}
        values={values}
        categories={categories}
        errors={errors}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        submitCount={submitCount}
        setFieldValue={setFieldValue}
        validateForm={validateForm}
      />
      {data.groups.map((group, groupIndex) => (
        <Group
          key={groupIndex}
          blockIndex={blockIndex}
          groupIndex={groupIndex}
          data={group}
          vendorsData={vendorsData}
          dataSets={dataSets}
          editMode={editMode}
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          submitCount={submitCount}
          setFieldValue={setFieldValue}
          validateForm={validateForm}
        />
      ))}
      <RetryRules
        blockIndex={blockIndex}
        values={values}
        editMode={editMode}
        errors={errors}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        submitCount={submitCount}
        setFieldValue={setFieldValue}
      />
    </div>
  )
}

export default Block;