import { toast } from 'react-toastify';
import { CountryList } from 'app/constants';

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text)
    .then(() => {
      toast("Copied To Clipboard", { autoClose: 1000 });
    })
    .catch((error) => {
      console.error('Error copying text:', error);
    });
};

export const convertCountryCodeToName = (countryCode) => {
  const country = CountryList.find(country => country.CountryCode === countryCode);
  return country ? country.Name : null;
}

export const generateGUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const capitalizeFirstLetter = s => s?.charAt(0).toUpperCase() + s?.slice(1);

export const formatCurrency = (currency, amount) => {
  // Store the original currency for display purposes
  const originalCurrency = currency;
  
  // Validate currency code for the formatter
  let formatterCurrency = currency;
  if (!currency || typeof currency !== 'string' || currency.length !== 3) {
    formatterCurrency = 'USD'; // Use USD for the formatter only
  }

  // Validate amount is a number
  const numericAmount = parseFloat(amount);
  if (isNaN(numericAmount)) {
    return '—'; // Return a dash for invalid amounts
  }
  
  try {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: formatterCurrency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    
    // Format with the valid currency but display the original currency code
    return formatter.format(numericAmount) + " " + originalCurrency;
  } catch (error) {
    // Fallback to basic formatting with the original currency
    return `$${numericAmount.toFixed(2)} ${originalCurrency}`;
  }
}

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const parseCSV = (csvText) => {
  const lines = csvText.trim().split("\n");
  const headers = lines[0].split(",").map((header) => header.trim());
  const result = [];
  for (let i = 1; i < lines.length; i++) {
    const line = lines[i];
    const values = line.split(",").map((value) => value.trim());
    const obj = {};
    headers.forEach((header, index) => {
      obj[header] = values[index] || null;
    });
    result.push(obj);
  }
  return result;
}

export const isStringOrNumber = input => {
  if (!isNaN(input) && input.trim() !== '') {
    return 'number';
  } else {
    return 'string';
  }
}
