import axios from 'axios';
import Config from '../config';
import SetupInterceptors from './axiosInterceptors';

class UserServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.UserMS.Url
    });

    SetupInterceptors(this.api);
  }

  signinUser = async (username, password) => {
    try {
      const data = JSON.stringify({ username, password });
      const response = await this.api.post('/v1/login?source=portal', data);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Error fetching data from API';
      throw new Error(errorMessage);
    }
  };

  getUsers = async (searchString, status, sortBy, desc, currentPage, pageSize) => {
    try {
      let queryString = `/v1/search?page=${currentPage}&pageSize=${pageSize}`;
      if (searchString) {
        queryString += `&search=${encodeURIComponent(searchString)}`;
      }
      if (status) {
        queryString += `&status=${status}`;
      }
      if (sortBy) {
        queryString += `&sortBy=${sortBy}`;
      }
      if (desc) {
        queryString += `&desc=${desc}`;
      }

      const response = await this.api.get(queryString);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Error fetching data from API';
      throw new Error(errorMessage);
    }
  };

  getUserById = async (userId) => {
    try {
      const response = await this.api.get(`/v1/${userId}?source=portal`);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Error fetching data from API';
      throw new Error(errorMessage);
    }
  };

  createUser = async (values) => {
    const data =  {
      "firstName": values.firstName,
      "lastName": values.lastName,
      "email" : values.email,
      "password" : values.password,
    };

    try {
      const response = await this.api.post(`/v1`, data);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Create User Failed';
      throw new Error(errorMessage);
    }
  };

  updateUser  = async (userId, values, userConfig) => {
    const data =  {
      "firstName": values.firstName,
      "lastName": values.lastName,
      "email" : values.email,
      "EmailSettingsEnabled": userConfig.data.EmailSettings.Enabled,
      "EmailSettingsFrequency":  userConfig.data.EmailSettings.Frequency,
      "UserSchemaVersion": userConfig.schemaVersion
    };
    try {
      const response = await this.api.patch(`/v1/${userId}`, data);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Update User Failed';
      throw new Error(errorMessage);
    }
  };

  assignRole  = async (userId, role) => {
    try {
      const data = {
        "roleId": role
      };

      const response = await this.api.post(`/v1/${userId}/role`, data);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Error assigning role to user';
      throw new Error(errorMessage);
    }
  };
  
  unassignRole  = async (userId, role) => {
    try {
      const data = {
        "roleId": role
      };

      const response = await this.api.delete(`/v1/${userId}/role`,  { data: data });
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Error unassigning role to user';
      throw new Error(errorMessage);
    }
  };

  assignUserPermissions  = async (userId, roleId, permissions) => {
    try {
      const response = await this.api.put(`/v1/${userId}/roles/${roleId}/permissions?source=portal`, permissions);

      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Error assigning role permissions to user';
      throw new Error(errorMessage);
    }
  };

  resetPassword = async (userId) => {
    try {
      const response = await this.api.post(`/v1/${userId}/reset-password`);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Failed To Reset Password';
      throw new Error(errorMessage);
    }
  };

  forgotPassword = async (email) => {
    try {
      const data = {
        "email": email
      };
      const response = await this.api.post(`/v1/forgot-password`, data);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Failed to Send Reset Password Email';
      throw new Error(errorMessage);
    }
  };

  updateForgotPassword = async (token, password) => {
    try {
      const data = {
        "password": password
      };
      const response = await this.api.post(`/v1/reset-password/${token}`, data);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Failed to Reset Password';
      throw new Error(errorMessage);
    }
  };

  getRoles = async () => {
    try {
      const response = await this.api.get('/v1/roles');
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Error fetching data from API';
      throw new Error(errorMessage);
    }
  };  

  deleteUser = async (userId) => {
    try {
      const response = await this.api.delete(`/v1/${userId}`);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Error deleting user';
      throw new Error(errorMessage);
    }
  };

  updateUserStatus = async (userId, enabled) => {
    const data = {
      "enabled": enabled
    };

    try {
      const response = await this.api.patch(`/v1/${userId}`, data);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Failed to update user';
      throw new Error(errorMessage);
    }
  }

  getRoleWithPermissions = async () => {
    try {
      const response = await this.api.get(`/v1/roles/permissions?source=portal`);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Error fetching data from API';
      throw new Error(errorMessage);
    }
  };

  searchRoles = async (searchString, sortBy, desc, currentPage, pageSize) => {
    try {
      let queryString = `/v1/roles/search?page=${currentPage}&pageSize=${pageSize}&source=portal`;
      if (searchString) {
        queryString += `&search=${encodeURIComponent(searchString)}`;
      }
      if (sortBy) {
        queryString += `&sortBy=${sortBy}`;
      }
      if (desc) {
        queryString += `&desc=${desc}`;
      }
      const response = await this.api.get(queryString);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Error fetching data from API';
      throw new Error(errorMessage);
    }
  };

  deleteRole = async (roleId) => {
    try {
      const response = await this.api.delete(`/v1/roles/${roleId}`);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Error deleting role';
      throw new Error(errorMessage);
    }
  };

  getRoleById = async (roleId) => {
    try {
      const response = await this.api.get(`/v1/roles/${roleId}/permissions?source=portal`);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Error fetching data from API';
      throw new Error(errorMessage);
    }
  };

  createRole = async (values) => {
    const data =  {
      "name": values.name
    };

    try {
      const response = await this.api.post(`/v1/roles`, data);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Create Role Failed';
      throw new Error(errorMessage);
    }
  };

  updateRole = async (roleId, permissions) => {
    try {
      const response = await this.api.put(`/v1/roles/${roleId}/permissions?source=portal`, permissions);

      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Failed to update role permissions';
      throw new Error(errorMessage);
    }
  };

  getResources = async () => {
    try {
      const response = await this.api.get(`/v1/resources?source=portal`);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Error fetching data from API';
      throw new Error(errorMessage);
    }
  };

  updateRoleName = async (roleId, name) => {
    const data = {
      name
    };

    try {
      const response = await this.api.put(`/v1/roles/${roleId}/name`, data);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Failed to update role name';
      throw new Error(errorMessage);
    }
  }

  updateUserAttributes = async (userId, type, entity, entityId, associatedIDs) => {
    const data = {
      type, entity, entityId, associatedIDs
    };
    const errMsg = 'Error updating user attributes';

    try {
      const response = await this.api.put(`/v1/${userId}/attributes`, data);
      if (response.status !== 204) {
        throw new Error(errMsg);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || errMsg;
      throw new Error(errorMessage);
    }
  }

  removedAttributesToUser = async (userId, attributes) => {
    const errMsg = 'Error removing attributes to user';
    try {
      const response = await this.api.delete(`/v1/${userId}/attributes`, { data: attributes });
      if (response.status !== 204) {
        throw new Error(errMsg);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || errMsg;
      throw new Error(errorMessage);
    }
  }

  getGroups = async () => {
    try {
      const response = await this.api.get(`/v1/groups`);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Error fetching data from API';
      throw new Error(errorMessage);
    }
  };

  createClient = async (values) => {
    const data =  {
      "Role": values.role,  // "vendor" || "merchant"
      "Id": values.id       // vendor or merchant id
    };

    try {
      const response = await this.api.post(`/v1/clients`, data);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Failed to create client';
      throw new Error(errorMessage);
    }
  };

  findClient = async (role, id) => {
    //role: "vendor" || "merchant"
    //id: vendor or merchant id
    try {
      const response = await this.api.get(`/v1/clients/find/${role}/${id}`);
      // Note: on UI we should show clientId from responce (has format "{role}-{id}-client") not GUID id
      // it should be used to obtain token
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Error fetching data from API';
      throw new Error(errorMessage);
    }
  };

  regenerateClientSecret = async (id) => {
    //id: client id GUID from findClient method (not clientId)
    try {
      const response = await this.api.put(`/v1/clients/${id}/secret`, {});
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Failed to regenerate client secret';
      throw new Error(errorMessage);
    }
  };

  deleteClient = async (id) => {
    //id: client id GUID from findClient method (not clientId)
    try {
      const response = await this.api.delete(`/v1/clients/${id}`);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Failed to delete client';
      throw new Error(errorMessage);
    }
  };

  inviteSubaccount = async (data) => {
    try {
      const response = await this.api.post(`/v1/invitation`, data);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0]?.errorMessage?.toString() || 'Invitate subaccount failed';
      throw new Error(errorMessage);
    }
  };

  validateInvitationCode = async (invitationCode) => {
    return (await this.api.get(`/v1/invitation/${invitationCode}`, {
      skipAuthRetry: true
    })).data;
  };

  onboardUser = async (data) => {
    return (await this.api.post('/v1/invitation/onboard', data, {
      skipAuthRetry: true
    })).data;
  };
}

export default new UserServices();
