import axios from "axios";
import Config from "../config";
import SetupInterceptors from "./axiosInterceptors";

class NoteServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.NoteMS.Url,
    });

    SetupInterceptors(this.api);
  }

  createNote = async (note) => {
    try {
      const response = await this.api.post(`/v1/notes`, note);
      return response.data;
    } catch (error) {
      const errorMsg =
        error?.response?.status === 403
          ? "Unauthorized: You do not have permission to create notes."
          : error?.response?.data?.errors?.[0]?.errorMessage ?? "Create Note Failed";
      throw new Error(errorMsg);
    }
  };

  getEntityNotes = async (entityType, entityId, isPrivate, page = 1, pageSize = 10) => {
    try {
      let query = `entityType=${entityType}&entityId=${entityId}&page=${page}&pageSize=${pageSize}`;
      if (isPrivate != null) {
        query = query + `&isPrivate=${isPrivate}`;
      }
      const response = await this.api.get(`/v1/notes?${query}`);
      return response.data;
    } catch (error) {
      const errorMsg =
        error?.response?.status === 403
          ? "Unauthorized: You do not have permission to view notes."
          : error?.response?.data?.errors?.[0]?.errorMessage ?? "Note data failed to load.";
      throw new Error(errorMsg);
    }
  };

  deleteNote = async (noteId) => {
    try {
      await this.api.delete(`/v1/notes/${noteId}`);
      return noteId;
    } catch (error) {
      const errorMsg =
        error?.response?.status === 403
          ? "Unauthorized: You do not have permission to delete the note."
          : error?.response?.data?.errors?.[0]?.errorMessage ?? "Delete Note Failed";
      throw new Error(errorMsg);
    }
  };

  updateNote = async (note) => {
    try {
      const response = await this.api.put(`/v1/notes/${note.id}`, {
        content: note.content,
        isPrivate: note.isPrivate,
      });
      return response.data;
    } catch (error) {
      const errorMsg =
        error?.response?.status === 403
          ? "Unauthorized: You do not have permission to the note."
          : error?.response?.data?.errors?.[0]?.errorMessage ?? "Delete Note Failed";
      throw new Error(errorMsg);
    }
  };
}

export default new NoteServices();
