import { createSelector } from 'reselect';

const insightSelector = state => state.insight;

export const queriesSelector = createSelector([insightSelector], insightStore => insightStore.queries.data);
export const queriesLoadingSelector = createSelector([insightSelector], insightStore => insightStore.queries.loading);
export const queriesErrorsSelector = createSelector([insightSelector], insightStore => insightStore.queries.error);

export const queryDataSelector = createSelector([insightSelector], insightStore => insightStore.queryData);

export const exportsSelector = createSelector([insightSelector], insightStore => insightStore.exports.data);
export const exportsLoadingSelector = createSelector([insightSelector], insightStore => insightStore.exports.loading);
export const exportsErrorsSelector = createSelector([insightSelector], insightStore => insightStore.exports.error);

export const exportRunHistoryByIdSelector = (exportId) => 
  createSelector(
    [insightSelector],
    insightStore => insightStore.exportRunHistory.data[exportId] || { loading: false, error: null, data: null }
  );

export const availableExportColumnsSelector = createSelector([insightSelector], insightStore => insightStore.availableExportColumns.data);
export const availableExportColumnsLoadingSelector = createSelector([insightSelector], insightStore => insightStore.availableExportColumns.loading);
export const availableExportColumnsErrorsSelector = createSelector([insightSelector], insightStore => insightStore.availableExportColumns.error);

export const createExportSelector = createSelector([insightSelector], insightStore => insightStore.createExport.data);
export const createExportLoadingSelector = createSelector([insightSelector], insightStore => insightStore.createExport.loading);
export const createExportErrorsSelector = createSelector([insightSelector], insightStore => insightStore.createExport.error);

export const runExportSelector = createSelector([insightSelector], insightStore => insightStore.runExport.data);
export const runExportLoadingSelector = createSelector([insightSelector], insightStore => insightStore.runExport.loading);
export const runExportErrorsSelector = createSelector([insightSelector], insightStore => insightStore.runExport.error);

export const exportPreviewDataSelector = createSelector([insightSelector], insightStore => insightStore.exportPreviewData.data);
export const exportPreviewDataLoadingSelector = createSelector([insightSelector], insightStore => insightStore.exportPreviewData.loading);
export const exportPreviewDataErrorsSelector = createSelector([insightSelector], insightStore => insightStore.exportPreviewData.error);