import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  reset,
  deleteRole,
  searchRoles,
} from "app/store/actions/user";
import {
  getMerchantDetails,
  updateMerchant,
} from "app/store/actions/merchant";
import {
  searchRolesDataSelector,
  searchRolesLoadingSelector,
  searchRolesErrorsSelector,
  currentUserSelector,
  rolesWithPermissionsLoadingSelector
} from "app/store/selectors/user";
import {
  merchantDetailsSelector,
  merchantDetailsLoadingSelector,
  merchantDetailsErrorsSelector,
} from "app/store/selectors/merchant";
import {
  Pagination,
  LoadingAnimation,
  SearchBar,
  ButtonIcon,
  Table,
  TableHeader,
  Button,
  Modal,
  Card,
  Link,
  Checkbox,
} from "app/components";
import { ExclamationCircle, Trash3, ArrowLeft } from "react-bootstrap-icons";
import { formatUsersRolesName, isUserSystemAdmin } from "../utils";
import { usePermission } from 'app/permissions';
import "./index.scss";

const RolesView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [searchString, setSearchString] = useState("");
  const [selectedSortBy, setSelectedSortBy] = useState("");
  const [selectedSortDir, setSelectedSortDir] = useState("");
  const [deleteRoleModal, showDeleteRoleModal] = useState(false);

  const rolesData = useSelector(searchRolesDataSelector);
  const loading = useSelector(searchRolesLoadingSelector);
  const errorsFound = useSelector(searchRolesErrorsSelector);
  const currentUser = useSelector(currentUserSelector);
  const rolesLoading = useSelector(rolesWithPermissionsLoadingSelector);
  const merchantDetails = useSelector(merchantDetailsSelector);
  const merchantDetailsLoading = useSelector(merchantDetailsLoadingSelector);
  const merchantDetailsErrors = useSelector(merchantDetailsErrorsSelector);

  const currentPage = parseInt(searchParams.get("page") || "1", 10);
  const pageSize = parseInt(searchParams.get("pageSize") || "50", 10);
  const sortBy = searchParams.get("sortBy") || "id";
  const sortDir = searchParams.get("sortDir") || "desc";

  // get user permissions
  const canManageRoles = usePermission('users', 'roles_create');

  const isAdmin = isUserSystemAdmin(currentUser);
  
  // Check if user is a parent merchant
  const isParentMerchant = 
    currentUser?.attributes?.entity?.includes('merchant') && 
    currentUser?.attributes?.type?.includes('parent');

  useEffect(() => {
    const entityIds = currentUser?.attributes?.entityId || [];
    
    if (entityIds.length > 0 && isParentMerchant) {
      dispatch(getMerchantDetails(entityIds[0]));
    }
  }, [dispatch, currentUser, isParentMerchant]);

  useEffect(() => {
    dispatch(searchRoles({ searchString, currentPage, pageSize }));
  }, [searchString, currentPage, pageSize, dispatch]);

  useEffect(() => {
    handleLocationSearch();
  }, [location.search]);

  useEffect(() => {
    handleSearchChange();
  }, [selectedSortBy, selectedSortDir]);

  const handleLocationSearch = () => {
    const searchParams = new URLSearchParams(location.search);

    // set sort by if it has been changed...
    const sortBy = searchParams.get("sortBy");
    if (sortBy && sortBy !== selectedSortBy) {
      setSelectedSortBy(sortBy);
    }

    // set sort direction if it has been changed...
    const sortDir = searchParams.get("sortDir");
    if (sortDir && sortDir !== selectedSortDir) {
      setSelectedSortDir(sortDir);
    }
  };

  const handleSearchChange = () => {
    dispatch(
      searchRoles({
        searchString,
        selectedSortBy,
        selectedSortDir,
        currentPage,
        pageSize,
      })
    );
  };

  const handleCheckboxChange = (roleId) => {
    const merchantId = currentUser?.attributes?.entityId?.[0];
    if (!merchantId) return;
    
    const updatedDetails = {
      ...merchantDetails,
      subaccountDefaultRoleId: roleId === merchantDetails?.subaccountDefaultRoleId ? null : roleId
    };
    
    dispatch(updateMerchant({ data: updatedDetails }));
  };

  return (
    <div className="role-view">
      {(loading || rolesLoading || (merchantDetailsLoading && isParentMerchant)) && <LoadingAnimation />}
      {rolesData && (!isParentMerchant || merchantDetails) && (
        <Card>
          <div className="roles-view-header">
            <div className="role-name">
              <ButtonIcon
                icon={<ArrowLeft />}
                onClick={() => navigate("/admin/users/")}
              />
              Role Management
            </div>
            {canManageRoles && (
              <Button
                variant="primary"
                size="medium"
                label={"Create Role"}
                onClick={() => {
                  dispatch(reset());
                  navigate(`/admin/users/roles/create-role`);
                }}
              />
            )}
          </div>
          <SearchBar
            searchPlaceholderText="Search for a Role"
            onSearchStringUpdated={setSearchString}
            debounceDelay={500}
          />
          <Table size="medium">
            <TableHeader
              sortBy={sortBy}
              sortDir={sortDir}
              options={[
                { id: "name", label: "Role", width: "240px", orderable: true },
                { id: "resources", label: "Permissions" },
                { id: "userCount", label: "User Count", orderable: true },
                ...(isParentMerchant ? [{ id: "defaultRole", label: "Default Role", width: "150px", align: 'center', tooltip: "Default Role for Sub-Account Invitations" }] : []),
                ...(canManageRoles ? [{ id: "actions", label: "Actions", width: "50px", align: 'center' }] : []),
              ]}
            />
            <tbody className="table-body">
              {rolesData?.roles.map((role) => (
                <tr className="role-row" key={role.id}>
                  <td>
                    <Link
                      label={formatUsersRolesName(role.name)}
                      url={`/admin/users/roles/${role.id}`}
                      onClick={() => {
                        dispatch(reset());
                        navigate(`/admin/users/roles/${role.id}`);
                      }}
                    />{" "}
                  </td>
                  <td>
                    {role.permissionSet
                      ?.filter(
                        (permission) =>
                          permission.associatedPermissions.length > 0
                      )
                      ?.map((r) => (
                        <div key={r?.resourceId}>
                          {formatUsersRolesName(r?.resourceDisplayName)}
                        </div>
                      ))}
                  </td>
                  <td>{role.usersCount}</td>
                  {isParentMerchant && (
                    <td className="default-role-checkbox">
                      <Checkbox
                        size='small' 
                        checked={merchantDetails?.subaccountDefaultRoleId === role.id} 
                        onChange={() => handleCheckboxChange(role.id)} 
                        disabled={!canManageRoles && !isAdmin}
                      />
                    </td>
                  )}
                  <td className="role-actions-menu">
                    {role?.usersCount === 0 && ((canManageRoles && !role.isSystem) || isAdmin) &&
                      <ButtonIcon
                        icon={<Trash3 />}
                        destructive
                        onClick={() => showDeleteRoleModal(role.id)}
                      />}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {errorsFound && !loading && (
            <div className="role-load-failed"><ExclamationCircle />Role Data failed to load. Refresh the page to try again.</div>
          )}
          {merchantDetailsErrors && !merchantDetailsLoading && (
            <div className="role-load-failed"><ExclamationCircle />Merchant details failed to load. Please try again.</div>
          )}
          {rolesData?.roles.length === 0 ? (
            <div className="no-matching-results">No Results Found</div>
          ) : (
            <Pagination totalItems={rolesData?.total || 0} hideBelow={3} />
          )}
          {deleteRoleModal && (
            <Modal
              secondaryButtonLabel="No, Keep It"
              secondaryButtonOnClick={() => {
                showDeleteRoleModal(null);
              }}
              primaryButtonLabel="Yes, Delete It"
              primaryButtonVariant="primary"
              primaryButtonDestructive
              primaryButtonOnClick={() => {
                dispatch(
                  deleteRole({
                    id: deleteRoleModal,
                    cb: () => handleSearchChange(),
                  })
                );
                showDeleteRoleModal(null);
              }}
              onClose={() => showDeleteRoleModal(null)}
            >
              <div>Are you sure you want to delete this role?</div>
              <div>This action is not reversible.</div>
            </Modal>
          )}
        </Card>
      )}
    </div>
  );
};
export default RolesView;
