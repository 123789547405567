import { createSelector } from 'reselect';

const integrationSelector = state => state.integration;

export const integrationsDataSelector = createSelector([integrationSelector], integrationView => integrationView.integrations.data);
export const integrationsLoadingSelector = createSelector([integrationSelector], integrationView => integrationView.integrations.loading);
export const integrationsErrorsSelector = createSelector([integrationSelector], integrationView => integrationView.integrations.error);

export const carriersSelector = createSelector([integrationSelector], integrationView => integrationView.carrierList.data);
export const carriersLoadingSelector = createSelector([integrationSelector], integrationView => integrationView.carrierList.loading);
export const carriersErrorsSelector = createSelector([integrationSelector], integrationView => integrationView.carrierList.error);

export const merchantIntegrationsDataSelector = createSelector([integrationSelector], integrationView => integrationView.merchantIntegrations.data);
export const merchantIntegrationsLoadingSelector = createSelector([integrationSelector], integrationView => integrationView.merchantIntegrations.loading);
export const merchantIntegrationsErrorsSelector = createSelector([integrationSelector], integrationView => integrationView.merchantIntegrations.error);

export const integrationDetailsSelector = createSelector([integrationSelector], integrationView => integrationView.integration.data);
export const integrationDetailsLoadingSelector = createSelector([integrationSelector], integrationView => integrationView.integration.loading);
export const integrationDetailsErrorsSelector = createSelector([integrationSelector], integrationView => integrationView.integration.error);

export const eventTypesSelector = createSelector([integrationSelector], integrationView => integrationView.eventTypes.data);
export const eventTypesLoadingSelector = createSelector([integrationSelector], integrationView => integrationView.eventTypes.loading);
export const eventTypesErrorsSelector = createSelector([integrationSelector], integrationView => integrationView.eventTypes.error);