import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import './index.scss';

const Input = ({
  className = '',            
  label = '',                
  errorMessage = null,       
  name,                     
  tooltip = null,            
  readonly = false,          
  disabled = false,          
  icon = null,               
  onIconClick = null,     
  showErrorMessages = true,   
  ...rest
}) => {
  const showError = errorMessage && errorMessage.trim().length > 0;

  return (
    <div className={`gooten-input ${className}`}>
      {label && (
        <label
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <input
        className={`form-control ${showError ? 'has-error' : ''} ${icon ? 'with-icon' : ''}`}
        name={name}
        readOnly={readonly}
        disabled={disabled}
        autoComplete={rest.type === 'password' ? 'new-password' : 'on'}
        {...rest}
      />
      {tooltip && (
        <OverlayTrigger
          key="top"
          placement="top"
          overlay={
            <Tooltip id={`tooltip-top`} style={{ maxWidth: 400 }}>
              <div dangerouslySetInnerHTML={{ __html: tooltip }} />
            </Tooltip>
          }
        >
          <QuestionCircleFill className="question-icon" />
        </OverlayTrigger>
      )}
      {icon &&
        React.cloneElement(icon, {
          className: `input-icon ${onIconClick ? 'clickable' : ''}`,
          onClick: () => (onIconClick ? onIconClick() : null),
        })}
      {showErrorMessages && <div className="error">{showError && errorMessage}</div>}
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.element,   
  onIconClick: PropTypes.func,
};

export default Input;
