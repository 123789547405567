import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  LoadingAnimation,
  StatusChip
} from 'app/components';
import {
  exportsSelector,
  availableExportColumnsSelector,
  availableExportColumnsLoadingSelector,
  createExportLoadingSelector,
  exportPreviewDataSelector,
  exportPreviewDataLoadingSelector,
  exportPreviewDataErrorsSelector,
  exportRunHistoryByIdSelector,
} from 'app/store/selectors/insight';
import {
  getAvailableExportColumns,
  createExport,
  updateExport,
  getExportPreviewData,
  clearExportPreviewData,
  getSpecificExport,
  getExportRunHistory,
  reset
} from 'app/store/actions/insight';
import { currentUserSelector } from 'app/store/selectors/user';
import { Download } from 'react-bootstrap-icons';
import { ReactComponent as LoadingIcon } from "app/assets/images/icons/loading.svg";
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import TitleSection from './TitleSection';
import ColumnSelection from './ColumnSelection';
import PreviewSection from './PreviewSection';
import { buildFiltersObject } from './filterUtils';
import './index.scss';

// Constants
const DEFAULT_TITLE = 'Untitled Export';

const ExportDetails = () => {
  const { exportId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Selectors
  const exportsData = useSelector(exportsSelector);
  const availableColumns = useSelector(availableExportColumnsSelector);
  const columnsLoading = useSelector(availableExportColumnsLoadingSelector);
  const createExportLoading = useSelector(createExportLoadingSelector);
  const exportPreviewData = useSelector(exportPreviewDataSelector);
  const exportPreviewDataLoading = useSelector(exportPreviewDataLoadingSelector);
  const exportPreviewDataErrorsFound = useSelector(exportPreviewDataErrorsSelector);
  const currentUser = useSelector(currentUserSelector);
  const exportHistory = useSelector(exportRunHistoryByIdSelector(exportId));

  // State
  const [currentExport, setCurrentExport] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [editedTitle, setEditedTitle] = useState('');
  const [activeFilters, setActiveFilters] = useState({});
  const [activeFilter, setActiveFilter] = useState(null);
  const [activeFilterColumn, setActiveFilterColumn] = useState(null);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isColumnChange, setIsColumnChange] = useState(false);
  const [isColumnReorder, setIsColumnReorder] = useState(false);
  // const [highlightedRunId, setHighlightedRunId] = useState(null);
  const [downloadingRunIds, setDownloadingRunIds] = useState([]);
  
  useEffect(() => {
    dispatch(reset());
  }, []);

  // Effect for handling column changes
  useEffect(() => {
    if (!availableColumns && !columnsLoading) {
      dispatch(getAvailableExportColumns({
        runAs: currentUser?.attributes?.entity?.[0] || 'admin'
      }));
    }
  }, [availableColumns, columnsLoading, currentUser]);

  useEffect(() => {
    if (!exportsData && exportId && availableColumns) {
      dispatch(getSpecificExport(exportId));
    }
  }, [exportsData, exportId, availableColumns]);

  useEffect(() => {
    if (exportsData && exportId) {
      // Handle both possible data structures: reports or exports
      const exportsList = exportsData.reports || exportsData.exports || [];
      const matchingExport = exportsList.find(exportItem => exportItem.id === exportId);
      
      setCurrentExport(matchingExport || null);
      setEditedTitle(matchingExport?.name || DEFAULT_TITLE);
      
      // Initialize filters from the existing export
      if (matchingExport?.filter?.filters) {
        const newActiveFilters = {};
        matchingExport.filter.filters.forEach(filter => {
          let value = filter.value;
          let value2 = filter.value2;

          if (filter.columnType?.toLowerCase() === 'date') {
            try {
              if (Array.isArray(value)) {
                const [startDate, endDate] = value;
                value = startDate;
                value2 = endDate;
              }
            } catch (error) {
              console.error('Error parsing date values:', error);
            }
          }

          newActiveFilters[filter.column] = {
            columnType: filter.columnType,
            type: filter.operator,
            value: value,
            value2: value2
          };
        });
        setActiveFilters(newActiveFilters);
      }

      // Set columns after filters to ensure we only fetch preview once
      if (matchingExport?.columns) {
        setSelectedColumns(matchingExport.columns);
      }
    }
  }, [exportsData, exportId]);

  const fetchPreviewData = useCallback((columns) => {
    if (columns.length > 0) {
      const entityType = currentUser?.attributes?.entity?.[0]?.toLowerCase();
      const entityIds = currentUser?.attributes?.entityId || [];

      const previewData = {
        columns: columns,
        runAs: currentUser?.attributes?.entity?.[0],
        merchantIds: entityType === 'merchant' ? entityIds : [],
        vendorIds: entityType === 'vendor' ? entityIds : [],
        limit: 20,
        filter: buildFiltersObject(activeFilters)
      };
      dispatch(getExportPreviewData(previewData));
    }
  }, [dispatch, activeFilters, currentUser]);

  const debouncedFetchPreview = useCallback(
    debounce((columns) => {
      fetchPreviewData(columns);
      setIsColumnChange(false);
    }, 2000),
    [fetchPreviewData]
  );

  const handleRetryPreview = useCallback(() => {
    if (selectedColumns.length > 0) {
      const filter = buildFiltersObject(activeFilters);
      dispatch(getExportPreviewData({ columns: selectedColumns, filter }));
    }
  }, [dispatch, selectedColumns, activeFilters]);

  const formatTableHeader = (name) => {
    const sections = name.split('.');
    const processedSections = sections.map(section => {
      const words = section.replace(/([A-Z])/g, ' $1').trim().split(' ');
      return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    });
  
    const lastWord = processedSections.pop();
    const previousWords = processedSections.length > 0 ? processedSections.join(' • ') : '';
  
    return (
      <>
        <div className="header-first-word">{lastWord}</div>
        {previousWords && <div className="header-remaining">{previousWords}</div>}
      </>
    );
  };

  // Memoized functions
  const buildColumnTree = useMemo(() => {
    if (!availableColumns?.columns) return {};

    const tree = { name: 'Root', children: {} };
    const searchLower = searchString.toLowerCase();

    availableColumns.columns.forEach(column => {
      if (searchString && !column.name.toLowerCase().includes(searchLower)) {
        return;
      }

      const parts = column.name.split('.');
      let currentNode = tree;

      parts.forEach((part, index) => {
        if (index === parts.length - 1) {
          if (!currentNode.columns) {
            currentNode.columns = [];
          }
          currentNode.columns.push({
            name: part,
            fullName: column.name,
            allowForUserTypes: column.allowForUserTypes
          });
        } else {
          if (!currentNode.children) {
            currentNode.children = {};
          }
          if (!currentNode.children[part]) {
            currentNode.children[part] = {
              name: part,
              children: {},
              isExpandable: true
            };
          }
          currentNode = currentNode.children[part];
        }
      });
    });

    const cleanupEmptyNodes = (node) => {
      if (!node) return false;

      if (node.children) {
        Object.keys(node.children).forEach(key => {
          if (!cleanupEmptyNodes(node.children[key])) {
            delete node.children[key];
          }
        });
      }

      return (node.columns?.length > 0) ||
        (node.children && Object.keys(node.children).length > 0);
    };

    cleanupEmptyNodes(tree);
    return tree;
  }, [availableColumns, searchString]);

  const isExportGenerating = useMemo(() => {
    return exportHistory?.data?.runs?.some(run => 
      run.status === 'Running' || run.status === 'Pending'
    ) || exportHistory?.loading;
  }, [exportHistory?.data?.runs, exportHistory?.loading]);

  const latestRun = useMemo(() => {
    return exportHistory?.data?.runs?.[0] || null;
  }, [exportHistory?.data?.runs]);

  useEffect(() => {
    if (exportId) {
      dispatch(getExportRunHistory({
        exportId,
        page: 1,
        pageSize: 1,
      }));
    }
  }, [exportId]);

  const handleDownload = async (downloadUrl, name, runId) => {
    if (!downloadUrl) {
      toast.error("Export file not available", { theme: 'colored' });
      return;
    }

    try {
      setDownloadingRunIds(prev => [...prev, runId]);
      const response = await fetch(downloadUrl);
      
      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `${name || 'export'}.csv`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Download error:', error);
      toast.error("Error downloading file", { theme: 'colored' });
    } finally {
      setDownloadingRunIds(prev => prev.filter(id => id !== runId));
    }
  };

  const handleCreateOrUpdateExport = () => {
    if (selectedColumns.length === 0) return;

    const exportData = {
      name: editedTitle || DEFAULT_TITLE,
      columns: selectedColumns,
      allowForMerchantIds: currentUser?.attributes?.entityId,
      allowForVendorIds: [],
      allowForUserTypes: currentUser?.attributes?.entity || ['admin'],
      filter: buildFiltersObject(activeFilters)
    };

    if (exportId) {
      // Update existing export
      dispatch(updateExport({ 
        exportId,
        data: exportData, 
        cb: () => {
          navigate('/admin/exports');
        } 
      }));
    } else {
      // Create new export
      dispatch(createExport({ 
        data: exportData, 
        cb: () => {
          navigate('/admin/exports');
        } 
      }));
    }
  };

  const handleCreateOrUpdateAndStay = () => {
    if (selectedColumns.length === 0) return;

    const exportData = {
      name: editedTitle || DEFAULT_TITLE,
      columns: selectedColumns,
      allowForMerchantIds: currentUser?.attributes?.entityId,
      allowForVendorIds: [],
      allowForUserTypes: currentUser?.attributes?.entity || ['admin'],
      filter: buildFiltersObject(activeFilters)
    };

    if (exportId) {
      // Update existing export
      dispatch(updateExport({ 
        exportId,
        data: exportData,
        showToast: false,
      }));
    } else {
      // Create new export
      dispatch(createExport({ 
        data: exportData,
        showToast: false,
        cb: (newExportId) => {
          // Navigate to the export page, accounting for fixed header offset
          navigate(`/admin/exports/${newExportId}`);
        } 
      }));
    }
  };

  const handleTitleChange = (newTitle) => {
    setEditedTitle(newTitle);
    setCurrentExport(prev => ({ ...prev, name: newTitle }));
  };

  const handleTitleEditStateChange = (editing) => {
    setIsEditingTitle(editing);
  };

  const handleColumnSelect = (columnNames, isSelected) => {
    setIsColumnChange(true);
    setSelectedColumns(prev => {
      if (isSelected) {
        return [...new Set([...prev, ...columnNames])];
      }
      const newColumns = prev.filter(col => !columnNames.includes(col));
      setActiveFilters(prevFilters => {
        const newFilters = { ...prevFilters };
        columnNames.forEach(col => {
          if (newFilters[col]) {
            delete newFilters[col];
          }
        });
        return newFilters;
      });
      return newColumns;
    });
  };

  const handleClearColumns = () => {
    setIsColumnChange(true);
    setSelectedColumns([]);
    setActiveFilters({});
    dispatch(clearExportPreviewData());
  };

  const handleFilterClick = (columnName) => {
    const columnType = availableColumns.columns.find(col => col.name === columnName)?.type;
    setActiveFilterColumn(columnName);
    setActiveFilter(columnType);
  };

  const handleFilterChange = (newFilters) => {
    setActiveFilters(newFilters);
  };

  const handleDownloadPreview = () => {
    if (!exportPreviewData?.data) return;

    const escapeCsvValue = (value) => {
      if (value == null) return '';
      const stringValue = String(value);
      if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n')) {
        return `"${stringValue.replace(/"/g, '""')}"`;
      }
      return stringValue;
    };

    const csvContent = [
      selectedColumns,
      ...exportPreviewData.data.map(row =>
        selectedColumns.map(column => escapeCsvValue(row[column]))
      )
    ];

    const csvString = csvContent.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    const fileName = (currentExport?.name || DEFAULT_TITLE)
      .replace(/[^a-z0-9]/gi, '_')
      .toLowerCase();
    link.download = `${fileName}_preview.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleColumnsReordered = (newOrderedColumns) => {
    // Set the reorder flag to prevent API call
    setIsColumnReorder(true);
    
    // Update the selectedColumns state with the new ordered columns
    // This will ensure the new order is used when saving to the API
    setSelectedColumns(newOrderedColumns);
  };

  // Effect for handling column/filter changes
  useEffect(() => {
    if (!selectedColumns.length) {
      dispatch(clearExportPreviewData());
      return;
    }

    // Skip API call if this was just a reordering operation
    if (isColumnReorder) {
      setIsColumnReorder(false);
      return;
    }

    if (isColumnChange) {
      debouncedFetchPreview(selectedColumns);
    } else {
      fetchPreviewData(selectedColumns);
    }
  }, [selectedColumns, activeFilters]);

  // If we're trying to load a specific export but don't have the data yet, show loading
  if (exportId && !exportsData) {
    return <LoadingAnimation />;
  }

  return (
    <div className="export-details">
      {(createExportLoading) && <LoadingAnimation />}

      <Card className="export-details-header">
        <div className="title-status-container">
          <TitleSection
            currentExport={currentExport}
            onNavigateBack={() => navigate('/admin/exports')}
            onTitleChange={handleTitleChange}
            onEditStateChange={handleTitleEditStateChange}
          />
          {exportId && latestRun?.status && (
            <div className="export-status">
              <StatusChip
                size="small"
                type="export"
                status={latestRun?.status}
                iconLeft={latestRun?.status === 'Running' || latestRun?.status === 'Pending' ? <LoadingIcon /> : null}
                iconRight={latestRun?.generatedFileUrl ? downloadingRunIds.includes(latestRun?.id) ? <LoadingIcon className="spin" /> : <Download /> : null}
                onClick={latestRun?.generatedFileUrl ? () => handleDownload(latestRun?.generatedFileUrl, currentExport?.name, latestRun?.id) : null}
              />
            </div>
          )}
        </div>
        <div className="action-buttons">
          <Button
            variant="secondary"
            label={"Save and Run Export"}
            onClick={handleCreateOrUpdateAndStay}
            loading={createExportLoading}
            disabled={createExportLoading || isEditingTitle || selectedColumns.length === 0 || isExportGenerating}
          />
          <Button
            variant="primary"
            label={exportId ? "Save Export" : "Create Export"}
            onClick={handleCreateOrUpdateExport}
            loading={createExportLoading}
            disabled={createExportLoading || isEditingTitle || selectedColumns.length === 0}
          />
        </div>
      </Card>

      <div className="cards-container">
        <ColumnSelection
          selectedColumns={selectedColumns}
          onColumnSelect={handleColumnSelect}
          onClearColumns={handleClearColumns}
          searchString={searchString}
          onSearchStringUpdated={setSearchString}
          columnTree={buildColumnTree}
        />

        <PreviewSection
          selectedColumns={selectedColumns}
          exportPreviewData={exportPreviewData}
          exportPreviewDataLoading={exportPreviewDataLoading}
          exportPreviewDataErrorsFound={exportPreviewDataErrorsFound}
          activeFilters={activeFilters}
          onFilterChange={handleFilterChange}
          activeFilter={activeFilter}
          activeFilterColumn={activeFilterColumn}
          onCloseFilter={() => {
            setActiveFilter(null);
            setActiveFilterColumn(null);
          }}
          onFilterClick={handleFilterClick}
          formatTableHeader={formatTableHeader}
          onDownloadPreview={handleDownloadPreview}
          onRetry={handleRetryPreview}
          availableColumns={availableColumns}
          onColumnsReordered={handleColumnsReordered}
        />
      </div>
    </div>
  );
};

export default ExportDetails;