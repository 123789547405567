import { createStatus } from './statusColorSchemes';

const ExportStatusDetails = {
  'completed': createStatus('success', 'Ready For Download', ''),
  'failed': createStatus('error', 'Error', ''),
  'running': createStatus('info', 'Running Export', ''),
  'pending': createStatus('info', 'Export Pending', ''),
  'loading': createStatus('info', 'Loading', '')
};

export default ExportStatusDetails;