import React from 'react';
import { Card, MessageBar } from 'app/components';
import ReactApexChart from 'react-apexcharts';
import Skeleton from 'react-loading-skeleton';
import EmptyChart from '../EmptyChart';

const Top5USStateByOrdersCount = ({ queryData = {} }) => {
  const {
    data,
    loading,
    error,
  } = queryData;

  const chartData = {
    series: [{
      data: data ? data.map(item => ({
        x: item.ship_state,
        y: item.orders_count
      })) : []
    }],
    options: {
      title: {
        text: 'Top 5 US State By Orders',
        align: 'left',
        style: {
          fontFamily: "Source Sans Pro",
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '26px',
          color: '#2E2E2E'
        }
      },
      chart: {
        type: 'treemap',
        animations: {
          enabled: false,
        },
        events: {
          click: function (chart, w, e) {
            console.log(chart, w, e)
          }
        }
      },
      colors: ['#9CB4E2', '#F8B05B', '#E8D953', '#6DCB5D', '#9AA4FF'],
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#2E2E2E']
        },
        formatter: function (text, op) {
          return [text, op.value];
        },
      },
      legend: {
        show: false
      },
    },
  };

  return (
    <>
      {!error ? (
        <Card className={`top-5-us-state-by-orders gooten-dashboard-chart ${data && data.length > 0 ? 'has-data' : ''}`}>
          {data && data.length > 0 && (
            <ReactApexChart options={chartData.options} series={chartData.series} type="treemap" height={350} />
          )}
          {!loading && !error && data && data.length === 0 && (
            <div className="loading">
              <div className="loading-header">
                {chartData.options.title.text}
              </div>
              <MessageBar color="yellow">
                No data available
              </MessageBar>
            </div>
          )}
          {loading && (
            <div className="loading">
              <div className="loading-header">
                {chartData.options.title.text}
              </div>
              <Skeleton height={150} count={2} borderRadius={15} />
            </div>
          )}
        </Card>
      ) : (
        <>
          <EmptyChart title="Top 5 US State By Orders" showError />
        </>
      )}
    </>
  )
}

export default Top5USStateByOrdersCount;
