import React, { useMemo, useCallback } from 'react';
import { Table, TableHeader, Button, Input, Dropdown, ButtonIcon } from 'app/components';
import { Trash3 } from 'react-bootstrap-icons';
import './index.scss';

const ShippingAccountsTable = ({ value, onChange, carriers }) => {
  // Parse the value string into rows data structure
  const rows = useMemo(() => {
    if (!carriers?.length || !value) return [];
    try {
      const parsedValue = JSON.parse(value.replace(/'/g, '"'));
      return parsedValue.map(item => {
        const carrierName = Object.keys(item)[0];
        const carrier = carriers.find(c => c.name === carrierName);
        return {
          carrier: carrier ? carrier.id : '',
          accountNumber: Object.values(item)[0]
        };
      });
    } catch (e) {
      console.error('Error parsing shipping account numbers:', e);
      return [];
    }
  }, [value, carriers]);

  const updateParentValue = useCallback((newRows) => {
    if (!carriers?.length) return;
    
    const formattedValue = JSON.stringify(
      newRows.map(row => {
        const carrier = carriers.find(c => c.id === row.carrier);
        return { [carrier ? carrier.name : row.carrier]: row.accountNumber };
      })
    ).replace(/"/g, "'");

    onChange(formattedValue);
  }, [carriers, onChange]);

  const addNewRow = useCallback(() => {
    const newRows = [...rows, { carrier: '', accountNumber: '' }];
    updateParentValue(newRows);
  }, [rows, updateParentValue]);

  const updateRow = useCallback((index, field, newValue) => {
    const newRows = [...rows];
    newRows[index][field] = newValue;
    updateParentValue(newRows);
  }, [rows, updateParentValue]);

  const deleteRow = useCallback((index) => {
    const newRows = rows.filter((_, i) => i !== index);
    updateParentValue(newRows);
  }, [rows, updateParentValue]);

  return (
    <div className="shipping-accounts-table">
      <div className="table-header">
        Shipping Account Numbers
        <Button
          variant="secondary"
          size="small"
          label="Add New"
          onClick={addNewRow}
        />
      </div>
      <Table size="medium" highlightRows>
        <TableHeader
          options={[
            { id: 'carrier', label: 'Carrier' },
            { id: 'accountNumber', label: 'Shipping Account Number' },
            { id: 'actions', label: '' },
          ]}
        />
        <tbody className="table-body">
          {rows.map((row, index) => (
            <tr key={index}>
              <td>
                <Dropdown
                  placeholder="Select Carrier"
                  name="carrier"
                  value={row.carrier}
                  onChange={(e) => updateRow(index, 'carrier', e.target.value)}
                  options={carriers.map((carrier) => ({ value: carrier.id, label: carrier.name }))}
                  showErrorMessages={false}
                />
              </td>
              <td>
                <Input
                  name="accountNumber"
                  value={row.accountNumber}
                  onChange={(e) => updateRow(index, 'accountNumber', e.target.value)}
                  placeholder="Enter account number"
                  showErrorMessages={false}
                />
              </td>
              <td>
                <ButtonIcon
                  icon={<Trash3 />}
                  destructive={true}
                  onClick={() => deleteRow(index)}
                />
              </td>
            </tr>
          ))}
          {rows.length === 0 && (
            <tr>
              <td colSpan={3} className="no-accounts-message">No shipping accounts configured</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ShippingAccountsTable;
