import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { Modal, Input, Dropdown } from 'app/components';
import { createReason, updateReason } from 'app/store/actions/merchant';
import { reasonLoadingSelector } from 'app/store/selectors/merchant';
import './index.scss';

// Regex patterns for validation
const ALPHANUMERIC_PATTERN = /^[a-zA-Z0-9]+$/;
const ALPHANUMERIC_WITH_PUNCTUATION_PATTERN = /^[a-zA-Z0-9\s,.'"/_\-();:]+$/;

const CreateEditReasonModal = ({ isOpen, onClose, merchantId, reasonType, reasonOptions, existingReasons, reason }) => {
  const dispatch = useDispatch();
  const loading = useSelector(reasonLoadingSelector);
  const reasonTypeDisplay = reasonType ? reasonType.charAt(0).toUpperCase() + reasonType.slice(1) : '';
  const isEditMode = !!reason;

  const validationSchema = object().shape({
    name: string()
      .required('Name is required')
      .max(50, 'Name must be at most 50 characters')
      .matches(ALPHANUMERIC_WITH_PUNCTUATION_PATTERN, 'Name must contain only alphanumeric characters and punctuation characters')
      .test('unique-name', 'This name is already in use', function (value) {
        if (!existingReasons) return true;
        return !existingReasons.some(existingReason =>
          existingReason.name &&
          existingReason.name.toLowerCase() === value.toLowerCase() &&
          (!isEditMode || existingReason.id !== reason.id)
        );
      }),
    code: string()
      .max(10, 'Code must be at most 10 characters')
      .matches(ALPHANUMERIC_PATTERN, 'Code must contain only alphanumeric characters'),
    description: string()
      .max(250, 'Description must be at most 250 characters'),
    category: string()
      .max(50, 'Category must be at most 50 characters')
      .matches(ALPHANUMERIC_WITH_PUNCTUATION_PATTERN, 'Category must contain only alphanumeric characters and punctuation characters'),
    suggestedCostCover: string()
  });

  const initialValues = {
    name: reason?.name || '',
    code: reason?.code || '',
    description: reason?.description || '',
    category: reason?.category || '',
    suggestedCostCover: reason?.suggestedCostCover || '',
    rank: reason?.rank || null,
    languageCode: reason?.languageCode || 'en'
  };

  const handleSaveReason = (reasonData) => {
    // Convert empty strings to null
    const processedData = Object.keys(reasonData).reduce((acc, key) => {
      acc[key] = reasonData[key] === '' ? null : reasonData[key];
      return acc;
    }, {});

    if (isEditMode) {
      dispatch(updateReason({
        merchantId,
        reasonType,
        reasonId: reason.id,
        reasonData: processedData,
        callback: (success) => {
          if (success) {
            onClose();
          }
        }
      }));
    } else {
      dispatch(createReason({
        merchantId,
        reasonType,
        reasonData: { ...processedData, rank: null },
        callback: (success) => {
          if (success) {
            onClose();
          }
        }
      }));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values) => { handleSaveReason(values); }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        handleBlur
      }) => (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          title={isEditMode ? `Edit ${reasonTypeDisplay} Reason` : `Add Custom ${reasonTypeDisplay} Reason`}
          primaryButtonLabel="Save"
          primaryButtonOnClick={handleSubmit}
          primaryButtonEnabled={!loading}
          secondaryButtonLabel="Cancel"
          secondaryButtonOnClick={onClose}
          secondaryButtonEnabled={!loading}
          className="create-edit-reason-modal"
        >
          <div className="create-edit-reason-form">
            <div className="form-row">
              <div className="form-col">
                <Input
                  label="Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  placeholder="Enter reason name"
                  required
                  errorMessage={touched.name && errors.name}
                />
              </div>
              <div className="form-col">
                <Input
                  label="Code (Optional)"
                  name="code"
                  value={values.code}
                  onChange={handleChange}
                  placeholder="Enter reason code"
                  errorMessage={touched.code && errors.code}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <Input
                  label="Description (Optional)"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  placeholder="Enter reason description"
                  errorMessage={touched.description && errors.description}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <Input
                  label="Category (Optional)"
                  name="category"
                  value={values.category}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter a category"
                  errorMessage={touched.category && errors.category}
                />
              </div>
              <div className="form-col">
                <Dropdown
                  label="Cost Cover (Optional)"
                  name="suggestedCostCover"
                  value={values.suggestedCostCover}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFieldValue(name, value);
                  }}
                  options={[
                    { value: '', label: 'None' },
                    ...(reasonOptions?.costCoverOptions || [])
                  ]}
                  placeholder="Select cost cover"
                  errorMessage={touched.suggestedCostCover && errors.suggestedCostCover}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </Formik>
  );
};

CreateEditReasonModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  merchantId: PropTypes.string.isRequired,
  reasonType: PropTypes.string.isRequired,
  existingReasons: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.string,
    suggestedCostCover: PropTypes.string,
    rank: PropTypes.number,
    isSystem: PropTypes.bool,
    isHidden: PropTypes.bool
  })),
  reasonOptions: PropTypes.shape({
    costCoverOptions: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    }))
  }),
  reason: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.string,
    suggestedCostCover: PropTypes.string,
    rank: PropTypes.number,
    languageCode: PropTypes.string,
    isSystem: PropTypes.bool,
    isHidden: PropTypes.bool
  })
};

export default CreateEditReasonModal;
