import axios from 'axios';
import Config from '../config';
import SetupInterceptors from './axiosInterceptors';

class IntegrationServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.IntegrationMS.Url
    });

    SetupInterceptors(this.api);
  }

  getIntegrations = async (data = {}) => {
    const {
      category,
      searchString,
      page,
      pageSize,
      sortBy,
      sortDir,
      isPublished,
      isPrivate,
      canBeOverridden
    } = data;

    let queryString = "";

    // helper function to append parameter if it's not null
    const addParam = (key, value) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `&${key}=${encodeURIComponent(value)}`;
      }
    };

    // append parameters dynamically if they are not null
    addParam('searchBy', searchString);
    addParam('page', page);
    addParam('pageSize', pageSize);
    addParam('sortBy', sortBy);
    addParam('sortAsc', sortDir === 'asc');
    addParam('type', category);
    addParam('isPublished', isPublished);
    addParam('isPrivate', isPrivate);
    addParam('canBeOverridden', canBeOverridden);

    try {
      const response = await this.api.get(`/v1/plugin?${queryString}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  getListOfCarriers = async () => {
    try {
      const response = await this.api.get(`/v1/carrier`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  }

  getMerchantIntegrations = async (merchantId) => {
    try {
      const response = await this.api.get(`/v1/merchantPlugin/merchant/${merchantId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  }

  getIntegrationBasicInfo = async (pluginId) => {
    try {
      const response = await this.api.get(`/v1/plugin/${pluginId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  }

  updateIntegrationBasicInfo = async (pluginId, data) => {
    try {
      const response = await this.api.patch(`/v1/plugin/${pluginId}`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating integration');
    }
  }

  getIntegrationVariables = async (pluginId, pluginVersionId) => {
    try {
      const response = await this.api.get(`/v1/plugin/${pluginId}/version/${pluginVersionId}/variableNames`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  }

  updateMerchantVariables = async (merchantPluginId, data) => {
    try {
      const response = await this.api.patch(`/v1/merchantPlugin/${merchantPluginId}`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating merchant variables');
    }
  }

  getMerchantIntegrationVariables = async (merchantPluginId) => {
    try {
      const response = await this.api.get(`/v1/merchantPlugin/${merchantPluginId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  }

  createIntegration = async (data) => {
    try {
      const response = await this.api.post('/v1/plugin', data);
      return response.data;
    } catch (error) {
      throw new Error('Error creating integration');
    }
  }

  getIntegrationVersions = async (pluginId) => {
    try {
      const response = await this.api.get(`/v1/plugin/${pluginId}/version`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  }

  getIntegrationDetails = async (pluginId, pluginVersionId) => {
    try {
      const response = await this.api.get(`/v1/plugin/${pluginId}/version/${pluginVersionId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  }

  createInitialIntegrationVersion = async (pluginId, data) => {
    try {
      const response = await this.api.post(`/v1/plugin/${pluginId}/version`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error creating Plugin version');
    }
  }

  updateExistingIntegrationVersion = async (pluginId, pluginVersionId, data) => {
    try {
      const response = await this.api.patch(`/v1/plugin/${pluginId}/version/${pluginVersionId}`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating Plugin version');
    }
  }

  publishIntegrationVersion = async (pluginId, pluginVersionId) => {
    try {
      const response = await this.api.patch(`/v1/plugin/${pluginId}/version/${pluginVersionId}/promote`);
      return response.data;
    } catch (error) {
      throw new Error('Error publishing Plugin version');
    }
  }

  cloneIntegrationVersion = async (pluginId, pluginVersionId) => {
    try {
      const response = await this.api.post(`/v1/plugin/${pluginId}/version/${pluginVersionId}/clone`);
      return response.data;
    } catch (error) {
      throw new Error('Error cloning Plugin version');
    }
  }

  deleteIntegration = async (pluginId) => {
    try {
      const response = await this.api.delete(`/v1/plugin/${pluginId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error deleting Plugin');
    }
  }

  getEventTypes = async () => {
    try {
      const response = await this.api.get(`/v1/event`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  }

  connectToIntegration = async (data) => {
    try {
      const response = await this.api.post('/v1/merchantPlugin', data);
      return response.data;
    } catch (error) {
      throw new Error('Error connecting to Plugin');
    }
  }

  disconnectIntegration = async (merchantPluginId) => {
    try {
      const response = await this.api.delete(`/v1/merchantPlugin/${merchantPluginId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error disconnecting Plugin');
    }
  }
}

export default new IntegrationServices();
