import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { SortArrow, Checkbox, TooltipIcon } from 'app/components';
import { Filter, GripVertical } from 'react-bootstrap-icons';
import { useSearchParams } from 'react-router-dom';
import './index.scss';

const TableHeader = ({
  options,
  sortBy = null, 
  sortDir = null, 
  isMasterChecked,
  onMasterCheckboxChange,
  onHeaderClick = null,
  onFilterClick = null,
  onColumnReorder = null,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [draggedColumn, setDraggedColumn] = useState(null);
  const [draggedOverColumn, setDraggedOverColumn] = useState(null);
  const draggedColumnRef = useRef(null);

  const headerClicked = (headerName) => {
    if (onHeaderClick) {
      onHeaderClick(headerName, sortBy, sortDir);
      return;
    }

    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('sortDir', headerName === sortBy ? (sortDir === 'desc' ? 'asc' : 'desc') : 'desc');
    updatedSearchParams.set('sortBy', headerName);
    setSearchParams(updatedSearchParams.toString());
  };

  const handleDragStart = (e, columnId) => {
    if (!onColumnReorder) return;
    
    setDraggedColumn(columnId);
    draggedColumnRef.current = columnId;
    
    // Make the ghost image transparent 
    const ghostElement = document.createElement('div');
    ghostElement.style.position = 'absolute';
    ghostElement.style.top = '-1000px';
    ghostElement.style.width = '10px';
    ghostElement.style.height = '10px';
    ghostElement.style.opacity = '0.01';
    document.body.appendChild(ghostElement);
    e.dataTransfer.setDragImage(ghostElement, 0, 0);
    
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.dropEffect = 'move'; 
    
    e.dataTransfer.setData('application/x-column-id', columnId);
    
    document.body.classList.add('column-dragging');
  };

  const handleDragOver = (e, columnId) => {
    if (!onColumnReorder || draggedColumn === columnId) return;
    
    e.preventDefault();
    setDraggedOverColumn(columnId);
  };

  const handleDragEnd = () => {
    if (!onColumnReorder) return;
    
    document.body.classList.remove('column-dragging');

    if (draggedColumn && draggedOverColumn && draggedColumn !== draggedOverColumn) {
      onColumnReorder(draggedColumn, draggedOverColumn);
    }
    
    setDraggedColumn(null);
    setDraggedOverColumn(null);
    draggedColumnRef.current = null;
  };

  const handleDrop = (e, columnId) => {
    if (!onColumnReorder) return;
    
    e.preventDefault();
    const sourceColumnId = e.dataTransfer.getData('application/x-column-id');
    
    if (!sourceColumnId && draggedColumnRef.current) {
      onColumnReorder(draggedColumnRef.current, columnId);
    } else if (sourceColumnId && sourceColumnId !== columnId) {
      onColumnReorder(sourceColumnId, columnId);
    }
    
    setDraggedColumn(null);
    setDraggedOverColumn(null);
    document.body.classList.remove('column-dragging');
  };

  return (
    <thead className="gooten-table-header">
      <tr>
        {options.map((option) => (
          <React.Fragment key={option.id}>
            {option.isMasterCheckbox ? (
              <th style={{ width: option.width }}>
                <div className={`column-header ${option.align || ''}`}>
                  <Checkbox size='medium' checked={isMasterChecked} onChange={onMasterCheckboxChange} />
                </div>
              </th>
            ) : (
              <th
                className={`${option.orderable ? 'orderable' : ''} 
                  ${onColumnReorder ? 'draggable' : ''} 
                  ${draggedColumn === option.id ? 'dragging' : ''}
                  ${draggedOverColumn === option.id ? 'drag-over' : ''}`}
                style={{ width: option.width }}
                onClick={() => option.orderable ? headerClicked(option.id) : null}
                draggable={!!onColumnReorder}
                onDragStart={(e) => handleDragStart(e, option.id)}
                onDragOver={(e) => handleDragOver(e, option.id)}
                onDragEnd={handleDragEnd}
                onDrop={(e) => handleDrop(e, option.id)}
              >
                <div className={`column-header ${option.align || ''}`}>
                  {onColumnReorder && (
                    <div className="drag-handle">
                      <GripVertical />
                    </div>
                  )}
                  <span>{option.label}</span>
                  {option.tooltip && (
                    <span className="tooltip-icon">
                      <TooltipIcon htmlContent={option.tooltip} />
                    </span>
                  )}
                  <div className="header-icons">
                    {option.orderable && <SortArrow direction={sortBy === option.id ? sortDir : null} />}
                    {onFilterClick && (
                      <div 
                        className="filter-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          onFilterClick(option.id);
                        }}
                      >
                        <Filter className={option.filterActive ? 'active' : ''} />
                      </div>
                    )}
                  </div>
                </div>
              </th>
            )}
          </React.Fragment>
        ))}
      </tr>
    </thead>
  );
};

TableHeader.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      orderable: PropTypes.bool,
      align: PropTypes.string,
      isMasterCheckbox: PropTypes.bool,
      tooltip: PropTypes.string
    })
  ).isRequired,
  sortBy: PropTypes.string,
  sortDir: PropTypes.string,
  isMasterChecked: PropTypes.bool,
  onMasterCheckboxChange: PropTypes.func,
  onHeaderClick: PropTypes.func,
  onFilterClick: PropTypes.func,
  onColumnReorder: PropTypes.func
};

export default TableHeader;
