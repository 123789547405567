import React from 'react';
import { Modal, Button } from 'app/components';
import { useDispatch } from 'react-redux';
import { deleteExport } from 'app/store/actions/insight';
import './index.scss';

const DeleteExportModal = ({ exportData, showDeleteExportModal }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteExport({ exportId: exportData.id }));
    showDeleteExportModal(false);
  };

  return (
    <Modal
      className="delete-export-modal"
      title={`Delete Export ${exportData.name ? ` - ${exportData.name}` : ''}`}
      hideButtons={true}
      onClose={() => { showDeleteExportModal(null); }}
    >
      <p>Are you sure you want to delete this export?</p>
      <div className="action-buttons">
        <Button
          variant="secondary"
          size="small"
          label="Cancel"
          onClick={() => { showDeleteExportModal(null); }}
        />
        <Button
          variant="primary"
          size="small"
          label="Delete Export"
          destructive
          onClick={handleDelete}
        />
      </div>
    </Modal>
  );
};

export default DeleteExportModal;
