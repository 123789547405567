import axios from 'axios';
import Config from '../config';
import SetupInterceptors from './axiosInterceptors';

class MerchantServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.MerchantMS.Url
    });

    SetupInterceptors(this.api);
  }

  getMerchants = async (searchString, currentPage, pageSize, sortBy, sortDir) => {
    let queryString = "";

    // helper function to append parameter if it's not null
    const addParam = (key, value) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `&${key}=${encodeURIComponent(value)}`;
      }
    };

    // append parameters dynamically if they are not null
    addParam('query', searchString);
    addParam('page', currentPage);
    addParam('pageSize', pageSize);
    addParam('sortBy', sortBy);
    addParam('sortDesc', sortDir === 'asc');

    try {
      const response = await this.api.get(`/v1/merchant?${queryString}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  searchMerchants = async (searchString, page, pageSize, sortBy, sortDir, disableXAccessHeader = false) => {
    let queryString = "";

    // helper function to append parameter if it's not null
    const addParam = (key, value) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `&${key}=${encodeURIComponent(value)}`;
      }
    };

    // append parameters dynamically if they are not null
    addParam('query', searchString);
    addParam('page', page);
    addParam('pageSize', pageSize);
    addParam('sortBy', sortBy);
    addParam('sortAsc', sortDir === 'asc');

    try {
      let url = `/v1/merchant/search?${queryString}`;
      if (disableXAccessHeader) {
        url += "&disableXAccessHeader";
      }
      const response = await this.api.get(url);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  searchMerchantsByNameOnly = async (name, page, pageSize, sortDir, disableXAccessHeader = false) => {
    let queryString = "";

    // helper function to append parameter if it's not null
    const addParam = (key, value) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `&${key}=${encodeURIComponent(value)}`;
      }
    };

    // append parameters dynamically if they are not null
    addParam('name', name);
    addParam('page', page);
    addParam('pageSize', pageSize);
    addParam('sortBy', 'name');
    addParam('sortAsc', sortDir === 'asc');

    try {
      let url = `/v1/merchant/search?${queryString}`;
      if (disableXAccessHeader) {
        url += "&disableXAccessHeader";
      }
      const response = await this.api.get(url);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  getMerchantById = async (merchantId) => {
    try {
      const response = await this.api.get(`/v1/merchant/${merchantId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  createMerchant = async (data) => {
    try {
      const response = await this.api.post(`/v1/merchant`, data);
      return response.data;
    } catch (error) {

      if (error?.response?.data?.errors?.length > 0) {
        throw error.response.data.errors[0].errorMessage;
      }
      throw error?.response?.data?.message || error.message || 'Create Merchant Failed';
    }
  };

  updateMerchant = async (data) => {
    try {
      const response = await this.api.put(`/v1/merchant`, data);
      return response.data;
    } catch (error) {
      throw (error?.response?.data?.RequestId || 'Update Merchant Failed');
    }
  };

  bulkGetMerchants = async (merchantIds) => {
    try {
      const response = await this.api.get(`/v1/merchant/bulk?${merchantIds.map(id => `bulk=${id}`).join('&')}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  getReasons = async (merchantId, reasonType) => {
    try {
      const response = await this.api.get(`/v1/merchant/${merchantId}/reason/${reasonType}`);
      return response.data.reasons;
    } catch (error) {
      throw new Error(`Error fetching ${reasonType} reasons from API`);
    }
  };

  createReason = async (merchantId, reasonType, reasonData) => {
    try {
      const response = await this.api.post(`/v1/merchant/${merchantId}/reason/${reasonType}`, reasonData);
      return response.data;
    } catch (error) {
      throw new Error(`Error creating ${reasonType} reason from API`);
    }
  };

  updateReason = async (merchantId, reasonType, reasonId, reasonData) => {
    try {
      const response = await this.api.put(`/v1/merchant/${merchantId}/reason/${reasonType}/${reasonId}`, reasonData);
      return response.data;
    } catch (error) {
      throw new Error(`Error updating ${reasonType} reason from API`);
    }
  };

  deleteReason = async (merchantId, reasonType, reasonId) => {
    try {
      const response = await this.api.delete(`/v1/merchant/${merchantId}/reason/${reasonType}/${reasonId}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error deleting ${reasonType} reason from API`);
    }
  }; 
}

export default new MerchantServices();