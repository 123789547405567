import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  createRoutingStrategy,
  updateRoutingStrategy,
  getStrategyInfo,
  getRoutingStrategyDataSets,
} from "app/store/actions/routing";
import {
  createStrategyLoadingSelector,
  updateStrategyLoadingSelector,
  deleteStrategyLoadingSelector,
  strategyInfoSelector,
  strategyInfoLoadingSelector,
  strategyInfoErrorSelector,
  getDataSetsSelector,
} from "app/store/selectors/routing";
import {
  categoriesDataSelector,
  categoriesLoadingSelector,
  categoriesErrorsSelector,
} from "app/store/selectors/catalog";
import { getCategories } from "app/store/actions/catalog";
import { getAllVendors } from "app/store/actions/vendor";
import { getMerchantDetails } from "app/store/actions/merchant";
import {
  merchantDetailsSelector,
  merchantDetailsLoadingSelector,
  merchantDetailsErrorsSelector,
} from "app/store/selectors/merchant";
import {
  vendorsDataSelector,
  vendorsLoadingSelector,
  vendorsErrorsSelector,
} from "app/store/selectors/vendor";
import { object, string, array, mixed, ref } from "yup";
import {
  Button,
  Input,
  LoadingAnimation,
  Card,
  ButtonIcon,
} from "app/components";
import { ExclamationCircle, ArrowLeft } from "react-bootstrap-icons";
import { CountryList } from "app/constants";
import AdditionalStrategyOptions from "../StrategyOverview/AdditionalStrategyOptions";
import Block from "../StrategyOverview/Block";
import "./index.scss";
import { Conditions, Operators } from "../StrategyOverview/Block/Utils";

const StrategyOverview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [editMode, setEditMode] = useState(false);
  const [createMode, setCreateMode] = useState(false);

  const vendorsData = useSelector(vendorsDataSelector);
  const vendorsLoading = useSelector(vendorsLoadingSelector);
  const vendorsErrors = useSelector(vendorsErrorsSelector);
  const categories = useSelector(categoriesDataSelector);
  const categoriesLoading = useSelector(categoriesLoadingSelector);
  const categoriesErrors = useSelector(categoriesErrorsSelector);
  const merchantDetailsLoading = useSelector(merchantDetailsLoadingSelector);
  const merchantDetails = useSelector(merchantDetailsSelector);
  const merchantDetailsErrors = useSelector(merchantDetailsErrorsSelector);
  const createStrategyLoading = useSelector(createStrategyLoadingSelector);
  const updateStrategyLoading = useSelector(updateStrategyLoadingSelector);
  const deleteStrategyLoading = useSelector(deleteStrategyLoadingSelector);
  const strategyInfo = useSelector(strategyInfoSelector);
  const strategyInfoLoading = useSelector(strategyInfoLoadingSelector);
  const strategyInfoError = useSelector(strategyInfoErrorSelector);
  const dataSets = useSelector(getDataSetsSelector);

  const { merchantId, strategyId } = useParams();

  useEffect(() => {
    // Check if the path contains "add-new" and set create mode to true
    if (location.pathname.includes("add-new")) {
      setCreateMode(true);
    }

    // we need the list of vendors to render the page
    dispatch(getAllVendors({ currentPage: 1, pageSize: 250 }));
    
    // if we are in edit mode and dont have the categories list already, get it
    if (!categories) {
      dispatch(getCategories());
    }
    // get the merchant details
    dispatch(getMerchantDetails(merchantId));

    if (!dataSets) {
      dispatch(getRoutingStrategyDataSets({ strategyId }));
    }
    // if a strategyId is passed in, then we are viewing an existing strategy
    if (strategyId) {
      // get the strategy info for this specific strategy
      dispatch(getStrategyInfo({ strategyId }));
    } else {
      // they are creating a new strategy, put it in edit mode
      setEditMode(true);
    }
  }, []);

  const getVendorNameById = (vendorId) => {
    const vendor = vendorsData.vendors.find(
      (vendor) => vendor.vendorId === vendorId
    );
    return vendor?.name || "";
  };

  const getFacilityNameById = (facilityId) => {
    for (const item of vendorsData.vendors) {
      const facility = item.facilities.find((f) => f.id === facilityId);
      if (facility) return facility.name;
    }
    return "";
  };

  const onCancelButtonPressed = (isSubmitting, resetForm) => {
    if (isSubmitting) return;
    if (strategyInfo && editMode) {
      resetForm();
      setEditMode(false);
    } else if (merchantId) {
      navigate(`/admin/routing/${merchantId}`);
    } else {
      navigate(`/admin/routing`);
    }
  };

  const onRoutingStrategyCreated = () => {
    if (merchantId) {
      navigate(`/admin/routing/${merchantId}`);
    } else {
      navigate(`/admin/routing`);
    }
  };

  const onRoutingStrategyUpdated = () => {
    if (merchantId) {
      navigate(`/admin/routing/${merchantId}`);
    } else {
      navigate(`/admin/routing`);
    }
  };

  const onRoutingStrategyDeleted = () => {
    if (merchantId) {
      navigate(`/admin/routing/${merchantId}`);
    } else {
      navigate(`/admin/routing`);
    }
  };

  const transformCountryCodeToMultiSelect = (countryCode) => {
    const country = CountryList.find(
      (country) => country.CountryCode === countryCode
    );
    return { value: countryCode, label: `${countryCode} - ${country.Name}` };
  };

  const transformCategoryToMultiSelect = (categoryId) => {
    const category = categories.find((category) => category.id === categoryId);
    if (!category) {
      console.error("Filter contains non-existant category :", categoryId);
      return { value: categoryId, label: categoryId };
    }
    return { value: category.id, label: category.title };
  };

  const parseFilterExpression = (filterExpression) => {
    if (!filterExpression) {
      return null;
    }

    // in case we get a filter expression with curly double quotes, replace them with straight double quotes
    filterExpression = filterExpression.replace(/“|”/g, '"');
    // do the same for single quotes
    filterExpression = filterExpression.replace(/‘|’/g, "'");

    const filters = [];

    /// CustomCode
    const customCodePattern = /^\(\(\((.*)\)\)\)$/;
    let customCodeMatch;
    if ((customCodeMatch = customCodePattern.exec(filterExpression))) {
      let customCode = {
        condition: Conditions.CustomCode.Name,
        logic: "-",
        value: customCodeMatch[1],
        value2: "-",
      };

      filters.push(customCode);
      return filters;
    }

    // ShipToCountry
    const shipToCountryPattern = /input1.OrderItemDataPoints.OrderShipCountryCode == "(\S+)"/g;
    let countryMatch;
    let OrderShipCountryCode = null;
    while ((countryMatch = shipToCountryPattern.exec(filterExpression)) !== null) {
      if (OrderShipCountryCode === null) {
        OrderShipCountryCode = {
          condition: Conditions.ShipToCountry.Name,
          logic: Conditions.ShipToCountry.Operators.IsEqualTo,
          value: [transformCountryCodeToMultiSelect(countryMatch[1])],
          value2: "",
        };
      } else {
        // if there are multiple country codes, we need to add them to the value
        OrderShipCountryCode.value.push(
          transformCountryCodeToMultiSelect(countryMatch[1])
        );
      }
    }
    // if there were country code, then add them to our filter array
    if (OrderShipCountryCode) {
      filters.push(OrderShipCountryCode);
    }

    // ProductCategory
    const categoryContainsPattern = /input1.OrderItemDataPoints.ProductCategoryName.Any\(category => category.Contains/g;
    const categoryIsEqualToPattern = /input1.OrderItemDataPoints.ProductCategoryName.Any/g;
    const categoryIsEqualToValuesPattern = /category == "(\S+)"/g;
    const categoryContainsValuePattern = /"(\S+)"/g;

    if (categoryContainsPattern.exec(filterExpression)) {
      let categoryMatch;
      let ProductCategoryName = null;
      while ((categoryMatch = categoryContainsValuePattern.exec(filterExpression)) !== null) {
        ProductCategoryName = {
          condition: Conditions.ProductCategory.Name,
          logic: Conditions.ProductCategory.Operators.Contains,
          value: categoryMatch[1],
          value2: "",
        };
      }
      // if there were categories, then add them to our filter array
      if (ProductCategoryName) {
        filters.push(ProductCategoryName);
      }
    }

    if (categoryIsEqualToPattern.exec(filterExpression)) {
      let categoryMatch;
      let ProductCategoryName = null;
      while ((categoryMatch = categoryIsEqualToValuesPattern.exec(filterExpression)) !== null) {
        if (ProductCategoryName === null) {
          ProductCategoryName = {
            condition: Conditions.ProductCategory.Name,
            logic: Conditions.ProductCategory.Operators.IsEqualTo,
            value: [transformCategoryToMultiSelect(categoryMatch[1])],
            value2: "",
          };
        } else {
          ProductCategoryName.value.push(
            transformCategoryToMultiSelect(categoryMatch[1])
          );
        }
      }
      // if there were categories, then add them to our filter array
      if (ProductCategoryName) {
        filters.push(ProductCategoryName);
      }
    }

    // OrderMeshSku
    const omSKUContainsPattern = /input1.OrderItemDataPoints.OrderMeshSKU.Any\(sku => sku.Contains/g;
    const omSKUIsEqualToPattern = /input1.OrderItemDataPoints.OrderMeshSKU.Any/g;
    const omSKUIsEqualToValuesPattern = /sku == "(\S+)"/g;
    const omSKUContainsValuePattern = /"(\S+)"/g;

    if (omSKUContainsPattern.exec(filterExpression)) {
      let skuMatch;
      let omSKU = null;
      while ((skuMatch = omSKUContainsValuePattern.exec(filterExpression)) !== null) {
        omSKU = {
          condition: Conditions.OrderMeshSKU.Name,
          logic: Conditions.OrderMeshSKU.Operators.Contains,
          value: skuMatch[1],
          value2: "",
        };
      }

      if (omSKU) {
        filters.push(omSKU);
      }
    }

    if (omSKUIsEqualToPattern.exec(filterExpression)) {
      let skuMatch;
      let omSKU = null;
      while ((skuMatch = omSKUIsEqualToValuesPattern.exec(filterExpression)) !== null) {
        if (omSKU === null) {
          omSKU = {
            condition: Conditions.OrderMeshSKU.Name,
            logic: Conditions.OrderMeshSKU.Operators.IsEqualTo,
            value: [skuMatch[1]],
            value2: "",
          };
        } else {
          omSKU.value = omSKU.value + `,${skuMatch[1]}`;
        }
      }

      if (omSKU) {
        filters.push(omSKU);
      }
    }

    // Quantity
    const quantityPattern = /input1.OrderItemDataPoints.Quantity (\S+) (\S+)/g;
    const quantityMatch = quantityPattern.exec(filterExpression);
    if (quantityMatch) {
      // we found a quanity, is there another one?
      const quantityMatch2 = quantityPattern.exec(filterExpression);
      if (quantityMatch2) {
        // there are two quantities, add them to the filter array
        filters.push({
          condition: Conditions.Quantity.Name,
          logic: Conditions.Quantity.Operators.IsBetween,
          value: quantityMatch[2],
          value2: quantityMatch2[2],
        });
      } else {
        // there is only one quantity, add it to the filter array
        filters.push({
          condition: Conditions.Quantity.Name,
          logic: convertSymbolToStringLogic(quantityMatch[1]),
          value: quantityMatch[2],
          value2: "",
        });
      }
    }

    // CustomerShippingMethod 
    const customerShippingMethodPattern = /input1.OrderItemDataPoints.CustomerShippingMethod == "(\S+)"/g;
    let methodMatch;
    let customerShippingMethod = null;
    while ((methodMatch = customerShippingMethodPattern.exec(filterExpression)) !== null) {
      if (customerShippingMethod === null) {
        customerShippingMethod = {
          condition: Conditions.CustomerShippingMethod.Name,
          logic: Conditions.CustomerShippingMethod.Operators.IsEqualTo,
          value: [{ value: methodMatch[1], label: methodMatch[1] }],
          value2: "",
        };
      } else {
        // if there are multiple country codes, we need to add them to the value
        customerShippingMethod.value.push(
          { value: methodMatch[1], label: methodMatch[1] }
        );
      }
    }
    // if there were country code, then add them to our filter array
    if (customerShippingMethod) {
      filters.push(customerShippingMethod);
    }

    // CustomerOrderTotal
    const orderTotalPattern = /input1.OrderItemDataPoints.CustomerOrderTotal (\S+) (\S+)/g;
    const orderQuantityMatch = orderTotalPattern.exec(filterExpression);
    if (orderQuantityMatch) {
      // we found a quanity, is there another one?
      const orderQuantityMatch2 = orderTotalPattern.exec(filterExpression);
      if (orderQuantityMatch2) {
        // there are two quantities, add them to the filter array
        filters.push({
          condition: Conditions.CustomerOrderTotal.Name,
          logic: Conditions.CustomerOrderTotal.Operators.IsBetween,
          value: orderQuantityMatch[2],
          value2: orderQuantityMatch2[2],
        });
      } else {
        // there is only one quantity, add it to the filter array
        filters.push({
          condition: Conditions.CustomerOrderTotal.Name,
          logic: convertSymbolToStringLogic(orderQuantityMatch[1]),
          value: orderQuantityMatch[2],
          value2: "",
        });
      }
    }

    // Metadata
    const metadataEqualsPattern = /input1.Metadata.(\S+) (\S+) "+(\S+)"+/g;
    const metadataContainsPattern = /input1.Metadata.(\S+)\.Contains\("(\S+)"\)/g;
    let metadataEqualsMatch;
    while ((metadataEqualsMatch = metadataEqualsPattern.exec(filterExpression)) !== null) {
      filters.push({
        condition: metadataEqualsMatch[1],
        logic: convertSymbolToStringLogic(metadataEqualsMatch[2]),
        value: metadataEqualsMatch[3],
        value2: "",
      });
    }

    // now do the same for the metadata.  Just like quantity, it could have a between or not.
    let metadataContainsMatch;
    while ((metadataContainsMatch = metadataContainsPattern.exec(filterExpression)) !== null) {
      filters.push({
        condition: metadataContainsMatch[1],
        logic: "contains",
        value: metadataContainsMatch[2],
        value2: "",
      });
    }

    // now that we have all the metadata, check to see if any of them have the exact same condition.  If they do, we need to combine them
    const metadataConditions = filters
      .filter(
        (filter) =>
          filter.condition !== "ShipToCountry" &&
          filter.condition !== "ProductCategory" &&
          filter.condition !== "Quantity"
      )
      .map((filter) => filter.condition);
    const uniqueMetadataConditions = [...new Set(metadataConditions)];
    for (let i = 0; i < uniqueMetadataConditions.length; i++) {
      const condition = uniqueMetadataConditions[i];
      const matchingFilters = filters.filter(
        (filter) => filter.condition === condition
      );
      if (matchingFilters.length > 1) {
        // there are multiple filters with the same condition, we need to combine them
        let combinedFilter = {
          condition: matchingFilters[0].condition,
          logic: "is between",
          value: matchingFilters[0].value,
          value2: matchingFilters[1].value,
        };

        filters.push(combinedFilter);
      }
    }
    // delete the original 2 filters that were combined
    for (let i = 0; i < uniqueMetadataConditions.length; i++) {
      const condition = uniqueMetadataConditions[i];
      const matchingFilters = filters.filter(
        (filter) => filter.condition === condition
      );
      if (matchingFilters.length > 1) {
        filters.splice(filters.indexOf(matchingFilters[0]), 1);
        filters.splice(filters.indexOf(matchingFilters[1]), 1);
      }
    }

    return filters;
  };

  const buildFilterExpression = (filters) => {
    let filterExpression = "";
    // loop through each filter and build the filter expression
    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];
      if (i > 0) {
        filterExpression += " AND ";
      }

      if (filter.condition === Conditions.CustomCode.Name) {
        filterExpression += `(((${filter.value})))`;
        continue;
      }

      if (filter.condition === Conditions.ShipToCountry.Name) {
        filterExpression += "(";
        for (let j = 0; j < filter.value.length; j++) {
          if (j > 0) {
            filterExpression += " OR ";
          }
          filterExpression += `input1.OrderItemDataPoints.OrderShipCountryCode == "${filter.value[j].value}"`;
        }
        filterExpression += ")";
        continue;
      }

      if (filter.condition === Conditions.ProductCategory.Name) {
        if (filter.logic === Conditions.ProductCategory.Operators.Contains) {
          filterExpression += `input1.OrderItemDataPoints.ProductCategoryName.Any(category => category.Contains("${filter.value}"))`;
        }
        if (filter.logic === Conditions.ProductCategory.Operators.IsEqualTo) {
          filterExpression +=
            "input1.OrderItemDataPoints.ProductCategoryName.Any(category => ";
          for (let j = 0; j < filter.value.length; j++) {
            if (j > 0) {
              filterExpression += " || ";
            }
            filterExpression += `category == "${filter.value[j].value}"`;
          }
          filterExpression += ")";
        }
        continue;
      }

      if (filter.condition === Conditions.Quantity.Name) {
        if (filter.logic === Conditions.Quantity.Operators.IsBetween) {
          filterExpression += `input1.OrderItemDataPoints.Quantity >= ${filter.value} AND input1.OrderItemDataPoints.Quantity <= ${filter.value2}`;
        } else {
          filterExpression += `input1.OrderItemDataPoints.Quantity ${convertStringLogicToSymbol(
            filter.logic
          )} ${filter.value}`;
        }
        continue;
      }

      if (filter.condition === Conditions.OrderMeshSKU.Name) {
        if (filter.logic === Conditions.OrderMeshSKU.Operators.Contains) {
          filterExpression += `input1.OrderItemDataPoints.OrderMeshSKU.Any(sku => sku.Contains("${filter.value}"))`;
        }
        if (filter.logic === Conditions.OrderMeshSKU.Operators.IsEqualTo) {
          filterExpression +=
            "input1.OrderItemDataPoints.OrderMeshSKU.Any(sku => ";

          let values = filter.value.split(",");
          for (let j = 0; j < values.length; j++) {
            if (j > 0) {
              filterExpression += " || ";
            }
            filterExpression += `sku == "${values[j]}"`;
          }
          filterExpression += ")";
        }
        continue;
      }

      if (filter.condition === Conditions.CustomerShippingMethod.Name) {
        filterExpression += "(";
        for (let j = 0; j < filter.value.length; j++) {
          if (j > 0) {
            filterExpression += " OR ";
          }
          filterExpression += `input1.OrderItemDataPoints.CustomerShippingMethod == "${filter.value[j].value}"`;
        }
        filterExpression += ")";
        continue;
      }

      if (filter.condition === Conditions.CustomerOrderTotal.Name) {
        if (filter.logic === Conditions.CustomerOrderTotal.Operators.IsBetween) {
          filterExpression += `input1.OrderItemDataPoints.CustomerOrderTotal >= ${filter.value} AND input1.OrderItemDataPoints.CustomerOrderTotal <= ${filter.value2}`;
        } else {
          filterExpression += `input1.OrderItemDataPoints.CustomerOrderTotal ${convertStringLogicToSymbol(
            filter.logic
          )} ${filter.value}`;
        }
        continue;
      }

      // logic for all other metadata can only be 'contains' or 'equals'
      if (filter.logic === Operators.Contains) {
        filterExpression += `input1.Metadata.${filter.condition}.Contains("${filter.value}")`;
      } else {
        // equals
        filterExpression += `input1.Metadata.${filter.condition} == "${filter.value}"`;
      }

    }

    return filterExpression;
  };

  const convertStringLogicToSymbol = (logic) => {
    switch (logic) {
      case Operators.IsEqualTo:
        return "==";
      case Operators.IsNotEqualTo:
        return "!=";
      case Operators.IsLessThan:
        return "<";
      case Operators.IsLessThanOrEqual:
        return "<=";
      case Operators.IsGreaterThan:
        return ">";
      case Operators.IsGreaterThanOrEqual:
        return ">=";
      default:
        return logic;
    }
  };

  const convertSymbolToStringLogic = (symbol) => {
    switch (symbol) {
      case "==":
        return Operators.IsEqualTo;
      case "!=":
        return Operators.IsNotEqualTo;
      case "<":
        return Operators.IsLessThan;
      case "<=":
        return Operators.IsLessThanOrEqual;
      case ">":
        return Operators.IsGreaterThan;
      case ">=":
        return Operators.IsGreaterThanOrEqual;
      default:
        return symbol;
    }
  };

  const defineInitialValues = (strategyInfo) => {
    const blocks = [];

    if (!strategyInfo) {
      blocks.push({
        blockName: "",
        groups: [
          {
            groupName: "",
            orderingAlgorithm: "",
            tiebreakerOrderingAlgorithm: "",
            orderingAlgorithmDirection: 'asc',
            vendors: [
              {
                vendorId: "",
                facilityId: "",
                rank: "0",
              },
            ],
          },
        ],
        filterInfo: null,
        retryRules: {
          rule1: "Full",
          rule2: "",
          rule3: "",
        },
      });
    } else {
      for (let i = 0; i < strategyInfo.assignedBlocks.length; i++) {
        const b = strategyInfo.assignedBlocks[i];
        const block = {
          id: b.id,
          blockName: b.name || "",
          groups: [],
          filterInfo: null,
          retryRules: {
            rule1: b.retryActions.retryAction1,
            rule2: b.retryActions.retryAction2,
            rule3: b.retryActions.retryAction3,
          },
        };

        for (let j = 0; j < b.vendorGroups.length; j++) {
          let group = {
            groupName: b.vendorGroups[j].vendorGroupId || "",
            orderingAlgorithm: b.vendorGroups[j].orderingAlgorithm,
            tiebreakerOrderingAlgorithm:
              b.vendorGroups[j].tiebreakerOrderingAlgorithm || "",
            orderingAlgorithmDirection: 'asc',
            vendors: [],
            filter: {
              expression: b.vendorGroups?.[j]?.filter?.[0]?.rules?.[0]?.expression
            }
          };

          // Transform dataset ordering algorithm
          if (group.orderingAlgorithm == "DataSet") {
            const { variableName, sortAscending } = b.vendorGroups[j].orderingAlgorithmOptions;
            group.orderingAlgorithm = `dataset*${variableName}`;
            group.orderingAlgorithmDirection = sortAscending ? 'asc' : 'desc';
          }

          for (let k = 0; k < b.vendorGroups[j].vendors.length; k++) {
            for (
              let l = 0;
              l < b.vendorGroups[j].vendors[k].vendorFacilities.length;
              l++
            ) {
              let vendor = {
                vendorId: b.vendorGroups[j].vendors[k].vendorId,
                facilityId: b.vendorGroups[j].vendors[k].vendorFacilities[l].id,
                rank: String(
                  b.vendorGroups[j].vendors[k].vendorFacilities[l].rank
                ),
              };
              group.vendors.push(vendor);
            }
          }
          block.groups.push(group);
        }

        // if there are filters, we need to parse them out and put them in the correct format
        if (!b.filter || b.filter.length === 0) {
          block.filterInfo = null;
        } else {
          // there are filters, initialize the filterInfo object
          // before adding the filters, make sure all parts are valid.  If not, do not use them (this gets rid of old broken filters)
          if (
            b?.filter?.[0]?.rules?.[0]?.expression &&
            b?.filter?.[0]?.rules?.[0]?.ruleName
          ) {
            block.filterInfo = {
              filterExpression: b.filter[0].rules[0].expression,
              filterName: b.filter[0].rules[0].ruleName || "",
              filters: parseFilterExpression(b.filter[0].rules[0].expression),
            };
          } else {
            block.filterInfo = null;
          }
        }

        blocks.push(block);
      }
    }

    return {
      strategyName: strategyInfo?.routingStrategyName || "",
      isActive: strategyInfo?.isActive || false,
      blocks,
    };
  };

  let errorMessage;

  if (vendorsErrors) {
    errorMessage = (
      <div className="data-load-failed">
        <ExclamationCircle /> Vendors data failed to load. Refresh the page to
        try again.
      </div>
    );
  } else if (strategyInfoError) {
    errorMessage = (
      <div className="data-load-failed">
        <ExclamationCircle /> Strategy information failed to load. Refresh the
        page to try again.
      </div>
    );
  } else if (merchantDetailsErrors) {
    errorMessage = (
      <div className="data-load-failed">
        <ExclamationCircle /> Merchant data failed to load. Refresh the page to
        try again.
      </div>
    );
  } else if (categoriesErrors) {
    errorMessage = (
      <div className="data-load-failed">
        <ExclamationCircle /> Category data failed to load. Refresh the page to
        try again.
      </div>
    );
  }

  const strategyOptionsButton = (isSubmitting, handleSubmit, resetForm, standalone) =>
    <div className={standalone ? 'strategy-options-button' : ''} >
      {strategyInfo && !editMode ? (
        <AdditionalStrategyOptions
          strategyId={strategyId}
          isActive={strategyInfo.isActive}
          onEditStrategy={() => setEditMode(true)}
          onRoutingStrategyDeleted={() =>
            onRoutingStrategyDeleted()
          }
        />
      ) : (
        <div className="action-buttons">
          <Button
            variant="secondary"
            size="medium"
            label="Cancel"
            onClick={() =>
              onCancelButtonPressed(isSubmitting, resetForm)
            }
            disabled={
              createStrategyLoading ||
              updateStrategyLoading ||
              deleteStrategyLoading
            }
          />
          {!errorMessage && (
            <Button
              variant="primary"
              size="medium"
              label="Save Strategy"
              onClick={() =>
                isSubmitting ? null : handleSubmit()
              }
              disabled={
                createStrategyLoading ||
                updateStrategyLoading ||
                deleteStrategyLoading
              }
            />
          )}
        </div>
      )}
    </div>

  return (
    <div className="strategy-edit-create">
      {(vendorsLoading ||
        createStrategyLoading ||
        updateStrategyLoading ||
        deleteStrategyLoading ||
        strategyInfoLoading ||
        categoriesLoading ||
        merchantDetailsLoading) && <LoadingAnimation />}
      {((vendorsData &&
        merchantDetails &&
        categories &&
        (strategyId ? !!strategyInfo : true)) ||
        errorMessage) && (
          <Formik
            enableReinitialize
            initialValues={defineInitialValues(strategyInfo)}
            validationSchema={() =>
              object().shape({
                strategyName: string().required("Strategy Name is required"),
                blocks: array().of(
                  object().shape({
                    blockName: string().required("Block Name is required"),
                    groups: array().of(
                      object().shape({
                        groupName: string().required("Group Name is required"),
                        orderingAlgorithm: string().required(
                          "Sorting Mechanism is required"
                        ),
                        orderingAlgorithmDirection: string(),
                        tiebreakerOrderingAlgorithm: string(),
                       
                        vendors: array()
                          .of(
                            object().shape({
                              vendorId: string().required(
                                "Vendor Name is required"
                              ),
                              facilityId: string().required(
                                "Facility Name is required"
                              ),
                              rank: string().required("Rank is required"),
                            })
                          )
                          .min(1, "At least one vendor must be added"),
                      })
                    ),
                    // add in the validation for filterInfo and all it's children.  Filter info is only required if it's not null
                    filterInfo: object()
                      .shape({
                        filterName: string().when("filters", {
                          is: (filters) => filters && filters.length > 0,
                          then: () =>
                            string().required("Filter Name is required"),
                          otherwise: () => string(),
                        }),
                        filters: array().of(
                          object().shape({
                            condition: string()
                              .required("Condition is required")
                              .notOneOf(["-"], "Condition is required"),
                            logic: string().required("Logic is required"),
                            value: mixed().test(
                              "is-string-or-array",
                              "Value is required",
                              (value) => {
                                if (Array.isArray(value)) {
                                  return array()
                                    .of(
                                      object().shape({
                                        value:
                                          string().required("Value is required"),
                                        label:
                                          string().required("Label is required"),
                                      })
                                    )
                                    .min(1, "At least one value must be added")
                                    .isValidSync(value);
                                }
                                return string()
                                  .required("Value is required")
                                  .isValidSync(value);
                              }
                            ),
                            value2: string().when("logic", {
                              is: (logic) => logic === "is between",
                              then: () =>
                                string()
                                  .required("Value2 is required")
                                  .test(
                                    "is-less-than-or-equal",
                                    "Should be ≥ Value1",
                                    function (value2) {
                                      const value1 = this.resolve(ref("value"));
                                      return (
                                        !value1 ||
                                        !value2 ||
                                        parseInt(value1) <= parseInt(value2)
                                      );
                                    }
                                  ),
                              otherwise: () => string().notRequired(),
                            }),
                          })
                        ),
                      })
                      .notRequired(),
                    retryRules: object().shape({
                      rule1: string().required("Default is required"),
                      rule2: string().required("Retry Rule 1 is required"),
                      rule3: string().required("Retry Rule 2 is required"),
                    }),
                  })
                ),
              })
            }
            onSubmit={async (values) => {
              const routingStrategy = {
                merchantId: merchantId,
                routingStrategyName: values.strategyName,
                isActive: values.isActive,
                assignedBlocks: [],
              };

              // if we are editing an existing strategy, we need to add the id
              if (strategyId) {
                routingStrategy.id = strategyId;
              }

              for (let x = 0; x < values.blocks.length; x++) {
                const block = values.blocks[x];
                const data = {
                  name: block.blockName,
                  isRuleEngineEnabled: true,
                  filter: [],
                  vendorGroups: [],
                  retryActions: {
                    retryAction1: block.retryRules.rule1,
                    retryAction2: block.retryRules.rule2,
                    retryAction3: block.retryRules.rule3,
                  },
                  order: x + 1,
                  isDefault: false,
                };

                // if we are editing an existing strategy, we need to add the id
                if (block.id) {
                  data.id = block.id;
                }

                // get the actual filterExpression string to send to the server (if there is one)
                if (block.filterInfo) {
                  const filterExpression = buildFilterExpression(
                    block.filterInfo.filters
                  );

                  data.filter.push({
                    workflowName: "Routing",
                    rules: [
                      {
                        ruleName: block.filterInfo.filterName,
                        errorMessage: "Error",
                        enabled: true,
                        expression: filterExpression,
                        successEvent: "true",
                      },
                    ],
                  });
                } else {
                  data.filter = null;
                  data.isRuleEngineEnabled = false;
                }

                for (let j = 0; j < block.groups.length; j++) {
                  let vendorGroup = {
                    vendorGroupId: block.groups[j].groupName,
                    orderingAlgorithm: block.groups[j].orderingAlgorithm,
                    tiebreakerOrderingAlgorithm:
                      block.groups[j].orderingAlgorithm === "Random"
                        ? null
                        : block.groups[j].tiebreakerOrderingAlgorithm === ""
                          ? null
                          : block.groups[j].tiebreakerOrderingAlgorithm,
                    vendors: []
                  };

                  // Handle dataset ordering algorithm
                  if (vendorGroup.orderingAlgorithm?.startsWith('dataset*')) {
                    const [, variableName] = vendorGroup.orderingAlgorithm.split('*');
                    vendorGroup.orderingAlgorithm = "DataSet";
                    vendorGroup.orderingAlgorithmOptions = {
                      variableName: variableName,
                      sortAscending: block.groups[j].orderingAlgorithmDirection === 'asc'
                    };
                  }

                  if (block.groups[j].filter && block.groups[j].filter.expression?.trim().length > 0) {
                    if (!vendorGroup.filter) {
                      vendorGroup.filter = [];
                    }
                    vendorGroup.filter.push(
                      {
                        workflowName: "Routing",
                        rules: [
                          {
                            ruleName: "Vendor group filter",
                            errorMessage: "Error",
                            enabled: true,
                            expression: block.groups[j].filter.expression,
                            successEvent: "true",
                          },
                        ],
                      }
                    );
                  }
                  data.vendorGroups.push(vendorGroup);

                  for (let k = 0; k < block.groups[j].vendors.length; k++) {
                    const vendorId = block.groups[j].vendors[k].vendorId;

                    // check to see if this vendor has already been added
                    const vendorIndex = data.vendorGroups[j].vendors.findIndex(
                      (vendor) => vendor.vendorId === vendorId
                    );

                    if (vendorIndex >= 0) {
                      // this vendor was previously added, no need to add the vendor again, just add their facility
                      data.vendorGroups[j].vendors[
                        vendorIndex
                      ].vendorFacilities.push({
                        id: block.groups[j].vendors[k].facilityId,
                        name: getFacilityNameById(
                          block.groups[j].vendors[k].facilityId
                        ),
                        rank: parseInt(block.groups[j].vendors[k].rank) || 0,
                      });
                    } else {
                      data.vendorGroups[j].vendors.push({
                        vendorId: block.groups[j].vendors[k].vendorId,
                        vendorName: getVendorNameById(
                          block.groups[j].vendors[k].vendorId
                        ),
                        vendorFacilities: [
                          {
                            id: block.groups[j].vendors[k].facilityId,
                            name: getFacilityNameById(
                              block.groups[j].vendors[k].facilityId
                            ),
                            rank: parseInt(block.groups[j].vendors[k].rank) || 0,
                          },
                        ],
                      });
                    }
                  }
                }

                routingStrategy.assignedBlocks.push(data);
              }

              if (editMode && strategyInfo) {
                dispatch(
                  updateRoutingStrategy({
                    routingStrategy,
                    cb: onRoutingStrategyUpdated,
                  })
                );
              } else {
                dispatch(
                  createRoutingStrategy({
                    routingStrategy,
                    cb: onRoutingStrategyCreated,
                  })
                );
              }
            }}
          >
            {({
              values,
              errors,
              setFieldValue,
              handleChange,
              handleSubmit,
              isSubmitting,
              submitCount,
              validateForm,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                {!createMode && (strategyOptionsButton(isSubmitting, handleSubmit, resetForm, true))}
                {createMode && <Card className={editMode ? "no-bot-padding" : ""}>
                  <div className="strategy-header-container">
                    <div className="strategy-name">
                      <ButtonIcon
                        icon={<ArrowLeft />}
                        onClick={() => navigate(`/admin/routing/${merchantId}`)}
                      />
                      {strategyInfo && !editMode
                        ? strategyInfo.routingStrategyName
                        : strategyInfo && editMode
                          ? "Edit Routing Strategy"
                          : errorMessage
                            ? "Routing Strategy"
                            : "Create Routing Strategy"}
                    </div>
                    {strategyOptionsButton(isSubmitting, handleSubmit, resetForm)}
                  </div>
                  {editMode && !errorMessage && (
                    <div className="strategy-name-input">
                      <Input
                        label="Strategy Name"
                        name="strategyName"
                        value={values.strategyName}
                        readonly={!editMode}
                        onChange={handleChange}
                        placeholder="Add a Strategy Name"
                        errorMessage={submitCount > 0 && errors.strategyName}
                      />
                    </div>
                  )}
                  {errorMessage && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                </Card>}
                {!errorMessage &&
                  values.blocks.map((block, index) => (
                    <Block
                      key={index}
                      blockIndex={index}
                      values={values}
                      editMode={editMode}
                      isNewStrategy={strategyInfo ? false : true}
                      data={block}
                      vendorsData={vendorsData}
                      categories={categories}
                      dataSets={dataSets}
                      errors={errors}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      isSubmitting={isSubmitting}
                      submitCount={submitCount}
                      setFieldValue={setFieldValue}
                      validateForm={validateForm}
                    />
                  ))}
              </form>
            )}
          </Formik>
        )}
    </div>
  );
};

export default StrategyOverview;
