import {
  getAllQueries,
  getAllQueriesSuccess,
  getAllQueriesFailure,
  runQueryById,
  runQueryByIdSuccess,
  runQueryByIdFailure,
  getAllExports,
  getAllExportsSuccess,
  getAllExportsFailure,
  getExportRunHistory,
  getExportRunHistorySuccess,
  getExportRunHistoryFailure,
  getAvailableExportColumns,
  getAvailableExportColumnsSuccess,
  getAvailableExportColumnsFailure,
  createExport,
  createExportSuccess,
  createExportFailure,
  runExport,
  runExportSuccess,
  runExportFailure,
  deleteExport,
  deleteExportSuccess,
  deleteExportFailure,
  getExportPreviewData,
  getExportPreviewDataSuccess,
  getExportPreviewDataFailure,
  clearExportPreviewData,
  getSpecificExport,
  getSpecificExportSuccess,
  getSpecificExportFailure,
  reset,
} from "app/store/actions/insight"
import { handleActions } from 'redux-actions';

const initialState = {
  // Dashboard state
  queries: {
    loading: false,
    data: null,
    error: null,
  },
  queryData: {
  },

  // Exports state
  exports: { // all exports
    loading: false,
    data: null,
    error: null,
  },
  exportRunHistory: { // run history for all exports
    data: {},
  },
  availableExportColumns: { // available columns for exports
    loading: false,
    data: null,
    error: null,
  },
  createExport: {
    loading: false,
    data: null,
    error: null,
  },
  runExport: {
    loading: false,
    data: null,
    error: null,
  },
  exportPreviewData: {
    loading: false,
    data: null,
    error: null,
  },
}

const insightReducer = handleActions(
  {
    [getAllQueries]: (state) => ({ 
      ...state,
      queries: {
        loading: true,
        data: null,
        error: null,
      },
    }),

    [getAllQueriesSuccess]: (state, data) => ({
      ...state,
      queries: {
        loading: false,
        data: data.payload?.queries,
        error: null,
      },
    }),

    [getAllQueriesFailure]: (state, data) => ({ 
      ...state,
      queries: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [runQueryById]: (state, data) => {
      const { query: { queryId }, dateRange } = data.payload;
    
      const id = `${queryId}-${dateRange || 'global'}`;
    
      // make a deep copy of the queryData in the state using JSON.parse and JSON.stringify
      const queryData = JSON.parse(JSON.stringify(state.queryData));
    
      // initialize the id object if it does not exist
      if (!queryData[id]) {
        queryData[id] = {};
      }
    
      // modify the deep-copied queryData
      queryData[id].loading = true;
      queryData[id].data = null;
      queryData[id].refreshedAt = null;
      queryData[id].error = null;
    
      return { 
        ...state,
        queryData,
      };
    },

    [runQueryByIdSuccess]: (state, data) => {
      const { queryId, dateRange, resp, refreshedAt } = data.payload;
    
      // make a deep copy of the queryData in the state using JSON.parse and JSON.stringify
      const queryData = JSON.parse(JSON.stringify(state.queryData));
      
      // create an id based on the queryId and dateRange
      const id = `${queryId}-${dateRange || 'global'}`;
    
      // modify the deep-copied queryData
      queryData[id].loading = false;
      queryData[id].data = resp.data;
      queryData[id].error = null;
      
      if (refreshedAt) {
        queryData[id].refreshedAt = refreshedAt;
      }
    
      return {
        ...state,
        queryData,
      };
    },
    

    [runQueryByIdFailure]: (state, data) => {
      const { queryId, dateRange, error } = data.payload;
    
      // make a deep copy of the queryData in the state using JSON.parse and JSON.stringify
      const queryData = JSON.parse(JSON.stringify(state.queryData));
      
      // create an id based on the queryId and dateRange
      const id = `${queryId}-${dateRange || 'global'}`;
      
      // modify the deep-copied queryData
      queryData[id].loading = false;
      queryData[id].data = null;
      queryData[id].refreshedAt = null;
      queryData[id].error = error;
      
      return {
        ...state,
        queryData,
      };
    },

    [getAllExports]: (state) => ({
      ...state,
      exports: {
        loading: true,
        data: null,
        error: null,
      },
      exportPreviewData: {
        loading: false,
        data: null,
        error: null,
      }
    }),

    [getAllExportsSuccess]: (state, data) => ({
      ...state,
      exports: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),
    
    [getAllExportsFailure]: (state, data) => ({
      ...state,
      exports: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),
    
    [getExportRunHistory]: (state, { payload: { exportId } }) => ({
      ...state,
      exportRunHistory: {
        data: {
          ...state.exportRunHistory.data,
          [exportId]: {
            ...state.exportRunHistory.data[exportId],
            loading: true,
            error: null,
          }
        }
      },
    }),

    [getExportRunHistorySuccess]: (state, { payload }) => ({
      ...state,
      exportRunHistory: {
        data: {
          ...state.exportRunHistory.data,
          [payload.exportId]: {
            loading: false,
            error: null,
            data: payload.data,
          }
        }
      },
    }),
    [getExportRunHistoryFailure]: (state, { payload: { exportId, error } }) => ({
      ...state,
      exportRunHistory: {
        data: {
          ...state.exportRunHistory.data,
          [exportId]: {
            ...state.exportRunHistory.data[exportId],
            loading: false,
            error,
          }
        }
      },
    }),

    [getAvailableExportColumns]: (state) => ({
      ...state,
      availableExportColumns: {
        ...state.availableExportColumns,
        loading: true,
        error: null,
      },
    }),
    
    [getAvailableExportColumnsSuccess]: (state, data) => ({
      ...state,
      availableExportColumns: {
        ...state.availableExportColumns,
        loading: false,
        data: data.payload,
        error: null,
      },
    }),
    
    [getAvailableExportColumnsFailure]: (state, data) => ({
      ...state,
      availableExportColumns: {
        ...state.availableExportColumns,
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [createExport]: (state) => ({
      ...state,
      createExport: {
        ...state.createExport,
        loading: true,
        data: null,
        error: null,
      },
    }),
    
    [createExportSuccess]: (state, data) => ({
      ...state,
      createExport: {
        ...state.createExport,
        loading: false,
        data: data.payload,
        error: null,
      },
    }),
    
    [createExportFailure]: (state, data) => ({
      ...state,
      createExport: {
        ...state.createExport,
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [runExport]: (state) => ({
      ...state,
      runExport: {
        ...state.runExport,
        loading: true,
        data: null,
        error: null,
      },
    }),
    
    [runExportSuccess]: (state, data) => ({
      ...state,
      runExport: {
        ...state.runExport,
        loading: false,
        data: data.payload,
        error: null,
      },
    }),
    
    [runExportFailure]: (state, data) => ({
      ...state,
      runExport: {
        ...state.runExport,
        loading: false,
        error: data.payload,
      },
    }),

    [deleteExport]: (state) => ({
      ...state,
      exports: {
        ...state.exports,
        loading: true,
      },
    }),

    [deleteExportSuccess]: (state, { payload: exportId }) => ({
      ...state,
      exports: {
        ...state.exports,
        loading: false,
        data: state.exports.data ? {
          ...state.exports.data,
          reports: state.exports.data.reports?.filter(export_ => export_.id !== exportId) || []
        } : null,
      },
    }),

    [deleteExportFailure]: (state) => ({
      ...state,
      exports: {
        ...state.exports,
        loading: false,
      },
    }),

    [getExportPreviewData]: (state) => ({
      ...state,
      exportPreviewData: {
        ...state.exportPreviewData,
        loading: true,
        error: null,
      },
    }),
    
    [getExportPreviewDataSuccess]: (state, data) => ({
      ...state,
      exportPreviewData: {
        ...state.exportPreviewData,
        loading: false,
        data: data.payload,
        error: null,
      },
    }),
    
    [getExportPreviewDataFailure]: (state, data) => ({
      ...state,
      exportPreviewData: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [clearExportPreviewData]: (state) => ({
      ...state,
      exportPreviewData: {
        loading: false,
        data: null,
        error: null,
      },
    }),

    [getSpecificExport]: (state) => ({
      ...state,
      exports: {
        loading: true,
        data: null,
        error: null,
      },
    }),
    [getSpecificExportSuccess]: (state, { payload }) => ({
      ...state,
      exports: {
        loading: false,
        data: {
          exports: [payload],
        },
        error: null,
      },
    }),
    [getSpecificExportFailure]: (state, { payload }) => ({
      ...state,
      exports: {
        loading: false,
        data: null,
        error: payload,
      },
    }),

    [reset]: () => ({
      ...initialState
    }),
  },
  initialState,
)

export default insightReducer;