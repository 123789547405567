export const setupChartResizeObserver = (ref, setWidth) => {
  if (!ref.current) return;

  const resizeObserver = new ResizeObserver(entries => {
    for (const entry of entries) {
      setWidth(entry.contentRect.width);
    }
  });

  resizeObserver.observe(ref.current);

  return () => {
    resizeObserver.disconnect();
  };
};

export const getTickAmount = (data, valueKey, containerWidth) => {
  // First find out how many unique values are in the dataset
  const uniqueValues = new Set(data.map(item => item[valueKey]));
  const uniqueValuesCount = uniqueValues.size;

  // If the number of unique values is less than 4, return the count size + 1
  if (uniqueValuesCount < 4) return uniqueValuesCount + 1;

  // If the container width is less than 500, return 5
  if (containerWidth < 500) return 5;

  // Otherwise, return 10
  return 10;
};
