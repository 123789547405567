import { put, takeEvery, call, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  createRoutingStrategy,
  createRoutingStrategySuccess,
  createRoutingStrategyFailure,
  updateRoutingStrategy,
  updateRoutingStrategySuccess,
  updateRoutingStrategyFailure,
  deleteRoutingStrategy,
  deleteRoutingStrategySuccess,
  deleteRoutingStrategyFailure,
  getStrategiesForMerchant,
  getStrategiesForMerchantSuccess,
  getStrategiesForMerchantFailure,
  getStrategyInfo,
  getStrategyInfoSuccess,
  getStrategyInfoFailure,
  activateStrategy,
  activateStrategySuccess,
  activateStrategyFailure,
  getRoutingStrategyDataSets,
  getRoutingStrategyDataSetsSuccess,
  getRoutingStrategyDataSetsFailure,
  createRoutingStrategyDataSet,
  createRoutingStrategyDataSetSuccess,
  createRoutingStrategyDataSetFailure,
  deleteRoutingStrategyDataSet,
  updateRoutingStrategyDataSet,
  getRoutingStrategyDataSet,
  getRoutingStrategyDataSetSuccess,
  getRoutingStrategyDataSetFailure,
  deleteRoutingStrategyDataSetSuccess,
  updateRoutingStrategyDataSetSuccess,
  updateRoutingStrategyDataSetFailure,
  deleteRoutingStrategyDataSetFailure,
  getSystemVariables,
  getSystemVariablesFailure,
  getSystemVariablesSuccess
} from "app/store/actions/routing";
import RoutingServices from 'app/services/routingServices';

function* fetchBlockAssignmentsForMerchant(action) {
  const merchantId = action.payload;
  try {
    const resp = yield call([RoutingServices, RoutingServices.getStrategiesForMerchant], merchantId);
    yield put(getStrategiesForMerchantSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getStrategiesForMerchantFailure(error));
  }
}

function* doCreateRoutingStrategy(action) {
  const { routingStrategy, cb } = action.payload;

  try {
    const resp = yield call([RoutingServices, RoutingServices.createStrategy], routingStrategy);
    yield put(createRoutingStrategySuccess(resp));
    toast.success("Routing Strategy Successfully Created", {
      theme: 'colored',
    });
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(createRoutingStrategyFailure());
    toast.error("Error Creating Routing Strategy", {
      theme: 'colored',
    })
  }
}

function* doUpdateRoutingStrategy(action) {
  const { routingStrategy, cb } = action.payload;

  try {
    const resp = yield call([RoutingServices, RoutingServices.updateStrategy], routingStrategy);
    yield put(updateRoutingStrategySuccess(resp));
    toast.success("Routing Strategy Successfully Updated", {
      theme: 'colored',
    });
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(updateRoutingStrategyFailure());
    toast.error("Error updating routing strategy", {
      theme: 'colored',
    })
  }
}

function* doDeleteStrategy(action) {
  const { merchantId, strategyId, cb } = action.payload;
  try {
    const resp = yield call([RoutingServices, RoutingServices.deleteStrategy], strategyId);
    yield put(deleteRoutingStrategySuccess(resp));

    const resp2 = yield call([RoutingServices, RoutingServices.getStrategiesForMerchant], merchantId);
    yield put(getStrategiesForMerchantSuccess(resp2));
    toast.success("Routing Strategy Successfully Deleted", {
      theme: 'colored',
    });
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(deleteRoutingStrategyFailure(error));
    toast.error("Deleting Strategy failed", {
      theme: 'colored',
    });
  }
}

function* doActivateStrategy(action) {
  const { strategyId, cb } = action.payload;
  try {
    yield call([RoutingServices, RoutingServices.activateStrategy], strategyId);
    yield put(activateStrategySuccess(strategyId));
    toast.success("Routing Strategy Successfully Activated", {
      theme: 'colored',
    });
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(activateStrategyFailure(error));
    toast.error("Activate Strategy failed", {
      theme: 'colored',
    });
  }
}

function* fetchStrategyInfo(action) {
  const { strategyId } = action.payload;

  try {
    const resp = yield call([RoutingServices, RoutingServices.getStrategy], strategyId);
    yield put(getStrategyInfoSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getStrategyInfoFailure(error));
  }
}

function* handleGetRoutingStrategyDataSets(action) {
  const { strategyId } = action.payload;

  try {
    const resp = yield call([RoutingServices, RoutingServices.getDataSets], strategyId);
    yield put(getRoutingStrategyDataSetsSuccess(resp));
  } catch (error) {
    yield put(getRoutingStrategyDataSetsFailure(error));
    toast.error(error, {
      theme: 'colored',
    });
  }
}

function createDataSetForUpload(csvItems, tableItems, dataSetName) {
  const dataSet = {
    name: dataSetName,
    variables: tableItems.map(item => ({
      name: item["column-header"],
      description: "",
      mapTo: item["map-to"],
      type: item["source-type"],
      useFor: item["use-for"].split(',').map(i => i.trim()),
      defaultValue: item["default-value"]
    })),
    values: csvItems
  };

  return dataSet;
}

function* handleCreateRoutingStrategyDataSet(action) {
  const { dataSetName, tableItems, csvItems, strategyId, cb } = action.payload;

  const dataSet = createDataSetForUpload(csvItems, tableItems, dataSetName);

  try {
    yield call([RoutingServices, RoutingServices.createDataSet], strategyId, dataSet);
    yield put(createRoutingStrategyDataSetSuccess());
    toast.success("Data Set Successfully Created", {
      theme: 'colored',
    });
    if (cb) cb();
  } catch (error) {
    yield put(createRoutingStrategyDataSetFailure(error));
    toast.error(error, {
      theme: 'colored',
    });
  }
}

function* handleDeleteRoutingStrategyDataSet(action) {
  const { strategyId, dataSetId, cb } = action.payload;

  try {
    yield call([RoutingServices, RoutingServices.deleteDataSet], strategyId, dataSetId);
    yield put(deleteRoutingStrategyDataSetSuccess());
    toast.success("Data Set Successfully Deleted", {
      theme: 'colored',
    });
    if (cb) cb();
  } catch (error) {
    yield put(deleteRoutingStrategyDataSetFailure(error));
    toast.error(error, {
      theme: 'colored',
    });
  }
}

function* handleUpdateRoutingStrategyDataSet(action) {
  const { strategyId, dataSetId, dataSetName, tableItems, csvItems, cb } = action.payload;

  const dataSet = createDataSetForUpload(csvItems, tableItems, dataSetName);

  try {
    yield call([RoutingServices, RoutingServices.updateDataSet], strategyId, dataSetId, dataSet);
    yield put(updateRoutingStrategyDataSetSuccess());

    toast.success("Data Set Successfully Updated", {
      theme: 'colored',
    });
    if (cb) cb();
  } catch (error) {
    yield put(updateRoutingStrategyDataSetFailure(error))
    toast.error(error, {
      theme: 'colored',
    });
  }
}

function* handleGetRoutingStrategyDataSet(action) {
  const { strategyId, dataSetId, cb } = action.payload;

  try {
    const resp = yield call([RoutingServices, RoutingServices.getDataSet], strategyId, dataSetId);
    yield put(getRoutingStrategyDataSetSuccess(resp));
    if (cb) cb();
  } catch (error) {
    yield put(getRoutingStrategyDataSetFailure(error));
    toast.error(error, {
      theme: 'colored',
    });
  }
}

function* handleGetSystemVariables(action) {
  const { cb } = action.payload;
  try {
    const resp = yield call([RoutingServices, RoutingServices.getSystemVariables]);
    yield put(getSystemVariablesSuccess(resp));
    if (cb) cb();
  } catch (error) {
    yield put(getSystemVariablesFailure(error));
    toast.error(error, {
      theme: 'colored',
    });
  }
}

function* watchData() {
  yield takeEvery(getStrategiesForMerchant().type, fetchBlockAssignmentsForMerchant);
  yield takeEvery(createRoutingStrategy().type, doCreateRoutingStrategy);
  yield takeEvery(updateRoutingStrategy().type, doUpdateRoutingStrategy);
  yield takeEvery(deleteRoutingStrategy().type, doDeleteStrategy);
  yield takeEvery(activateStrategy().type, doActivateStrategy);
  yield takeEvery(getStrategyInfo().type, fetchStrategyInfo);
  yield takeLatest(getRoutingStrategyDataSets().type, handleGetRoutingStrategyDataSets);
  yield takeLatest(createRoutingStrategyDataSet().type, handleCreateRoutingStrategyDataSet);
  yield takeLatest(deleteRoutingStrategyDataSet().type, handleDeleteRoutingStrategyDataSet);
  yield takeLatest(updateRoutingStrategyDataSet().type, handleUpdateRoutingStrategyDataSet);
  yield takeLatest(getRoutingStrategyDataSet().type, handleGetRoutingStrategyDataSet);
  yield takeLatest(getSystemVariables().type, handleGetSystemVariables);
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}