import {
  createNote,
  createNoteSuccess,
  createNoteFailure,
  getNotes,
  getNotesSuccess,
  getNotesFailure,
  deleteNoteSuccess,
  deleteNoteFailure,
  toggleEditNote,
  editNoteSuccess,
  editNoteCancel,
  updateNoteSuccess,
  updateNoteFailure,
  reset,
} from "app/store/actions/note";
import { handleActions } from 'redux-actions';

const initialState = {
  notes: {
    loading: false,
    data: null,
    error: null,
  },
  note: {
    loading: false,
    data: null,
    error: null,
    sent_at: null,
  },
}

const noteReducer = handleActions(
  {
    [getNotes]: (state) => ({
      ...state,
      notes: {
        ...state.notes,
        loading: true,
        error: null,
      }
    }),

    [getNotesSuccess]: (state, { payload }) => ({
      ...state,
      notes: {
        loading: false,
        data: payload,
        error: null,
      },
    }),

    [getNotesFailure]: (state, { payload }) => ({
      ...state,
      notes: {
        loading: false,
        data: null,
        error: payload,
      },
    }),

    [createNote]: (state) => ({
      ...state,
      note: {
        loading: true,
        data: null,
        error: null,
        sent_at: null,
      },
    }),

    [createNoteSuccess]: (state, { payload }) => ({
      ...state,
      note: {
        loading: false,
        data: payload,
        error: null,
        sent_at: new Date().getTime(),
      },
    }),

    [createNoteFailure]: (state, { payload }) => ({
      ...state,
      note: {
        loading: false,
        data: null,
        error: payload,
        sent_at: null,
      },
    }),

    [deleteNoteSuccess]: (state, { payload }) => ({
      ...state,
      notes: {
        ...state.notes,
        data: state.notes.data.filter(note => note.id !== payload),
      },
    }),

    [deleteNoteFailure]: (state, { payload }) => ({
      ...state,
      notes: {
        ...state.notes,
        error: payload,
      }
    }),

    [toggleEditNote]: (state, { payload }) => ({
      ...state,
      notes: {
        ...state.notes,
        data: state.notes.data.map(note =>
          note.id === payload.id ? { ...note, isBeingEdited: !note.isBeingEdited } : note
        ),
      },
    }),

    [editNoteSuccess]: (state, { payload }) => ({
      ...state,
      notes: {
        ...state.notes,
        data: state.notes.data.map(note =>
          note.id === payload.id ? { ...note, ...payload, isBeingEdited: false } : note
        ),
      },
    }),

    [editNoteCancel]: (state, { payload }) => ({
      ...state,
      notes: {
        ...state.notes,
        data: state.notes.data.map(note =>
          note.id === payload.id ? { ...note, isBeingEdited: false } : note
        ),
      },
    }),

    [updateNoteSuccess]: (state, { payload }) => ({
      ...state,
      notes: {
        ...state.notes,
        data: state.notes.data.map(note =>
          note.id === payload.id ? { ...note, ...payload, isBeingEdited: false } : note
        ),
      },
    }),

    [updateNoteFailure]: (state, { payload }) => ({
      ...state,
      notes: {
        ...state.notes,
        error: payload,
      }
    }),

    [reset]: () => ({
      ...initialState
    })
  },
  initialState,
)

export default noteReducer;
