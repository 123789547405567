import { string } from 'yup';
import { emailRegex } from 'app/utils';

export const MerchantValidation = {
  address1: isRequired => {
    const validation = string()
      .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 1') // address
      .matches(/[\x20-\x7F]+/, 'Enter a valid Address 1') // onlyEnglish
      .max(35, 'Please limit to 35 characters')
    return isRequired ? validation.required('Address 1 is required') : validation;
  },
  address2: isRequired => {
    const validation = string().when(['address1', 'countryCode'], (addr1, cc) => {
      if (cc !== 'US') {
        if (addr1) {
          return string()
            .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
            .matches(/[\x20-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
            .max(
              35 - addr1.length,
              'Please limit to 35 characters for Address 1 + Address 2'
            );
        } else {
          return string()
            .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
            .matches(/[\x20-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
            .max(35, 'Please limit to 35 characters for Address 1 + Address 2');
        }
      } else {
        return string()
          .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
          .matches(/[\x20-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
          .max(35, 'Please limit to 35 characters');
      }
    })
    return isRequired ? validation.required('Address 2 is required') : validation;
  },
  city: isRequired => {
    const validation = string()
      .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid City') // address
      .matches(/[\x20-\x7F]+/, 'Enter a valid City') // onlyEnglish
    return isRequired ? validation.required('Enter a valid City') : validation;
  },
  state: isRequired => {
    return string()
      .when('countryCode', {
        is: (countryCode) => ['CA', 'US'].includes(countryCode),
        then: () => isRequired ? string().required('Enter a valid State/Province') : string(),
        otherwise: () => string()
      })
      .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid State/Province')
      .matches(/[\x20-\x7F]+/, 'Enter a valid State/Province')
  },
  postalCode: isRequired => {
    return string().when('countryCode', {
      is: countryCode => countryCode === 'US',
      then: () => isRequired ? string()
        .matches(/^[a-zA-Z\d-]*$/, 'Enter a valid zip code')
        .required('Enter a valid zip code')
        : string().matches(/^[a-zA-Z\d-]*$/, 'Enter a valid zip code')
      ,
      otherwise: () => string().when('countryCode', {
        is: countryCode => countryCode === 'CA',
        then: () => isRequired ? string()
          .matches(/^[a-zA-Z\d- ]*$/, 'Enter a valid postal code')
          .required('Enter a valid postal code')
          : string().matches(/^[a-zA-Z\d- ]*$/, 'Enter a valid postal code')
        ,
        otherwise: () => string().matches(/^[a-zA-Z\d- ]*$/, 'Enter a valid postal code')
      })
    })
  },
  countryCode: isRequired => {
    return isRequired ? string().required('Enter a valid Country') : string();
  },
  email: isRequired => {
    const validation = string()
      .matches(
        emailRegex,
        'Enter a valid Email'
      )
    return isRequired ? validation.required('Enter a valid Email') : validation;
  }
}