// Shared color schemes for all status types
export const statusColorSchemes = {
  success: 'success-status',
  warning: 'warning-status',
  error: 'error-status',
  info: 'info-status',
  neutral: 'neutral-status'
};

// Helper function to create a status object
export const createStatus = (type, displayName, description) => ({
  type,
  displayName,
  description
});
