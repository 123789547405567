import { createSelector } from 'reselect';

const noteSelector = state => state.note;

export const noteLoadingSelector = createSelector([noteSelector], noteStore => noteStore.note.loading);
export const noteDataSelector = createSelector([noteSelector], noteStore => noteStore.note.data);
export const noteErrorsSelector = createSelector([noteSelector], noteStore => noteStore.note.error);

export const notesDataSelector = createSelector([noteSelector], noteStore => noteStore.notes.data);
export const notesLoadingSelector = createSelector([noteSelector], noteStore => noteStore.notes.loading);
export const notesErrorsSelector = createSelector([noteSelector], noteStore => noteStore.notes.error);
export const noteCreationSuccessSelector = createSelector([noteSelector], noteStore => noteStore.note.sent_at);
