import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonIcon, Checkbox } from 'app/components';
import { ChevronRight, ChevronDown } from 'react-bootstrap-icons';
import './index.scss';

const formatColumnName = (name) => {
  // First, capitalize the first letter
  let formatted = name.charAt(0).toUpperCase() + name.slice(1);
  // Then insert spaces before capital letters in the middle of the word
  formatted = formatted.replace(/([A-Z])/g, ' $1').trim();
  return formatted;
};

const TreeNode = ({ node, level, selectedColumns, onSelect, searchActive }) => {
  const [isExpanded, setIsExpanded] = useState(searchActive);
  const hasChildren = node.children && Object.keys(node.children).length > 0;
  const hasColumns = node.columns && node.columns.length > 0;
  const allColumns = [];

  // Update expansion state when search changes
  useEffect(() => {
    setIsExpanded(searchActive);
  }, [searchActive]);

  // Collect all columns from this node and its children
  const collectColumns = (node) => {
    if (node.columns) {
      allColumns.push(...node.columns.map(col => col.fullName));
    }
    if (node.children) {
      Object.values(node.children).forEach(child => collectColumns(child));
    }
  };
  collectColumns(node);

  // Calculate how many columns are selected from this section
  const selectedColumnsCount = allColumns.filter(col => selectedColumns.includes(col)).length;

  // Format the node label to include the count of selected columns
  const formattedNodeLabel = () => {
    const baseLabel = formatColumnName(node.name);
    // Only show count for main sections (level 0) or sections with children/columns
    if ((level === 0 || hasChildren || hasColumns) && selectedColumnsCount > 0) {
      return `${baseLabel} (${selectedColumnsCount})`;
    }
    return baseLabel;
  };

  return (
    <div className="tree-node" style={{ marginLeft: `${level * 20}px` }}>
      <div className="node-content">
        {(hasChildren || node.isExpandable) && (
          <ButtonIcon
            icon={isExpanded ? <ChevronDown /> : <ChevronRight />}
            onClick={() => setIsExpanded(!isExpanded)}
            className="expand-button"
          />
        )}
        <span className="node-label">{formattedNodeLabel()}</span>
      </div>
      {isExpanded && (
        <div className="node-children">
          {hasColumns && node.columns.map(column => (
            <div key={column.fullName} className="tree-node" style={{ marginLeft: '20px' }}>
              <Checkbox
                size="small"
                checked={selectedColumns.includes(column.fullName)}
                onChange={() => onSelect([column.fullName], !selectedColumns.includes(column.fullName))}
                label={formatColumnName(column.name)}
              />
            </div>
          ))}
          {hasChildren && Object.entries(node.children).map(([key, childNode]) => (
            <TreeNode
              key={key}
              node={childNode}
              level={level + 1}
              selectedColumns={selectedColumns}
              onSelect={onSelect}
              searchActive={searchActive}
            />
          ))}
        </div>
      )}
    </div>
  );
};

TreeNode.propTypes = {
  node: PropTypes.shape({
    name: PropTypes.string.isRequired,
    children: PropTypes.object,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        fullName: PropTypes.string.isRequired,
        allowForUserTypes: PropTypes.arrayOf(PropTypes.string)
      })
    ),
    isExpandable: PropTypes.bool
  }).isRequired,
  level: PropTypes.number.isRequired,
  selectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
  searchActive: PropTypes.bool.isRequired
};

export default TreeNode;
