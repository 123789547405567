import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PageNotFound } from 'app/components';
import ExportsView from './ExportsView';
import ExportDetails from './ExportDetails';

function Exports() {
  return (
    <Routes>
      <Route exact path="/" element={<ExportsView />} />
      <Route path="/activity-log" element={<ExportsView />} />
      <Route path="/create-export/*" element={<ExportDetails />} />
      <Route path="/:exportId/*" element={<ExportDetails />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default Exports;