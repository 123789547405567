import { createStatus } from './statusColorSchemes';

const ProductStatusDetails = {
  'available': createStatus('success', 'Available', ''),
  'discontinued product': createStatus('error', 'Discontinued Product', ''),
  'not set': createStatus('error', 'Not Set', ''),
  'outofstock': createStatus('error', 'Out of Stock', ''),
  'archived': createStatus('error', 'Archived', '')
};

export default ProductStatusDetails;
