import { createStatus } from './statusColorSchemes';

const ShipmentStatusDetails = {
  'open': createStatus('success', 'Open', 'Order and items were created and ready for next steps.'),
  'inproduction': createStatus('success', 'In Production', ''),
  'readyforfulfillment': createStatus('success', 'Ready For Fulfillment', ''),
  'imageerror': createStatus('error', 'Image Error', 'Image Error is raised when the image associated with an item fails validation (similar to Image Issue).'),
  'fulfillmenterror': createStatus('error', 'Fulfillment Error', ''),
  'canceled': createStatus('error', 'Canceled', 'Order, shipment, or item was canceled.'),
  'fulfilled': createStatus('info', 'Fulfilled', 'Shipped = Fulfilled; All shipments in the order are shipped; Shipment has been shipped by the vendor; Item has been shipped by the vendor.')
};

export default ShipmentStatusDetails;
