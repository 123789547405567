import { createStatus } from './statusColorSchemes';

const OrderStatusDetails = {
  'open': createStatus('success', 'Open', 
    'Order and items were created and ready for next steps.'),
  
  'hold': createStatus('warning', 'Customer Care Hold',
    'Generic Hold state that pauses the workflow for the entity.'),
  
  'error': createStatus('error', 'Error', ''),
  
  'addresserror': createStatus('error', 'Address Error',
    'Address Error is raised when the shipping address in the order fails validation (similar to Address Issue).'),
  
  'imageerror': createStatus('error', 'Image Error',
    'Image Error is raised when the image associated with an item fails validation (similar to Image Issue).'),
  
  'fulfillmenterror': createStatus('error', 'Fulfillment Error',
    'Fulfillment Error is raised when a shipment submitted to a vendor fails (similar to VAI).'),
  
  'pending': createStatus('warning', 'Pending',
    'Configurable processing hold for orders received from a merchant or channel.'),
  
  'customercarehold': createStatus('warning', 'Customer Care Hold',
    'The order is held for review by a customer care agent. For e.g., If routing ran successfully, but an assignment could not be found, the order should be placed in a Customer Care Hold for further manual action.'),
  
  'reviewhold': createStatus('warning', 'Review Hold',
    'Configurable processing hold to prevent the order from being fulfilled until an action is complete (similar to Needs Manual Approval).'),
  
  'readyforfulfillment': createStatus('success', 'Ready For Fulfillment',
    'All shipments in the order are ready for submission to the vendor for fulfillment.'),
  
  'inproduction': createStatus('success', 'In Production',
    'All shipments in the order are submitted to the vendor.'),
  
  'partial': createStatus('info', 'Partial',
    'Shipments within an order are in multiple different states.'),
  
  'canceled': createStatus('error', 'Canceled',
    'Order, shipment, or item was canceled.'),
  
  'fulfilled': createStatus('info', 'Fulfilled',
    'Shipped = Fulfilled; All shipments in the order are shipped; Shipment has been shipped by the vendor; Item has been shipped by the vendor.')
};

export default OrderStatusDetails;