import React, { useRef, useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonIcon, Card, Button, Table, TableHeader, Input, Dropdown, MultiSelect, LoadingAnimation } from "app/components";
import { ArrowLeft, Upload } from "react-bootstrap-icons";
import { isStringOrNumber, parseCSV } from "app/utils";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import "./index.scss";
import { createRoutingStrategyDataSet, getRoutingStrategyDataSet, getSystemVariables, updateRoutingStrategyDataSet } from "app/store/actions/routing";
import { toast } from "react-toastify";
import { getDataSetLoadingSelector, getDataSetSelector, getDataSetsLoadingSelector } from "app/store/selectors/routing";
import { convertDataSetToTableItems, convertDataSetValuesToCSVValues } from "app/pages/Routing/utils";

export const dataSetCSVExampleUrl = "https://printmeeappassets.blob.core.windows.net/ordermesh/Data%20Set%20template.csv";
const maxFileSize = 10;
const VAR_FACILITY_ID = "facilityId";
const MANDATORY_VARIABLES = [VAR_FACILITY_ID];
const DATA_SET = {
  MAP_TO: "map-to",
  COLUMN_HEADER: "column-header",
  SAMPLE_ROW: "sample-row",
  SOURCE_TYPE: "source-type",
  USE_FOR: "use-for",
  DEFAULT_VALUE: "default-value"
}

const UploadDataSetCSV = ({ isEdit }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { strategyId, dataSetId } = useParams();

  const isLoading = useSelector(getDataSetsLoadingSelector);
  const isLoadingSingleDataSet = useSelector(getDataSetLoadingSelector);
  const dataSetWithValues = useSelector(getDataSetSelector);

  const fileInputRef = useRef(null);
  const [csvItems, setCSVItems] = useState([]);
  const [tableItems, setTableItems] = useState([]);
  const [dataSetName, setDataSetName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [mapToOptions] = useState([{ label: "Custom Variable", value: "customVariable" },{ label: "Facility Id", value: "facilityId" }]);

  useEffect(() => {
    if (isEdit) {
      dispatch(getRoutingStrategyDataSet({ strategyId, dataSetId }));
    }
  }, [isEdit, dispatch, dataSetId]);

  useEffect(() => {
    if (isEdit) {
      if (dataSetWithValues) {
        setDataSetName(dataSetWithValues?.name);
        const items = convertDataSetValuesToCSVValues(dataSetWithValues?.values);
        setCSVItems(items);

        const tItems = convertDataSetToTableItems(dataSetWithValues);
        setTableItems(tItems);
      } else {
        setDataSetName("");
        setCSVItems([]);
        setTableItems([]);
      }
    }
  }, [dataSetWithValues, dataSetId]);



  const handleDownloadTemplate = useCallback(() => {
    window.open(dataSetCSVExampleUrl, '_blank');
  }, []);

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleFile(file);
  };

  const createTableItems = (items) => {
    if (!items?.length) return;

    // create table rows format...
    const tItems = Object.keys(items?.[0]).map((key) => {
      return {
        [DATA_SET.COLUMN_HEADER]: key,
        [DATA_SET.SAMPLE_ROW]: items?.[0][key],
        [DATA_SET.MAP_TO]: "",
        [DATA_SET.SOURCE_TYPE]: isStringOrNumber(items?.[0][key]),
        [DATA_SET.USE_FOR]: "",
        [DATA_SET.DEFAULT_VALUE]: ""
      }
    });
    setTableItems(tItems || []);
  }

  const validateColumnHeaders = (items) => {
    if (!items?.length) return true;
    const columnHeaderRegex = /^[a-zA-Z][a-zA-Z0-9_]*$/;
    const invalidHeaders = Object.keys(items[0]).filter(header => !columnHeaderRegex.test(header));
    if (invalidHeaders.length > 0) {
      toast.error(`Invalid column headers found: "${invalidHeaders.join('", "')}" - Headers must start with a letter and can only contain alphanumeric characters and underscores.`, { theme: 'colored' });
      return false;
    }
    return true;
  }

  const validateCSVContent = (items) => {
    const specialCharRegex = /[^a-zA-Z0-9,._\s]/;
    for (const item of items) {
      for (const key in item) {
        if (specialCharRegex.test(item[key])) {
          toast.error(`Special characters found in CSV data: "${item[key]}". Only alphanumeric characters, commas, dots, underscores, and spaces are allowed.`, { theme: 'colored' });
          return false;
        }
      }
    }
    return true;
  }

  const handleFile = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const items = parseCSV(text);
        
        if (!validateColumnHeaders(items) || !validateCSVContent(items)) {
          // Reset the file input so the same file can be uploaded again
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          return;
        }
        
        setCSVItems(items || []);
        createTableItems(items);

        dispatch(getSystemVariables({}));
      }
      reader.readAsText(file);
    }
  }

  const handleSaveDataset = () => {
    if (!dataSetName) {
      setErrorMessage("Data Set Name is required");
      return;
    }

    // validate that data set has mandatory variables applied...
    const missingMandatory = MANDATORY_VARIABLES.filter(v => !tableItems.find(item => item[DATA_SET.MAP_TO]?.toLowerCase() === v.toLowerCase()));
    if (missingMandatory.length) {
      toast.error(`Mandatory variables are required: ${MANDATORY_VARIABLES.join(',')}`, { theme: 'colored' });
      return;
    }

    // validate if some of variables are duplicated, except for customVariable...
    const duplicatedVariables = tableItems.filter((item, index) => {
      if (item[DATA_SET.MAP_TO]?.toLowerCase() === "customvariable") {
        return false;
      }
      return tableItems.findIndex(tItem => tItem[DATA_SET.MAP_TO]?.toLowerCase() === item[DATA_SET.MAP_TO]?.toLowerCase()) !== index;
    });
    if (duplicatedVariables.length) {
      toast.error("Duplicated variables are not allowed", { theme: 'colored' });
      return;
    }

    // validate that each variable has mapTo...
    const missingMapTo = tableItems.filter(item => !item[DATA_SET.MAP_TO]);
    if (missingMapTo.length) {
      toast.error("Map To is required for all variables", { theme: 'colored' });
      return;
    }

    // validate that each variable has useFor...
    const missingUseFor = tableItems.filter(item => !item[DATA_SET.USE_FOR]);
    if (missingUseFor.length) {
      toast.error("Use For is required for all variables", { theme: 'colored' });
      return;
    }

    if (isEdit) {
      dispatch(updateRoutingStrategyDataSet({
        dataSetName,
        tableItems,
        csvItems,
        strategyId,
        dataSetId,
        cb: () => navigate(-1)
      }));
    } else {
      dispatch(createRoutingStrategyDataSet({
        dataSetName,
        tableItems,
        csvItems,
        strategyId,
        cb: () => navigate(-1)
      }));
    }
  };

  const sourceTypeOptions = [
    { label: "String", value: "string" },
    { label: "Number", value: "number" }
  ];

  const useForOptions = [
    { label: "Sort", value: "sort" },
    { label: "Filter", value: "filter" }
  ];

  const onChangeDropDown = (e, item, key) => {
    const newTableItems = tableItems.map((tItem) => {
      if (tItem[DATA_SET.COLUMN_HEADER] === item[DATA_SET.COLUMN_HEADER]) {
        tItem[key] = e.target.value;
      }
      return tItem;
    });
    setTableItems(newTableItems);
  }

  const onChangeMultiSelect = (e, item, key) => {
    const newTableItems = tableItems.map((tItem) => {
      if (tItem[DATA_SET.COLUMN_HEADER] === item[DATA_SET.COLUMN_HEADER]) {
        tItem[key] = e.map(v => v.value).join(",");
      }
      return tItem;
    });
    setTableItems(newTableItems);
  }

  const onChangeInput = (e, item, key) => {
    const newTableItems = tableItems.map((tItem) => {
      if (tItem[DATA_SET.COLUMN_HEADER] === item[DATA_SET.COLUMN_HEADER]) {
        tItem[key] = e.target.value;
      }
      return tItem;
    });
    setTableItems(newTableItems);
  }

  const isFieldDisabled = (key, item) =>
    (key == DATA_SET.SOURCE_TYPE || key == DATA_SET.USE_FOR || key == DATA_SET.DEFAULT_VALUE) &&
    item[DATA_SET.MAP_TO]?.toLowerCase() === VAR_FACILITY_ID.toLowerCase();

  ////////////////////////////
  // UI Components
  ////////////////////////////

  const uploadContainer = () => (
    <div className="dataset-content-holder d-flex justify-content-center align-items-center">
      <div
        className="upload-container"
        onClick={handleDivClick}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept=".csv"
          onChange={handleFileChange}
        />
        <Upload className="upload-icon"></Upload>
        <div className="drag-text">Drag and Drop your File to Upload</div>
        <div className="gray-text">File supported:CSV</div>
        <Button label="Choose a file" onClick={() => 0}></Button>
        <div className="gray-text">Maximum file size {maxFileSize} MB</div>
      </div>
    </div>
  );

  const createDropDown = (options, item, key) => {
    if (key === DATA_SET.MAP_TO && item[DATA_SET.MAP_TO] === VAR_FACILITY_ID) {
      item[DATA_SET.SOURCE_TYPE] = "string";
      item[DATA_SET.USE_FOR] = "filter";
      item[DATA_SET.DEFAULT_VALUE] = "";
    }

    const value = item ? item[key] : "";

    return (
      <Dropdown
        name="data-set-dropdown"
        showErrorMessages={false}
        options={options}
        value={value}
        disabled={isFieldDisabled(key, item)}
        onChange={e => onChangeDropDown(e, item, key)}
      />
    )
  }

  const createMultiSelect = (options, item, key) => {
    const value = options.filter(option => item[key].split(",").includes(option.value));

    if (item[DATA_SET.SOURCE_TYPE] === "string") {
      options = options.filter(option => option.value === "filter");
    }

    return (
      <MultiSelect
        label=""
        options={options}
        showErrorMessage={false}
        value={value}
        disabled={isFieldDisabled(key, item)}
        onChange={e => onChangeMultiSelect(e, item, key)}
      />
    )
  }

  const createInput = (item, key) => {
    return (
      <Input
        name={key}
        value={item[key]}
        onChange={e => onChangeInput(e, item, key)}
        showErrorMessages={false}
        disabled={isFieldDisabled(key, item)}
      />
    )
  }

  const dataSetTable = () => {
    return (
      <div className="dataset-table">
        <div className="dataset-title">
          <b>Data Set Name</b>
          <Input
            name="data-set-name"
            value={dataSetName}
            onChange={(e) => {
              setDataSetName(e.target.value)
              setErrorMessage("")
            }}
            errorMessage={errorMessage}
          />
        </div>
        <Table
          className="mt-4"
          size="medium">
          <TableHeader
            options={[
              { id: DATA_SET.COLUMN_HEADER, label: "Variable Name", orderable: false },
              { id: DATA_SET.SAMPLE_ROW, label: "Sample Row", orderable: false },
              { id: DATA_SET.MAP_TO, label: "Map To", orderable: false },
              { id: DATA_SET.SOURCE_TYPE, label: "Source Type", orderable: false },
              { id: DATA_SET.USE_FOR, label: "Use For", orderable: false },
              { id: DATA_SET.DEFAULT_VALUE, label: "Default Value", orderable: false }
            ]}
          />
          <tbody className="table-body">
            {tableItems && tableItems.map((item, index) => (
              <tr className="data-set-row" key={index}>
                <td>{item[DATA_SET.COLUMN_HEADER]}</td>
                <td>{item[DATA_SET.SAMPLE_ROW]}</td>
                <td className={DATA_SET.MAP_TO}>{createDropDown(mapToOptions, item, DATA_SET.MAP_TO)}</td>
                <td className={DATA_SET.SOURCE_TYPE}>{createDropDown(sourceTypeOptions, item, DATA_SET.SOURCE_TYPE)}</td>
                <td className={DATA_SET.USE_FOR}>{createMultiSelect(useForOptions, item, DATA_SET.USE_FOR)}</td>
                <td className={DATA_SET.DEFAULT_VALUE}>{createInput(item, DATA_SET.DEFAULT_VALUE)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <div className="upload-dataset-csv">
      {(isLoading || isLoadingSingleDataSet) && <LoadingAnimation />}
      <Card>
        <div className="upload-dataset-csv-header">
          <div className="upload-dataset-csv-title">
            <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate(-1)} />
            {isEdit ? dataSetWithValues?.name : "Upload Data Set"}
          </div>
          {!tableItems.length ?
            <Button
              variant="primary"
              size="medium"
              label="Download Template CSV"
              onClick={handleDownloadTemplate}
            /> :
            <Button
              variant="primary"
              size="medium"
              label="Save Data Set"
              onClick={handleSaveDataset}
            />}
        </div>
        <div>
        </div>
        {(!tableItems.length && !isLoadingSingleDataSet) && uploadContainer()}
        {tableItems.length > 0 && dataSetTable()}
      </Card>
    </div>
  );
}

UploadDataSetCSV.propTypes = {
  isEdit: PropTypes.bool
};

export default UploadDataSetCSV;
