import React, { useEffect } from 'react';
import { ClientManager } from 'app/components'
import { useSelector, useDispatch } from 'react-redux';
import { getMerchantDetails } from 'app/store/actions/merchant';
import { merchantDetailsSelector } from 'app/store/selectors/merchant';

const AuthView = ({ merchantId }) => {
  const dispatch = useDispatch();
  const merchantDetails = useSelector(merchantDetailsSelector);
  useEffect(() => {
    if (merchantId && (!merchantDetails || merchantDetails.id !== merchantId)) {
      dispatch(getMerchantDetails(merchantId));
    }
  }, [merchantId, merchantDetails?.id, dispatch]);

  return (
    <div className="merchant-auth-view">
      <ClientManager entityType="merchant" entityId={merchantId}></ClientManager>
    </div>
  )
}

export default AuthView;