import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  reasonsSelector,
  reasonsLoadingSelector,
  reasonsErrorsSelector,
  reasonLoadingSelector
} from 'app/store/selectors/merchant';
import { getReasons, updateReason } from 'app/store/actions/merchant';
import { Card, LoadingAnimation, MessageBar, Table, TableHeader, ButtonIcon, Button, Dropdown } from 'app/components';
import { Eye, EyeSlash, ThreeDotsVertical } from 'react-bootstrap-icons';
import CreateEditReasonModal from '../CreateEditReasonModal';
import DeleteReasonModal from '../DeleteReasonModal';
import './index.scss';

const ReasonManagement = ({ merchantId, reasonType, title, reasonOptions }) => {
  const dispatch = useDispatch();

  const reasons = useSelector(reasonsSelector(reasonType));
  const reasonsLoading = useSelector(reasonsLoadingSelector(reasonType));
  const reasonsErrors = useSelector(reasonsErrorsSelector(reasonType));
  const reasonLoading = useSelector(reasonLoadingSelector);

  const [showCreateEditModal, setShowCreateEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);
  const [editingRankId, setEditingRankId] = useState(null);
  const [rankValue, setRankValue] = useState('');
  const [rankOptions, setRankOptions] = useState([]);
  const dropdownRef = React.useRef(null);

  useEffect(() => {
    if (merchantId) {
      dispatch(getReasons({ merchantId, reasonType }));
    }
  }, [merchantId, reasonType, dispatch]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        cancelEditingRank();
      }
    }

    if (editingRankId !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editingRankId]);

  const handleOpenCreateEditModal = (reason = null) => {
    setSelectedReason(reason);
    setShowCreateEditModal(true);
  };

  const handleCloseCreateEditModal = () => {
    setShowCreateEditModal(false);
    setSelectedReason(null);
  };

  const handleOpenDeleteModal = (reason) => {
    setSelectedReason(reason);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedReason(null);
  };

  const handleToggleHidden = (reason) => {
    const updatedReasonData = {
      ...reason,
      isHidden: !reason.isHidden
    };

    dispatch(updateReason({
      merchantId,
      reasonType,
      reasonId: reason.id,
      reasonData: updatedReasonData
    }));
  };

  const startEditingRank = (reason) => {
    setEditingRankId(reason.id);
    const rankVal = reason.rank !== null && reason.rank !== undefined ? reason.rank.toString() : '';
    setRankValue(rankVal);

    const reasonSpecificOptions = calculateRankOptionsForReason(reason);
    setRankOptions(reasonSpecificOptions);
  };

  const cancelEditingRank = () => {
    setEditingRankId(null);
    setRankValue('');
  };

  const handleRankChange = (e, reason) => {
    const newValue = e.target.value;
    setRankValue(newValue);

    const newRank = newValue === '' ? null : Number(newValue);

    if (newRank !== reason.rank) {
      const updatedReasonData = {
        ...reason,
        rank: newRank
      };

      dispatch(updateReason({
        merchantId,
        reasonType,
        reasonId: reason.id,
        reasonData: updatedReasonData,
        callback: (success) => {
          if (success) {
            cancelEditingRank();
          }
        }
      }));
    } else {
      cancelEditingRank();
    }
  };

  const calculateRankOptionsForReason = (currentReason) => {
    const options = [{ value: '', label: 'No rank' }];

    const existingRanks = reasons
      .filter(r => r.rank !== null && r.rank !== undefined)
      .map(r => r.rank);

    let minRank = 1;
    let maxRank = existingRanks.length > 0 ? Math.max(...existingRanks) : 1;

    if (currentReason.rank === null || currentReason.rank === undefined && existingRanks.length > 0) {
      maxRank += 1;
    }

    for (let i = minRank; i <= maxRank; i++) {
      options.push({ value: i.toString(), label: i.toString() });
    }

    return options;
  };

  const renderContent = () => {

    if (reasonsLoading && (!reasons || reasons.length === 0)) {
      return;
    }

    if (reasonsErrors) {
      return (
        <MessageBar color="yellow">
          An error occurred while fetching {reasonType} reasons
        </MessageBar>
      );
    }

    if (!reasons || reasons.length === 0) {
      return (
        <MessageBar color="blue">
          No {reasonType} reasons found for this merchant
        </MessageBar>
      );
    }

    return (
      <>
        <Table size="medium">
          <TableHeader
            options={[
              { id: 'code', label: 'Code' },
              { id: 'name', label: 'Name' },
              { id: 'description', label: 'Description' },
              { id: 'category', label: 'Category' },
              { id: 'suggestedCostCover', label: 'Cost Cover' },
              { id: 'rank', label: 'Rank' },
              { id: 'actions', label: '', orderable: false, align: 'center' },
            ]}
          />
          <tbody className="table-body">
            {[...reasons]
              .sort((a, b) => {
                if (a.isSystem !== b.isSystem) {
                  return a.isSystem ? -1 : 1;
                }
                return 0;
              })
              .map((reason) => (
                <tr key={reason.id}>
                  <td>{reason.code || '-'}</td>
                  <td>{reason.name || '-'}</td>
                  <td>{reason.description || '-'}</td>
                  <td>{reason.category || '-'}</td>
                  <td>{reason.suggestedCostCover || '-'}</td>
                  <td className="rank-cell">
                    {editingRankId === reason.id ? (
                      <div className="rank-edit" ref={dropdownRef}>
                        <Dropdown
                          name="rank"
                          value={rankValue}
                          onChange={(e) => handleRankChange(e, reason)}
                          options={rankOptions}
                          placeholder="Select rank"
                          autoFocus
                        />
                      </div>
                    ) : (
                      <span
                        className="editable-rank"
                        onClick={() => startEditingRank(reason)}
                        title="Click to edit rank"
                      >
                        {reason.rank != null ? reason.rank : '-'}
                      </span>
                    )}
                  </td>
                  <td className="actions-cell">
                    {reason.isSystem ? (
                      reason.isHidden ? (
                        <ButtonIcon
                          icon={<EyeSlash />}
                          title="Hidden System Reason - Click to show"
                          onClick={() => handleToggleHidden(reason)}
                        />
                      ) : (
                        <ButtonIcon
                          icon={<Eye />}
                          title="Visible System Reason - Click to hide"
                          onClick={() => handleToggleHidden(reason)}
                        />
                      )
                    ) : (
                      <ButtonIcon
                        icon={<ThreeDotsVertical />}
                        options={[
                          {
                            value: 'Edit',
                            label: 'Edit',
                            onClick: () => handleOpenCreateEditModal(reason)
                          },
                          {
                            value: 'Delete',
                            label: 'Delete',
                            onClick: () => handleOpenDeleteModal(reason)
                          }
                        ]}
                      />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        <div className="add-reason-button">
          <Button
            onClick={() => handleOpenCreateEditModal()}
            label={`Add a custom ${reasonType} reason`}
          />
        </div>

        {showCreateEditModal &&
          <CreateEditReasonModal
            isOpen={showCreateEditModal}
            onClose={handleCloseCreateEditModal}
            merchantId={merchantId}
            reasonType={reasonType}
            reasonOptions={reasonOptions}
            existingReasons={reasons}
            reason={selectedReason}
          />
        }

        {showDeleteModal && selectedReason &&
          <DeleteReasonModal
            isOpen={showDeleteModal}
            onClose={handleCloseDeleteModal}
            merchantId={merchantId}
            reasonType={reasonType}
            reason={selectedReason}
          />
        }
      </>
    );
  };

  return (
    <Card>
      <Card.Header>{title || `${reasonType.charAt(0).toUpperCase() + reasonType.slice(1)} Reasons`}</Card.Header>
      <Card.Body>
        {(reasonsLoading || reasonLoading) && <LoadingAnimation />}
        {renderContent()}
      </Card.Body>
    </Card>
  );
};

ReasonManagement.propTypes = {
  merchantId: PropTypes.string.isRequired,
  reasonType: PropTypes.string.isRequired,
  title: PropTypes.string,
  reasonOptions: PropTypes.shape({
    costCoverOptions: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    }))
  })
};

export default ReasonManagement;
