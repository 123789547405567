import React from 'react';
import { ButtonIcon, SearchBar, Card } from 'app/components';
import { X } from 'react-bootstrap-icons';
import TreeNode from '../TreeNode';

const ColumnSelection = ({
  selectedColumns,
  onColumnSelect,
  onClearColumns,
  searchString,
  onSearchStringUpdated,
  columnTree
}) => (
  <Card className="data-selection">
    <div className="section-title">Column Selection</div>
    <div className="selection-info">
      <span>{`${selectedColumns.length} column${selectedColumns.length === 1 ? '' : 's'} selected`}</span>
      <ButtonIcon
        icon={<X />}
        size="small"
        onClick={onClearColumns}
        disabled={selectedColumns.length === 0}
      />
    </div>
    <div className="search-box">
      <SearchBar
        searchPlaceholderText="Search"
        onSearchStringUpdated={onSearchStringUpdated}
      />
    </div>
    <div className="column-tree">
      {Object.entries(columnTree.children || {}).map(([key, node]) => (
        <TreeNode
          key={key}
          node={node}
          level={0}
          selectedColumns={selectedColumns}
          onSelect={onColumnSelect}
          searchActive={!!searchString}
        />
      ))}
    </div>
  </Card>
);

export default ColumnSelection;
