import { put, takeEvery, call, all, takeLatest } from "redux-saga/effects";
import NoteServices from "app/services/noteService";
import { toast } from "react-toastify";

import {
  createNote,
  createNoteSuccess,
  createNoteFailure,
  getNotes,
  getNotesSuccess,
  getNotesFailure,
  deleteNote,
  deleteNoteSuccess,
  deleteNoteFailure,
  updateNote,
  updateNoteSuccess,
  updateNoteFailure,
} from "app/store/actions/note";

function* doCreateNote(action) {
  try {
    const resp = yield call([NoteServices, NoteServices.createNote], action.payload);
    yield put(createNoteSuccess(resp));
  } catch (error) {
    yield put(createNoteFailure(error?.message));
    toast.error(error?.message || "Creating note failed", {
      theme: "colored",
    });
  }
}

function* fetchEntityNotes(action) {
  try {
    const { entityType, entityId, isPrivate, page, pageSize } = action.payload;

    const data = yield call([NoteServices, NoteServices.getEntityNotes], entityType, entityId, isPrivate, page, pageSize);
    yield put(getNotesSuccess(data));
  } catch (error) {
    yield put(getNotesFailure(error?.message || "Fetching notes failed"));
  }
}

function* doDeleteNote(action) {
  try {
    const noteId = yield call([NoteServices, NoteServices.deleteNote], action.payload);
    yield put(deleteNoteSuccess(noteId));
    toast.success("Note deleted successfully", {
      theme: "colored",
    });
  } catch (error) {
    yield put(deleteNoteFailure(error?.message));
    toast.error(error?.message || "Deleting note failed", {
      theme: "colored",
    });
  }
}

function* doUpdateNote(action) {
  try {
    const resp = yield call([NoteServices, NoteServices.updateNote], action.payload);
    yield put(updateNoteSuccess(resp));
  } catch (error) {
    yield put(updateNoteFailure(error?.message));
    toast.error(error?.message || "Updating note failed", {
      theme: "colored",
    });
  }
}

function* watchData() {
  yield takeEvery(createNote.toString(), doCreateNote);
  yield takeLatest(getNotes.toString(), fetchEntityNotes);
  yield takeEvery(deleteNote.toString(), doDeleteNote);
  yield takeEvery(updateNote.toString(), doUpdateNote);
}

export default function* rootSaga() {
  yield all([watchData()]);
}
