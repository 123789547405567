import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Card, MessageBar } from "app/components";
import { onboardUser, validateInvitationCode, signinUser } from "app/store/actions/user";
import {
  userAuthSelector,
  invitationCodeDataSelector,
  invitationCodeLoadingSelector,
  invitationCodeErrorSelector,
  onboardUserLoadingSelector,
  onboardUserErrorSelector,
} from "app/store/selectors/user";
import { LoadingAnimation } from "app/components";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import { ReactComponent as Logo } from "app/assets/images/logos/main-logo-dark.svg";
import { Formik } from "formik";
import { object, string } from "yup";

import "./index.scss";

const Onboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAuth = useSelector(userAuthSelector);
  const invitationCodeData = useSelector(invitationCodeDataSelector);
  const invitationCodeLoading = useSelector(invitationCodeLoadingSelector);
  const invitationCodeError = useSelector(invitationCodeErrorSelector);
  const onboardUserLoading = useSelector(onboardUserLoadingSelector);
  const onboardUserError = useSelector(onboardUserErrorSelector);
  const [showPassword, setShowPassword] = useState(false);
  const [onboardError, setOnboardError] = useState(null);
  const { invitationCode } = useParams();

  useEffect(() => {
    if (invitationCode) {
      dispatch(validateInvitationCode({ invitationCode }));
    }
  }, []);

  if (invitationCodeLoading) return <LoadingAnimation />;

  return (
    <div className="onboard">
      {(onboardUserLoading || userAuth?.loading) && <LoadingAnimation />}
      <Logo className="logo" onClick={() => navigate("/")} />
      
      <div className="onboard-content">
        <Card className="header-card">
          <div className="header-card-title">Welcome to OrderMesh</div>
          {(invitationCodeData?.isInvitationCodeValid && !invitationCodeError) && (
            <>
              <div className="header-card-subtitle">
                {`${invitationCodeData?.merchantName} has invited you to join ${invitationCodeData?.merchantSubaccountName}.`}
              </div>
              <div className="header-card-subtitle">
                {`By signing in, you will be associated with ${invitationCodeData?.merchantSubaccountName}.`}
              </div>
            </>
          )}
          {(invitationCodeError || !invitationCodeData?.isInvitationCodeValid || onboardError) && (
            <MessageBar color="red">
              {onboardError || 'This invitation code is no longer valid'}
            </MessageBar>
          )}
          {onboardUserError && (
            <MessageBar color="red">
              {`Existing User Account Found : ${onboardUserError}`}
            </MessageBar>
          )}
        </Card>
        <Formik
          enableReinitialize
          initialValues={{
            password: "",
            firstName: "",
            lastName: "",
            email: invitationCodeData?.email || "",
          }}
          validationSchema={() =>
            object().shape({
              ...(invitationCodeData?.isExistingUser ? {} : {
                firstName: string().required("Enter a valid First Name"),
                lastName: string().required("Enter a valid Last Name"),
              }),
              password: string().required("Enter a valid Password"),
              email: string().required("Enter a valid Email"),
            })
          }
          onSubmit={async (values) => {
            setOnboardError(null);
            let data;
            if (invitationCodeData?.isExistingUser) {
              data = {
                email: values.email,
                password: values.password, 
                invitationCode,
              };
            } else {
              data = {
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                password: values.password, 
                invitationCode,
              };
            }

            dispatch(onboardUser({ 
              data,
              cb: () => {
                dispatch(signinUser({
                  username: values.email,
                  password: values.password,
                  onSigninSuccess: () => {
                    navigate("/");
                  }
                }));
              }
            }));
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            submitCount,
          }) => (
            <Card className="onboard-form-card">
              <form
                onSubmit={handleSubmit}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleSubmit();
                    event.preventDefault();
                  }
                }}
              >
                <div className="input-container">
                  {!invitationCodeData?.isExistingUser && (
                    <>
                      <Input
                        label="First Name"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        placeholder="First Name"
                        errorMessage={submitCount > 0 && errors.firstName}
                      />
                      <Input
                        label="Last Name"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        placeholder="Last Name"
                        errorMessage={submitCount > 0 && errors.lastName}
                      />
                    </>
                  )}
                  <Input
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    placeholder="Email"
                    disabled={invitationCodeData?.email}
                    errorMessage={submitCount > 0 && errors.email}
                  />
                  <Input
                    label="Password"
                    name="password"
                    value={values.password}
                    type={showPassword ? "text" : "password"}
                    onChange={handleChange}
                    placeholder="Password"
                    icon={showPassword ? <EyeSlashFill /> : <EyeFill />}
                    onIconClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    errorMessage={submitCount > 0 && errors.password}
                  />
                </div>
                <div className="submit-button-container">
                  <Button
                    variant="primary"
                    size="small"
                    label="Sign In"
                    disabled={isSubmitting || invitationCodeError || !invitationCodeData?.isInvitationCodeValid}
                    onClick={() => (userAuth.loading ? null : handleSubmit())}
                  />
                </div>
              </form>
            </Card>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Onboard;
