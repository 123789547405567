import axios from 'axios';
import Config from '../config';
import SetupInterceptors from './axiosInterceptors';

class InsightServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.InsightMS.Url
    });

    SetupInterceptors(this.api);
  }

  getAllQueries = async () => {
    try {
      const response = await this.api.get(`/v1/query`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching queries from API');
    }
  };

  runQueryById = async (queryId, runAs, dateRange, merchantIds, vendorIds) => {
    try {
      let queryString = "";

      const addParam = (key, value) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `&${key}=${encodeURIComponent(value)}`;
        }
      };

      if (dateRange) {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - dateRange);
        addParam('startDate', startDate.toISOString()?.split('T')[0]);
      }

      addParam('runAs', runAs);

      if (merchantIds) {
        merchantIds.forEach(m => {
          addParam('merchantIds', m);
        });
      }

      if (vendorIds) {
        vendorIds.forEach(v => {
          addParam('vendorIds', v);
        });
      }

      // runAs not needed for now...
      const response = await this.api.get(`/v1/query/${queryId}/run?${queryString}`);
      return response.data;
    } catch (error) {
      console.log('Error running query', error);
      throw new Error('Error running query');
    }
  };

  getAllExports = async ({ page, pageSize, allowForMerchantIds, q } = {}) => {
    try {
      const queryParams = new URLSearchParams();
      if (page) queryParams.append('page', page);
      if (pageSize) queryParams.append('pageSize', pageSize);
      if (q) queryParams.append('q', q);
      if (allowForMerchantIds) {
        allowForMerchantIds.forEach(m => {
          queryParams.append('allowForMerchantIds', m);
        });
      }
      
      const response = await this.api.get(`/v1/reports${queryParams.toString() ? `?${queryParams.toString()}` : ''}`);
      return response.data;
    } catch (error) {
      console.log('Error fetching exports', error);
      throw new Error('Error fetching exports');
    }
  };

  getSpecificExport = async (exportId) => {
    try {
      const response = await this.api.get(`/v1/reports/${exportId}`);
      return { reports: [response.data], total: 1 };
    } catch (error) {
      console.log('Error fetching specific export', error);
      throw new Error('Error fetching specific export from API');
    }
  };

  getExportRunHistory = async (exportId, page, pageSize) => {
    try {
      const queryParams = new URLSearchParams();
      if (page) queryParams.append('page', page);
      if (pageSize) queryParams.append('pageSize', pageSize);
      
      const response = await this.api.get(`/v1/reports/${exportId}/runs${queryParams.toString() ? `?${queryParams.toString()}` : ''}`);
      return response.data;
    } catch (error) {
      console.log('Error fetching export run history', error);
      throw new Error('Error fetching export run history');
    }
  };

  getAvailableExportColumns = async (runAs) => {
    try {
      const response = await this.api.get(`/v1/reports/columns?runAs=${runAs}`);
      return response.data;
    } catch (error) {
      console.log('Error fetching export columns', error);
      throw new Error('Error fetching export columns');
    }
  };

  createExport = async (data) => {
    try {
      const response = await this.api.post(`/v1/reports`, data);
      return response.data;
    } catch (error) {
      console.log('Error creating export', error);
      throw new Error('Error creating export');
    }
  };

  runExport = async (exportId, runAs, merchantIds, vendorIds) => {
    try {
      const response = await this.api.post(`/v1/reports/${exportId}/run`, { runAs, merchantIds, vendorIds });
      return response.data;
    } catch (error) {
      console.log('Error running export', error);
      throw new Error('Error running export');
    }
  };

  getExportRunDetails = async (exportId, runId) => {
    try {
      const response = await this.api.get(`/v1/reports/${exportId}/runs/${runId}`);
      return response.data;
    } catch (error) {
      console.log('Error fetching export run details', error);
      throw new Error('Error fetching export run details');
    }
  };

  deleteExport = async (exportId) => {
    try {
      const response = await this.api.delete(`/v1/reports/${exportId}`);
      return response.data;
    } catch (error) {
      console.log('Error deleting export', error);
      throw new Error('Error deleting export');
    }
  };

  getExportPreviewData = async (data) => {
    try {
      const response = await this.api.post(`/v1/reports/preview`, data);
      return response.data;
    } catch (error) {
      console.log('Error fetching export preview data', error);
      throw new Error('Error fetching export preview data');
    }
  };

  updateExport = async (exportId, data) => {
    try {
      const response = await this.api.put(`/v1/reports/${exportId}`, data);
      return response.data;
    } catch (error) {
      console.log('Error updating export', error);
      throw new Error('Error updating export');
    }
  };
}

export default new InsightServices();