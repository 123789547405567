import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getHistory } from "app/store/actions/history";
import { historyDataSelector, historyLoadingSelector, historyErrorsSelector } from 'app/store/selectors/history';
import { Table, TableHeader, Card, LoadingAnimation, Pagination} from 'app/components';
import moment from 'moment';
import './index.scss';


const HistoryView = (props) => {
  const { entityType, entityId } = props;
  const clientId = `${entityType}-${entityId}-client`;
  const dispatch = useDispatch();

  const historyData = useSelector(historyDataSelector);
  const historyDataLoading = useSelector(historyLoadingSelector);
  const historyDataError = useSelector(historyErrorsSelector);

  const [searchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);
  const sortDir = searchParams.get('sortDir') || 'desc';

  useEffect(() => {
    dispatch(getHistory({ parentType: 'AuthClient', parentId: clientId, page: currentPage, pageSize: pageSize, sortAscending: sortDir }));
  }, [entityType, entityId, currentPage, pageSize, sortDir, dispatch]);

  return (
    <Card className="auth-client-history-view">
      <Card.Header>
        Action History
      </Card.Header>
      {historyDataLoading && <LoadingAnimation />}
      {historyData && (
        <Card.Body>
          <Table size="small" verticalAlign="top" highlightRows={false}>
            <TableHeader
              sortBy="timestamp"
              sortDir={sortDir}
              options={[
                { id: 'timestamp', label: 'Timestamp', orderable: true },
                { id: 'user', label: 'User' },
                { id: 'event', label: 'Event' },
                { id: 'message', label: 'Message', width: "40%" },
              ]}
            />
            <tbody className="table-body">
              {historyData.historyLogs.map((history) => (
                <tr key={history.id}>
                  <td>{moment(history.dateCreated).local().format('MMM DD, YYYY h:mm:ss a')}</td>
                  <td>{history.userName}</td>
                  <td>{history.event}</td>
                  <td className="history-description">{history.description}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination totalItems={historyData?.total || 0} />
        </Card.Body>
      )}
      {historyData && historyData.historyLogs.length === 0 && (
        <Card.Body>
          <div className="history-data-empty">
            No history data found.
          </div>
        </Card.Body>
      )}
      {historyDataError && (
        <Card.Body>
          <div className="history-data-failed">
            History Data failed to load.  Refresh the page to try again.
          </div>
        </Card.Body>
      )}
    </Card>
  );
};

HistoryView.propTypes = {
  entityType: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  };

export default HistoryView;
