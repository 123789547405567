import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { reset } from 'app/store/actions/notification';
import { Card, TabbedMenu, Button } from 'app/components';
import ExportsList from './ExportsList';
import ActivityLog from './ActivityLog';
import { usePermission } from 'app/permissions';
import { subscriberSelector } from 'app/store/selectors/user';
import './index.scss';

const tabs = [
  {
    path: '/admin/exports',
    label: 'Exports',
    content: ExportsList,
  },
  {
    path: '/admin/exports/activity-log',
    label: 'Activity Log',
    content: ActivityLog,
  },
];

const ExportsView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get user permissions
  const canCreateExports = usePermission('insight', 'report_create');

  const subscriber = useSelector(subscriberSelector);

  return (
    <div className="exports-view">
      <Card>
        <div className="exports-view-header">
          Exports
          {canCreateExports && subscriber && (
            <Button
              variant="primary"
              size="medium"
              label={"Create Export"}
              onClick={() => {
                dispatch(reset());
                navigate(`/admin/exports/create-export`);
              }}
            />
          )}
        </div>
        <TabbedMenu tabs={tabs} showTabs={!!subscriber} />
      </Card>
    </div>
  )
}

export default ExportsView;