import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { Modal, Button, Input, LoadingAnimation, Dropdown } from 'app/components';
import { getMerchantDetails, createMerchant } from 'app/store/actions/merchant';
import { inviteSubaccount, clearInviteSubaccountData, inviteSubaccountSuccess, clearErrors } from 'app/store/actions/user';
import { merchantDetailsSelector, merchantDetailsLoadingSelector } from 'app/store/selectors/merchant';
import { inviteSubaccountDataSelector, inviteSubaccountLoadingSelector, inviteSubaccountErrorSelector } from 'app/store/selectors/user';
import { PersonAdd, ChevronDown, ChevronLeft, Link45deg, X } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { copyToClipboard } from 'app/utils';
import './index.scss';

const CREATE_MERCHANT_VALUE = 'create_new_merchant';

const EmailPillInput = ({ emails, setEmails, disabled, inputValue, setInputValue, onInputValidChange }) => {
  const inputRef = useRef(null);

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  // Notify parent of input validity changes
  useEffect(() => {
    if (onInputValidChange) {
      onInputValidChange(isValidEmail(inputValue.trim()));
    }
  }, [inputValue, onInputValidChange]);

  const addValidEmails = (text) => {
    const potentialEmails = text.split(/[;,\s]/).map(e => e.trim()).filter(Boolean);
    const newValidEmails = potentialEmails
      .filter(email => isValidEmail(email) && !emails.includes(email));

    if (newValidEmails.length > 0) {
      setEmails([...emails, ...newValidEmails]);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;

    // Handle pasted content with separators
    if (value.includes(';') || value.includes(',')) {
      addValidEmails(value);
      setInputValue('');
      return;
    }

    setInputValue(value);

    // Handle single email entry with space or enter
    if ([' ', 'Enter'].includes(value.slice(-1))) {
      const email = value.slice(0, -1).trim();
      if (email && isValidEmail(email) && !emails.includes(email)) {
        setEmails([...emails, email]);
      }
      setInputValue('');
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    addValidEmails(pastedText);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && !inputValue && emails.length > 0) {
      // Remove the last email pill when backspace is pressed on empty input
      setEmails(emails.slice(0, -1));
    }
  };

  const removeEmail = (indexToRemove) => {
    setEmails(emails.filter((_, index) => index !== indexToRemove));
  };

  const handleContainerClick = () => {
    inputRef.current?.focus();
  };

  return (
    <div
      className="email-pill-input"
      onClick={handleContainerClick}
    >
      <div className="pills-container">
        {emails.map((email, index) => (
          <div key={index} className="email-pill">
            <span>{email}</span>
            <X
              className="remove-pill"
              size={14}
              onClick={(e) => {
                e.stopPropagation();
                removeEmail(index);
              }}
            />
          </div>
        ))}
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onPaste={handlePaste}
          onKeyDown={handleKeyDown}
          placeholder={emails.length === 0 ? "Enter email addresses" : ""}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

const InviteMerchantModal = ({ showInviteMerchantModal, subscriberId, doSearch }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pendingMerchantId, setPendingMerchantId] = useState(null);
  const [linkSectionOpen, setLinkSectionOpen] = useState(false);
  const [emailSectionOpen, setEmailSectionOpen] = useState(false);
  const [inviteEmails, setInviteEmails] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [currentInputValid, setCurrentInputValid] = useState(false);
  const [invitedMerchants, setInvitedMerchants] = useState(new Set());
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [requestMade, setRequestMade] = useState(false);
  const formikRef = useRef();

  const merchantDetails = useSelector(merchantDetailsSelector);
  const merchantDetailsLoading = useSelector(merchantDetailsLoadingSelector);
  const inviteSubaccountData = useSelector(inviteSubaccountDataSelector);
  const inviteSubaccountLoading = useSelector(inviteSubaccountLoadingSelector);
  const inviteSubaccountError = useSelector(inviteSubaccountErrorSelector);

  useEffect(() => {
    // Reset initialDataLoaded flag when requesting fresh data
    setInitialDataLoaded(false);
    setRequestMade(true);
    dispatch(clearErrors());
    dispatch(getMerchantDetails(subscriberId));
  }, [subscriberId]);

  // Effect to track initial data loading
  useEffect(() => {
    // Only set to true when we've made a request AND the loading is complete
    if (!initialDataLoaded && requestMade && !merchantDetailsLoading && merchantDetails) {
      setInitialDataLoaded(true);
    }
  }, [merchantDetailsLoading, merchantDetails, initialDataLoaded, requestMade]);

  // Effect to handle merchant selection after details are loaded
  useEffect(() => {
    if (pendingMerchantId && merchantDetails?.children?.length > 0 && formikRef.current) {
      const merchantExists = merchantDetails.children.some(child => child.id === pendingMerchantId);
      if (merchantExists) {
        formikRef.current.setFieldValue('selectedChild', pendingMerchantId);
        formikRef.current.setFieldValue('name', '');
        formikRef.current.setFieldValue('email', '');
        setPendingMerchantId(null);
      }
    }
  }, [merchantDetails, pendingMerchantId]);

  const hasExistingMerchants = merchantDetails?.children?.length > 0;
  const childMerchantOptions = [
    ...(hasExistingMerchants ? [{
      label: (
        <div className="dropdown-option create-new">
          <PersonAdd className="option-icon" />
          <span>Create New Merchant</span>
        </div>
      ),
      value: CREATE_MERCHANT_VALUE,
      className: 'create-new-option'
    },
    {
      label: (
        <div className="dropdown-divider">
          <div className="divider-line"></div>
          <span>Or select existing merchant</span>
          <div className="divider-line"></div>
        </div>
      ),
      value: 'divider',
      disabled: true
    }] : []),
    ...(merchantDetails?.children?.map(child => ({
      label: child.name,
      value: child.id
    })) || [])
  ];

  const getValidationSchema = (isCreateMode) => {
    if (isCreateMode) {
      return object().shape({
        name: string().required('Name is required'),
        selectedChild: string().required()
      });
    }
    return object().shape({
      selectedChild: string().required('Please select a child merchant')
    });
  };

  const handleSendInvites = async () => {
    // Get emails from pills and any valid email in the input field
    let emailsToSend = [...inviteEmails];
    const trimmedInput = inputValue.trim();
    
    // Add current input if it's a valid email and not already in the list
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedInput);
    if (trimmedInput && isValidEmail && !inviteEmails.includes(trimmedInput)) {
      emailsToSend.push(trimmedInput);
    }
    
    // Exit if no emails to send
    if (emailsToSend.length === 0) return;

    const selectedChild = formikRef.current?.values?.selectedChild;

    if (selectedChild && selectedChild !== CREATE_MERCHANT_VALUE) {
      try {
        const data = {
          parentMerchantId: merchantDetails.id,
          childMerchantId: selectedChild,
          emails: emailsToSend
        };
        dispatch(inviteSubaccount({
          data,
          cb: (data) => {
            // Track success/failure counts
            const results = {
              success: 0,
              failed: 0,
              failedEmails: []
            };

            // Process each invitation result
            data.invitations?.forEach(invite => {
              if (!invite.isError && invite.status === 'Sent') {
                results.success++;
              } else {
                results.failed++;
                results.failedEmails.push(invite.email);
              }
            });

            // Show appropriate toast message
            if (results.success > 0) {
              toast.success(`Successfully sent ${results.success} invitation${results.success > 1 ? 's' : ''}`, {
                theme: 'colored',
              });
              if (results.failed > 0) {
                toast.error(`Failed to send invitations to: ${results.failedEmails.join(', ')}`, {
                  theme: 'colored',
                });
              }
              // Close modal only if at least one invitation succeeded
              showInviteMerchantModal(false);
            } else {
              toast.error('Failed to send invitations. Please try again.', {
                theme: 'colored',
              });
            }

            setInvitedMerchants(prev => new Set([...prev, { id: selectedChild, data }]));
            setInviteEmails([]);
            setEmailSectionOpen(false);
          }
        }));
      } catch (error) {
        console.error('Failed to send invitations:', error);
        toast.error('Failed to send invitations. Please try again.', {
          theme: 'colored',
        });
      }
    }
  }

  const handleSectionToggle = (section) => {
    if (section === 'link') {
      const selectedChild = formikRef.current?.values?.selectedChild;
      if (selectedChild && selectedChild !== CREATE_MERCHANT_VALUE) {
        const existingMerchant = Array.from(invitedMerchants).find(m => m.id === selectedChild);
        if (existingMerchant) {
          dispatch(inviteSubaccountSuccess(existingMerchant.data));
        } else {
          const data = {
            parentMerchantId: merchantDetails.id,
            childMerchantId: selectedChild
          };
          dispatch(inviteSubaccount({
            data,
            cb: (data) => {
              setInvitedMerchants(prev => new Set([...prev, { id: selectedChild, data }]));
            }
          }));
        }
      }
      setLinkSectionOpen(!linkSectionOpen);
      setEmailSectionOpen(false);
    } else {
      setEmailSectionOpen(!emailSectionOpen);
      setLinkSectionOpen(false);
    }
  };

  // Check if the required default role is configured (either missing in merchant details or mentioned in error)
  const isDefaultRoleMissing = (!merchantDetails?.subaccountDefaultRoleId || 
                               merchantDetails.subaccountDefaultRoleId.trim() === '' || 
                               (inviteSubaccountError && inviteSubaccountError.toString().includes('Default subaccount role is missing')));


  if (!initialDataLoaded) {
    return <LoadingAnimation />;
  }                              

  // If no default role is configured, show a message instead of the normal modal content
  if (merchantDetails && !merchantDetailsLoading && isDefaultRoleMissing) {
    return (
      <Modal
        className="invite-merchant-modal"
        title="Default Role Required"
        hideButtons={true}
        onClose={() => showInviteMerchantModal(false)}
      >
        <div className="default-role-message">
          <p>A default role for new merchants has not been configured.</p>
          <p>You must define a default role before you can invite new merchants to your organization.</p>
        </div>
        <div className="button-container">
          <Button
            variant="secondary"
            size="small"
            label="Close"
            onClick={() => showInviteMerchantModal(false)}
          />
          <Button
            variant="primary"
            size="small"
            label="Go to Roles Page"
            onClick={() => {
              showInviteMerchantModal(false);
              navigate('/admin/users/roles');
            }}
          />
        </div>
      </Modal>
    );
  }

  return (
    <>
      {merchantDetailsLoading && <LoadingAnimation />}
      <Formik
        innerRef={formikRef}
        initialValues={{
          name: '',
          selectedChild: '',
          inviteLink: '',
        }}
        validate={(values) => {
          const isCreateMode = values.selectedChild === CREATE_MERCHANT_VALUE;
          const schema = getValidationSchema(isCreateMode);
          try {
            schema.validateSync(values, { abortEarly: false });
            return {};
          } catch (err) {
            return err.inner.reduce((acc, error) => {
              acc[error.path] = error.message;
              return acc;
            }, {});
          }
        }}
        onSubmit={(values) => {
          if (values.selectedChild === CREATE_MERCHANT_VALUE) {
            const data = {
              legalBusinessName: values.name,
              name: values.name,
              address1: merchantDetails.address1,
              address2: merchantDetails.address2,
              city: merchantDetails.city,
              state: merchantDetails.state,
              country: merchantDetails.country,
              zipCode: merchantDetails.zipCode,
              enabled: true,
              isMerchantVasFlexible: merchantDetails.isMerchantVasFlexible,
              isParent: false,
              parentId: merchantDetails.id,
              ...(merchantDetails.customReturnAddress && {
                customReturnAddress: {
                  ...merchantDetails.customReturnAddress,
                }
              })
            };
            dispatch(createMerchant({
              data,
              cb: (merchantId) => {
                setPendingMerchantId(merchantId);
                dispatch(getMerchantDetails(subscriberId));
                doSearch();
              }
            }));
          } else {
            const selectedChildData = merchantDetails?.children?.find(child => child.id === values.selectedChild);
            if (selectedChildData) {
              // Show invite options instead of closing
              setLinkSectionOpen(false);
              setEmailSectionOpen(false);
            }
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          submitCount,
          setFieldValue,
        }) => {
          const isCreateMode = values.selectedChild === CREATE_MERCHANT_VALUE;
          const selectedMerchant = values.selectedChild && values.selectedChild !== CREATE_MERCHANT_VALUE;

          return (
            <Modal
              className="invite-merchant-modal"
              title={isCreateMode ? "Create New Merchant" : "Invite a Merchant"}
              hideButtons={true}
              onClose={() => showInviteMerchantModal(false)}
            >
              {!isCreateMode && (
                <div className="input-field">
                  <Dropdown
                    label="Select Merchant"
                    name="selectedChild"
                    value={values.selectedChild}
                    onChange={(e) => {
                      handleChange(e);
                      setLinkSectionOpen(false);
                      setEmailSectionOpen(false);
                      setInviteEmails([]);
                      dispatch(clearInviteSubaccountData());
                    }}
                    options={childMerchantOptions}
                    searchable={childMerchantOptions.length > 10}
                    showErrorMessages={false}
                  />
                </div>
              )}

              {isCreateMode && (
                <>
                  <div className="input-field">
                    <Input
                      label="Merchant Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      placeholder="Enter Merchant Name"
                      errorMessage={submitCount > 0 && errors.name}
                    />
                  </div>
                </>
              )}

              {selectedMerchant && (
                <div className="invite-options">
                  <div className="collapsible-section">
                    <div
                      className="section-header"
                      onClick={() => handleSectionToggle('link')}
                    >
                      <div className="header-content">
                        <Link45deg size={16} />
                        <span>Invite via link</span>
                      </div>
                      {linkSectionOpen ? <ChevronDown /> : <ChevronLeft />}
                    </div>
                    {linkSectionOpen && (
                      <div className="section-content">
                        <div className="invite-link-container">
                          <Input
                            name="inviteLink"
                            className={`invite-link-input ${(inviteSubaccountError || (inviteSubaccountData?.invitations?.[0]?.isError)) ? 'error' : ''}`}
                            value={(inviteSubaccountError || (inviteSubaccountData?.invitations?.[0]?.isError)) ? 'Invite link generation failed' : inviteSubaccountData?.invitations?.[0]?.invitationLink || ''}
                            readOnly
                            showErrorMessages={false}
                          />
                          {inviteSubaccountLoading ? (
                            <LoadingAnimation fullscreen={false} />
                          ) : (
                            <Button
                              variant="secondary"
                              size="small"
                              label={inviteSubaccountError || (inviteSubaccountData?.invitations?.[0]?.isError) ? "Retry" : "Copy"}
                              onClick={() => {
                                if (inviteSubaccountError || (inviteSubaccountData?.invitations?.[0]?.isError)) {
                                  const selectedChild = formikRef.current?.values?.selectedChild;
                                  if (selectedChild && selectedChild !== CREATE_MERCHANT_VALUE) {
                                    const existingMerchant = Array.from(invitedMerchants).find(m => m.id === selectedChild);
                                    if (existingMerchant) {
                                      dispatch(inviteSubaccountSuccess(existingMerchant.data));
                                    } else {
                                      const data = {
                                        parentMerchantId: merchantDetails.id,
                                        childMerchantId: selectedChild
                                      };
                                      dispatch(inviteSubaccount({
                                        data,
                                        cb: (data) => {
                                          setInvitedMerchants(prev => new Set([...prev, { id: selectedChild, data }]));
                                        }
                                      }));
                                    }
                                  }
                                } else {
                                  copyToClipboard(inviteSubaccountData?.invitations?.[0]?.invitationLink);
                                }
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="collapsible-section">
                    <div
                      className="section-header"
                      onClick={() => handleSectionToggle('email')}
                    >
                      <div className="header-content">
                        <PersonAdd size={16} />
                        <span>Invite via email</span>
                      </div>
                      {emailSectionOpen ? <ChevronDown /> : <ChevronLeft />}
                    </div>
                    {emailSectionOpen && (
                      <div className="section-content">
                        <div className="invite-email-container">
                          <EmailPillInput
                            emails={inviteEmails}
                            setEmails={setInviteEmails}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            disabled={inviteSubaccountLoading}
                            onInputValidChange={setCurrentInputValid}
                          />
                          {inviteSubaccountLoading ? (
                            <LoadingAnimation fullscreen={false} />
                          ) : (
                            <Button
                              variant="secondary"
                              size="small"
                              label="Send Invites"
                              onClick={handleSendInvites}
                              disabled={inviteEmails.length === 0 && !currentInputValid}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {isCreateMode && (
                <div className="button-container">
                  <Button
                    variant="secondary"
                    size="small"
                    label="Back"
                    onClick={() => {
                      setFieldValue('selectedChild', '');
                      setFieldValue('name', '');
                      setFieldValue('email', '');
                      setLinkSectionOpen(false);
                      setEmailSectionOpen(false);
                      setInviteEmails([]);
                    }}
                  />
                  <Button
                    variant="primary"
                    size="small"
                    label="Create Merchant"
                    onClick={handleSubmit}
                  />
                </div>
              )}
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

export default InviteMerchantModal;
