import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import "./index.scss";
import { Card, Button, ButtonIcon, Input, LoadingAnimation } from "app/components";
import { ArrowLeft } from "react-bootstrap-icons";
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from "formik";
import { object, string } from "yup";
import Editor from '@monaco-editor/react';
import { merchantDetailsSelector, templateLoadingSelector, templateSelector } from "app/store/selectors/merchant";
import { getTemplate, resetTemplate, createTemplate, updateTemplate, renderTemplate } from "app/store/actions/merchant";
import { formatCategoryName } from "../utils";
import { toast } from 'react-toastify';

const CreateTemplateView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { templateId, category } = useParams();
  const loading = useSelector(templateLoadingSelector);
  const template = useSelector(templateSelector);
  const merchant = useSelector(merchantDetailsSelector);
  const isEditMode = templateId && template;

  useEffect(() => {
    // fetch template data
    if (templateId) {
      dispatch(resetTemplate());
      dispatch(getTemplate(templateId));
    }
  }, [])

  const editorOptions = {
    overviewRulerLanes: 0,
    renderLineHighlight: 'none',
    cursorBlinking: 'blink',
    automaticLayout: true,
    minimap: { enabled: false },
    readOnly: false
  };

  const renderTemplateName = (values, handleChange, errors, submitCount) => {
    return (
      <Input className={"template-name"}
        label="Template Name"
        name="templateName"
        placeholder="Template Name"
        value={values.templateName}
        onChange={handleChange}
        errorMessage={submitCount > 0 && errors.templateName}
      />
    )
  }

  const renderTemplateDescription = (values, handleChange, errors, submitCount) => {
    return (
      <Input className={"template-description"}
        label="Template Description"
        name="templateDescription"
        placeholder="Template Description"
        value={values.templateDescription}
        onChange={handleChange}
        errorMessage={submitCount > 0 && errors.templateDescription}
      />
    )
  }

  const renderCodeSnippet = (values, setFieldValue, errors, submitCount) => {
    return (
      <div className="code-snippet">
        <div>Code Snippet</div>
        <Editor
          className="code-snippet-editor"
          options={editorOptions}
          height="400px"
          defaultLanguage="html"
          defaultValue={''}
          theme="light"
          value={values.html}
          onChange={(value) => setFieldValue("html", value)}
        />
        {submitCount > 0 && errors.html && <div className="code-editor-error-message">{errors.html}</div>}
      </div>
    )
  }

  const handleRenderTemplate = (file) => {
    window.open(file?.fileInfo?.url);
  }

  const handlePreviewTemplate = () => {
    if (template) {
      dispatch(renderTemplate({
        templateId: template.id,
        cb: handleRenderTemplate
      }))
    } else {
      toast.error("Please save the template first.")
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        templateName: isEditMode ? template.name : "",
        templateDescription: isEditMode ? template.description : "",
        html: isEditMode ? template.html : ""
      }}
      validationSchema={() =>
        object().shape({
          templateName: string()
            .required("Template name is required"),
          html: string()
            .required("Snippet code is required")
        })}
      onSubmit={async (values) => {
        if (isEditMode) {
          // update template
          dispatch(updateTemplate({
            values: {
              ...values,
              category,
              merchantId: template.merchantId,
              id: template.id
            },
            cb: () => navigate(-1)
          }));
        }
        else {
          // create template
          dispatch(createTemplate({
            values: {
              ...values,
              category,
              merchantId: merchant.id
            },
            cb: () => navigate(-1)
          }));
        }
      }}>
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        submitCount,
        setFieldValue
      }) =>
      (
        <div className="create-template-view">
          {loading && <LoadingAnimation />}
          <Card className="create-template-title-card">
            <Card.Header>
              <div className="top-holder">
                <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate(-1)} />
                <div>{isEditMode || loading ? 'Edit Template' : `Create ${formatCategoryName(category)}`}</div>
              </div>
              <Button label={templateId ? 'Save Changes' : 'Create Template'} onClick={handleSubmit} />
            </Card.Header>
          </Card>
          <Card className="create-template-card">
            <Card.Header>
              <span>{isEditMode || loading ? "Edit Template" : "Create Template"}</span>
              <Button
                label='Preview Template'
                disa
                onClick={handlePreviewTemplate}
                variant="secondary"
                size="small" />
            </Card.Header>
            <Card.Body>
              <div className="template-form">
                {renderTemplateName(values, handleChange, errors, submitCount)}
                {renderTemplateDescription(values, handleChange, errors, submitCount)}
              </div>
              {renderCodeSnippet(values, setFieldValue, errors, submitCount)}
            </Card.Body>
          </Card>
        </div>
      )}
    </Formik >
  )
}

export default CreateTemplateView;