import React, { useMemo } from 'react';
import { Tag } from 'app/components';
import StringFilter from './Filters/StringFilter';
import NumericFilter from './Filters/NumericFilter';
import BoolFilter from './Filters/BoolFilter';
import DateFilter from './Filters/DateFilter';
import './index.scss';

const ExportFilters = ({
  activeFilters,
  onFilterChange,
  activeFilter,
  activeFilterColumn,
  onCloseFilter,
}) => {
  const handleRemoveFilter = (columnName) => {
    const newFilters = { ...activeFilters };
    delete newFilters[columnName];
    onFilterChange(newFilters);
  };

  const formatPropertyPath = useMemo(() => (path) => {
    return path.split('.')
      .map(part => part
        .replace(/([A-Z])/g, ' $1') 
        .replace(/^./, str => str.toUpperCase())
        .trim()
      )
      .join(' ► ');
  }, []);

  const formatFilterValue = useMemo(() => (filter) => {
    if (filter?.columnType?.toLowerCase() === 'date') {
      const formatDate = (dateStr) => {
        if (!dateStr) return '';
        const date = new Date(dateStr);
        return `${(date.getUTCMonth() + 1).toString().padStart(2, '0')}/${date.getUTCDate().toString().padStart(2, '0')}/${date.getUTCFullYear()}`;
      };

      const date1 = formatDate(filter.value);
      const date2 = formatDate(filter.value2);
      if (date2 && date1 === date2) {
        return `on ${date1}`;
      }
      return `${date2 ? `between ${date1} and ${date2}` : date1}`;
    }
    return filter.value ? `${filter.type} "${filter.value}"` : `"${filter.type}"`;
  }, []);

  const renderFilterTags = () => {
    if (Object.keys(activeFilters).length === 0) return null;

    return (
      <div className="filter-tags">
        <span className="applied-filters-label">Applied Filters</span>
        {Object.entries(activeFilters).map(([columnName, filter]) => (
          <Tag
            key={columnName}
            label={`${formatPropertyPath(columnName)} ${formatFilterValue(filter)}`}
            onRemove={() => handleRemoveFilter(columnName)}
          />
        ))}
      </div>
    );
  };

  const renderFilterModal = () => {
    if (!activeFilter || !activeFilterColumn) return null;

    const props = {
      title: `Filter - ${activeFilterColumn.split('.').pop().replace(/([A-Z])/g, ' $1').trim().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}`,
      onClose: onCloseFilter,
      onApply: (filter) => {
        onFilterChange({
          ...activeFilters,
          [activeFilterColumn]: filter
        });
        onCloseFilter();
      },
      onClear: () => {
        handleRemoveFilter(activeFilterColumn);
        onCloseFilter();
      },
      currentFilter: activeFilters[activeFilterColumn]
    };

    switch (activeFilter) {
      case 'String':
        return <StringFilter {...props} />;
      case 'Int':
      case 'Decimal':
        return <NumericFilter {...props} />;
      case 'Bool':
        return <BoolFilter {...props} />;
      case 'Date':
        return <DateFilter {...props} />;
      default:
        return null;
    }
  };

  return (
    <>
      {renderFilterTags()}
      {renderFilterModal()}
    </>
  );
};

export default ExportFilters;
