import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { integrationsDataSelector, integrationsLoadingSelector, integrationsErrorsSelector } from 'app/store/selectors/integration';
import { getIntegrations, reset } from 'app/store/actions/integration';
import { Link, Card, Button, ButtonIcon, Table, TableHeader, Pagination, LoadingAnimation, SearchBar } from 'app/components';
import { ThreeDotsVertical, ExclamationCircle, ArrowLeft } from 'react-bootstrap-icons';
import DeleteIntegrationModal from './DeleteIntegrationModal';
import { usePermission } from 'app/permissions';
import './index.scss';

const IntegrationsView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [deleteIntegration, showDeleteIntegrationModal] = useState(null);

  const integrationData = useSelector(integrationsDataSelector);
  const loading = useSelector(integrationsLoadingSelector);
  const errorsFound = useSelector(integrationsErrorsSelector);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);
  const searchString = searchParams.get('searchString') || null;
  const sortBy = searchParams.get('sortBy') || 'id';
  const sortDir = searchParams.get('sortDir') || 'desc';

  // get user permissions
  const canManageIntegrations = usePermission('notification', 'create');

  useEffect(() => {
    if (!searchString || searchString.length !== 1) {
      dispatch(getIntegrations({
        searchString,
        currentPage,
        pageSize,
        sortBy,
        sortDir
      }));
    }
  }, [searchString, currentPage, pageSize, sortBy, sortDir, dispatch]);

  const onSearchStringUpdated = (searchString) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    searchString ? updatedSearchParams.set('searchString', searchString) : updatedSearchParams.delete('searchString');
    updatedSearchParams.set('page', 1);
    setSearchParams(updatedSearchParams.toString());
  };

  const integrationOptions = (plugin) => {
    return [
      ...(canManageIntegrations ? [{ value: 'Edit Basic Settings', label: 'Edit Basic Settings', onClick: () => { dispatch(reset()); navigate(`/admin/plugins/${plugin.id}/basic-settings`) }}] : []),
      ...(canManageIntegrations ? [{ value: 'Delete Plugin', label: 'Delete Plugin', destructive: true, onClick: () => { showDeleteIntegrationModal(plugin) }}] : [])
    ];
  }

  return (
    <div className="integrations-manager">
      {loading && <LoadingAnimation />}
      {(integrationData || errorsFound) && (
        <Card>
          <div className="merchants-header">
            
            <div className="merchants-header-title">
              <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate('/admin/plugins')} />
              <div className="order-num">
              Manage Plugins
              </div>
            </div>
            {canManageIntegrations && (
              <Button
                variant="primary"
                size="medium"
                label={"Create Plugin"}
                onClick={() => {
                  dispatch(reset());
                  navigate(`/admin/plugins/create-plugin`);
                }}
              />
            )}
          </div>
          <div className="search-bar-and-filters">
            <SearchBar
              searchPlaceholderText="Search for a Plugin"
              onSearchStringUpdated={onSearchStringUpdated}
              debounceDelay={500}
              disabled={!!errorsFound}
            />
          </div>
          <Table size="medium">
            <TableHeader
              sortBy={sortBy}
              sortDir={sortDir}
              options={[
                { id: 'integrationName', label: 'Plugin Name' },
                { id: 'pluginId', label: 'Plugin ID' },
                { id: 'type', label: 'Type' },
                { id: 'integratorName', label: 'Integrator Name' },
                { id: 'audience', label: 'Audience' },
                ...(integrationData?.plugins && integrationData.plugins.length > 0 && integrationOptions(integrationData.plugins[0]).length > 0 ? [{ id: 'actions', label: 'Actions', align: 'center' }] : [])
              ]}
            />
            <tbody className="table-body">
              {integrationData?.plugins?.map((plugin) => {
                const options = integrationOptions(plugin);
                              
                return (
                  <tr className="plugin-row" key={plugin.id}>
                    <td>
                      <Link
                        label={plugin.name}
                        url={`/admin/plugins/${plugin.id}/0`}
                        onClick={() => {
                          dispatch(reset());
                          navigate(`/admin/plugins/${plugin.id}/0`);
                        }}
                      />
                    </td>
                    <td>{plugin.id}</td>
                    <td>{plugin.type}</td>
                    <td>{plugin.vendorName}</td>
                    <td>{plugin.isPrivate ? 'Private' : 'Public'}</td>
                    {options.length > 0 ? <td className="integration-actions"><ButtonIcon icon={<ThreeDotsVertical />} options={integrationOptions(plugin)} /></td> : <td></td>}
                  </tr>
                );
              })}
              {integrationData?.plugins?.length === 0 && !errorsFound && !loading && (
                <tr>
                  <td colSpan="6" className="error-message">No Results Found</td>
                </tr>
              )}
              {errorsFound && !loading && (
                <tr>
                  <td colSpan="6" className="error-message"><ExclamationCircle />Error fetching Plugin data</td>
                </tr>
              )}
            </tbody>
          </Table>
          {integrationData && (
            <Pagination
              totalItems={integrationData?.total || 0}
            />
          )}
          {deleteIntegration && (
            <DeleteIntegrationModal
              plugin={deleteIntegration}
              showDeleteIntegrationModal={showDeleteIntegrationModal}
            />
          )}
        </Card>
      )}
    </div>
  )
}

export default IntegrationsView;