import { createSelector } from 'reselect';

const transSelector = state => state.transformation;

export const transformationsSelector = createSelector([transSelector], transformationStore => transformationStore.transformations.data);
export const transformationsLoadingSelector = createSelector([transSelector], transformationStore => transformationStore.transformations.loading);
export const transformationsErrorSelector = createSelector([transSelector], transformationStore => transformationStore.transformations.error);

export const transformationSelector = createSelector([transSelector], transformationStore => transformationStore.transformation.data);
export const transformationLoadingSelector = createSelector([transSelector], transformationStore => transformationStore.transformation.loading);
export const transformationErrorSelector = createSelector([transSelector], transformationStore => transformationStore.transformation.error);

export const auditInfoSelector = createSelector([transSelector], transformationStore => transformationStore.auditInfo.data);
export const auditInfoLoadingSelector = createSelector([transSelector], transformationStore => transformationStore.auditInfo.loading);
export const auditInfoErrorSelector = createSelector([transSelector], transformationStore => transformationStore.auditInfo.error);