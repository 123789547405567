import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { vendorDetailsSelector, vendorDetailsLoadingSelector, vendorDetailsErrorsSelector } from 'app/store/selectors/vendor';
import { getVendorDetails, createVendor, updateVendor } from 'app/store/actions/vendor';
import { LoadingAnimation, Card, Button, Input, Dropdown, DataPoint, MessageBar } from 'app/components'
import { convertCountryCodeToName } from 'app/utils'
import { CountryList } from 'app/constants';
import { usePermission } from 'app/permissions';
import './index.scss';

const DetailsView = props => {
  const { vendorId } = props;
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(vendorDetailsLoadingSelector);
  const vendorDetails = useSelector(vendorDetailsSelector);
  const vendorDetailsErrorsFound = useSelector(vendorDetailsErrorsSelector);
  
  // get user permissions
  const canManageVendor = usePermission('vendor', 'create');

  useEffect(() => {
    if (vendorId && (!vendorDetails || vendorDetails.vendorId !== vendorId)) {
      dispatch(getVendorDetails({vendorId}));
    }
  }, [vendorId, vendorDetails?.id, dispatch]);

  useEffect(() => {
    if (!vendorDetails) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [vendorDetails]);

  const onVendorCreated = (vendorId) => {
    navigate(`/admin/vendors/${vendorId}`);
  }

  const onVendorUpdated = () => {
    setEditMode(false);
  }

  return (
    <div className="vendor-details-view">
      {loading && <LoadingAnimation />}
      <Formik
        enableReinitialize
        initialValues={{
          vendorId: vendorDetails?.vendorId || '',
          vendorLegalName: vendorDetails?.legalName || '',
          vendorName: vendorDetails?.name || '',
          address1: vendorDetails?.address?.line1 || '',
          address2: vendorDetails?.address?.line2 || '',
          city: vendorDetails?.address?.city || '',
          state: vendorDetails?.address?.state || '',
          zipPostalCode: vendorDetails?.address?.zip || '',
          url: vendorDetails?.url || '',
          countryCode: vendorDetails?.address?.countryCode || 'US',
          contactName: vendorDetails?.contacts[0]?.name || '',
          role: vendorDetails?.contacts[0]?.role || '',
          phoneNumber: vendorDetails?.contacts[0]?.phone || '',
          email: vendorDetails?.contacts[0]?.email || '',
        }}
        validationSchema={() =>
          object().shape({
            vendorLegalName: string().required('Enter a valid Vendor Legal Name'), // required
            vendorName: string().required('Enter a valid Vendor Name'), // required
            address1: string()
              .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 1') // address
              .matches(/[\x20-\x7F]+/, 'Enter a valid Address 1') // onlyEnglish
              .max(35, 'Please limit to 35 characters')
              .required('Enter a valid Address 1'), // required
            address2: string().when(['address1', 'countryCode'], (address1, countryCode) => {
              if (countryCode !== 'US') {
                if (address1) {
                  return string()
                    .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                    .matches(/[\x20-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
                    .max(
                      35 - address1.length,
                      'Please limit to 35 characters for Address 1 + Address 2'
                    );
                } else {
                  return string()
                    .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                    .matches(/[\x20-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
                    .max(35, 'Please limit to 35 characters for Address 1 + Address 2');
                }
              } else {
                return string()
                  .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                  .matches(/[\x20-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
                  .max(35, 'Please limit to 35 characters');
              }
            }),
            city: string()
              .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid City') // address
              .matches(/[\x20-\x7F]+/, 'Enter a valid City') // onlyEnglish
              .required('Enter a valid City'), // required
            state: string()
              .when('countryCode', {
                is: (countryCode) => ['CA', 'US'].includes(countryCode),
                then: () => string().required('Enter a valid State/Province'),
                otherwise: () => string()
              })
              .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid State/Province')
              .matches(/[\x20-\x7F]+/, 'Enter a valid State/Province'),
            zipPostalCode: string().when('countryCode', {
              is: countryCode => countryCode === 'US',
              then: () => string()
                .matches(/^[a-zA-Z\d\s-]*$/, 'Enter a valid zip code') // zipcode
                .required('Enter a valid zip code'),
              otherwise: () => string().when('countryCode', {
                is: countryCode => countryCode === 'CA',
                then: () => string()
                  .matches(/^[a-zA-Z\d\s-]*$/, 'Enter a valid postal code') // postalcode
                  .required('Enter a valid postal code'),
                otherwise: () => string().matches(/^[a-zA-Z\d\s-]*$/, 'Enter a valid postal code') // postalcode
              })
            }),
            countryCode: string().required('Enter a Country'), // required
            url: string()
              .transform((value) => {
                if (value && !/^https?:\/\//i.test(value)) {
                  return `https://${value}`;
                }
                return value;
              })
              .url('Enter a valid Portal URL'), // url
            contactName: string(),
            role: string(),
            phoneNumber: string(),
            email: string().email('Enter a valid Email'),
          })
        }
        onSubmit={async (values) => {
          const data = {
            "legalName": values.vendorLegalName,
            "name": values.vendorName,
            "address": {
              "line1": values.address1,
              "line2": values.address2,
              "city": values.city,
              "state": values.state,
              "zip": values.zipPostalCode,
              "countryCode": values.countryCode,
            },
            "url": values.url && !/^https?:\/\//i.test(values.url) ? `https://${values.url}` : values.url,
            "contacts": [{
              "name": values.contactName,
              "role": values.role,
              "phone": values.phoneNumber,
              "email": values.email,
            }],
          };

          if (vendorDetails) {
            // update existing vendor
            dispatch(updateVendor({ vendorId: vendorDetails.vendorId, data, cb: onVendorUpdated }));
          } else {
            // create new vendor
            dispatch(createVendor({ data, cb: onVendorCreated }));
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          submitCount,
        }) => (
          <Card className={`vendor-details-card ${editMode && !vendorDetailsErrorsFound ? 'edit-mode' : 'view-mode'}`}>
            <Card.Header>
              Vendor Details
              {!editMode && vendorDetails && !vendorDetailsErrorsFound && canManageVendor && (
                <Button
                  variant="primary"
                  size="small"
                  label="Edit Vendor Details"
                  onClick={() => setEditMode(true)}
                />
              )}
              {editMode && !vendorDetailsErrorsFound && canManageVendor && (
                <div className={`action-buttons ${!vendorDetails ? 'new-vendor' : ''}`}>
                  <Button
                    variant="secondary"
                    size={!vendorDetails ? "medium" : "small"}
                    label="Cancel"
                    disabled={loading}
                    onClick={() => !vendorDetails ? navigate('/admin/vendors/') : setEditMode(false)}
                  />
                  <Button
                    variant="primary"
                    size={!vendorDetails ? "medium" : "small"}
                    label={!vendorDetails ? 'Create Vendor' : 'Update'}
                    disabled={loading}
                    onClick={() => (loading ? null : handleSubmit())}
                  />
                </div>
              )}
            </Card.Header>
            <Card.Body>
              {!editMode && vendorDetails && !vendorDetailsErrorsFound && (
                <>
                  <div className={`vendor-information ${editMode ? 'edit-mode' : 'view-mode'}`}>
                    <DataPoint title="Legal Name" data={vendorDetails.legalName} />
                    <DataPoint title="Vendor Name" data={vendorDetails.name} />
                    <DataPoint title="Portal URL" data={vendorDetails.url || '-'} />
                    <DataPoint title="Address" data={`${vendorDetails.address?.line1}${vendorDetails.address?.line2 ? ' ' + vendorDetails.address.line2 : ''} ${vendorDetails.address?.city}, ${vendorDetails.address?.state} ${convertCountryCodeToName(vendorDetails.address?.countryCode)} ${vendorDetails.address?.zip}`} />
                  </div>
                  <div className="vendor-contact-information">
                    Vendor Contact Information
                  </div>
                  <div className={`vendor-information mb-0 ${editMode ? 'edit-mode' : 'view-mode'}`}>
                    <DataPoint title="Contact Name" data={vendorDetails.contacts[0]?.name || '-'} />
                    <DataPoint title="Role" data={vendorDetails.contacts[0]?.role || '-'} />
                    <DataPoint title="Phone Number" data={vendorDetails.contacts[0]?.phone || '-'} />
                    <DataPoint title="Email" data={vendorDetails.contacts[0]?.email || '-'} />
                  </div>
                </>
              )}
              {editMode && !vendorDetailsErrorsFound && (
                <form onSubmit={handleSubmit}>
                  <div className={`vendor-information ${editMode ? 'edit-mode' : 'view-mode'}`}>
                    <Input
                      label="Legal Name"
                      name="vendorLegalName"
                      value={values.vendorLegalName}
                      onChange={handleChange}
                      placeholder="Legal Name"
                      errorMessage={submitCount > 0 && errors.vendorLegalName}
                    />
                    <Input
                      label="Vendor Name"
                      name="vendorName"
                      value={values.vendorName}
                      onChange={handleChange}
                      placeholder="Vendor Name"
                      errorMessage={submitCount > 0 && errors.vendorName}
                    />
                     <Input
                      label="Portal URL"
                      name="url"
                      value={values.url}
                      onChange={handleChange}
                      placeholder="Portal URL"
                      errorMessage={submitCount > 0 && errors.url}
                    />
                  </div>
                  <div className="address-information">Address Information</div>
                  <div className={`vendor-information ${editMode ? 'edit-mode' : 'view-mode'}`}>
                    <Input
                      label="Address Line 1"
                      name="address1"
                      value={values.address1}
                      onChange={handleChange}
                      placeholder="Street Address"
                      errorMessage={submitCount > 0 && errors.address1}
                    />
                    <Input
                      label="Address Line 2"
                      name="address2"
                      value={values.address2}
                      onChange={handleChange}
                      placeholder="Apt, Suite, unit, building, floor, etc"
                      errorMessage={submitCount > 0 && errors.address2}
                    />
                    <Input
                      label="City"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      placeholder="City"
                      errorMessage={submitCount > 0 && errors.city}
                    />
                    <Input
                      label="State / Province"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      placeholder="State / Province"
                      errorMessage={submitCount > 0 && errors.state}
                    />
                    <Input
                      label="Zip / Postal Code"
                      name="zipPostalCode"
                      value={values.zipPostalCode}
                      onChange={handleChange}
                      placeholder="Zip / Postal Code"
                      errorMessage={submitCount > 0 && errors.zipPostalCode}
                    />
                    <Dropdown
                      label="Country"
                      name="countryCode"
                      value={values.countryCode}
                      onChange={handleChange}
                      options={CountryList.map(country => (
                        { value: country.CountryCode, label: `${country.CountryCode} - ${country.Name}` }
                      ))}
                      errorMessage={submitCount > 0 && errors.countryCode}
                    />
                  </div>
                  <div className="vendor-contact-information">
                    Vendor Contact Information
                  </div>
                  <div className={`vendor-information ${editMode ? 'edit-mode' : 'view-mode'}`}>
                    <Input
                      label="Contact Name"
                      name="contactName"
                      value={values.contactName}
                      onChange={handleChange}
                      placeholder="Contact Name"
                      errorMessage={submitCount > 0 && errors.contactName}
                    />
                    <Input
                      label="Role"
                      name="role"
                      value={values.role}
                      onChange={handleChange}
                      placeholder="Role"
                      errorMessage={submitCount > 0 && errors.role}
                    />
                    <Input
                      label="Phone Number"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      placeholder="Phone Number"
                      errorMessage={submitCount > 0 && errors.phoneNumber}
                    />
                    <Input
                      label="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      placeholder="Email"
                      errorMessage={submitCount > 0 && errors.email}
                    />
                  </div>
                </form>
              )}
              {vendorDetailsErrorsFound && (
                <MessageBar color="yellow">
                  An error occurred while fetching data
                </MessageBar>
              )}
            </Card.Body>
          </Card>
        )}
      </Formik>
    </div>
  )
}

export default DetailsView;