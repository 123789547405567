import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getHistory, reset as resetHistoryData } from 'app/store/actions/history';
// import { getWebhookAuditInfo } from 'app/store/actions/notification';
import { auditInfoLoadingSelector } from 'app/store/selectors/notification';
import { historyDataSelector, historyLoadingSelector, historyErrorsSelector } from 'app/store/selectors/history';
import { Table, TableHeader, Card, LoadingAnimation, AuditInfoModal } from 'app/components';
import { subscriberSelector } from 'app/store/selectors/user';
// import moment from 'moment';
import './index.scss';

const ActivityLog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [auditInfo, setAuditInfo] = useState(null);

  const historyData = useSelector(historyDataSelector);
  const historyDataLoading = useSelector(historyLoadingSelector);
  const historyDataError = useSelector(historyErrorsSelector);
  const auditInfoLoading = useSelector(auditInfoLoadingSelector);
  const subscriber = useSelector(subscriberSelector);

  const sortBy = searchParams.get('sortBy') || 'dateCreated';
  const sortDir = searchParams.get('sortDir') || 'desc';
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);

  useEffect(() => {
    if (!subscriber) {
      navigate('/admin/webhooks');
    }
  }, [subscriber]);

  useEffect(() => {
    dispatch(resetHistoryData());
  }, []);

  useEffect(() => {
    dispatch(getHistory({parentType: 'user', parentId: subscriber.id, entityType: 'notification-api', page: currentPage, pageSize: pageSize, sortAscending: sortDir}));
  }, [subscriber, currentPage, pageSize, sortDir, dispatch]);

  return (
    <div className="webhook-action-history">
      {(historyDataLoading || auditInfoLoading) && <LoadingAnimation />}
      {historyData && (
        <>
          <Table size="medium" verticalAlign="top" highlightRows={false}>
            <TableHeader
              sortBy={sortBy}
              sortDir={sortDir}
              options={[
                { id: 'dateCreated', label: 'Date Created', orderable: true },
                { id: 'id', label: 'Log ID' },
                { id: 'userId', label: 'User ID' },
                { id: 'description', label: 'Description' },
                { id: 'action', label: 'Action', width: "105px" },
              ]}
            />
            {/* <tbody className="table-body">
              {historyData.historyLogs.map((log) => (
                <tr key={log.id}>
                  <td className="history-date">{moment(log.dateCreated).local().format('MMM DD, YYYY h:mm:ss a')}</td>
                  <td>{log.id}</td>
                  <td>{log.userId}</td>
                  <td>{log.description}</td>
                  <td>
                    <Link
                      label="View Log"
                      onClick={() => dispatch(getWebhookAuditInfo({ auditUrl: log.auditUrl, cb: (auditInfo) => setAuditInfo(auditInfo?.events || []) }))}
                    />
                  </td>
                </tr>
              ))}
            </tbody> */}
          </Table>
          {/* <Pagination totalItems={historyData?.total || 0} /> */}
        </>
      )}
      {historyData && ( //historyData.historyLogs.length === 0 && (
        <Card.Body>
          <div className="no-results">
            No Export Activity found.
          </div>
        </Card.Body>
      )}
      {historyDataError && (
        <Card.Body>
          <div className="history-data-failed">
            Export Activity failed to load.  Refresh the page to try again.
          </div>
        </Card.Body>
      )}
      {auditInfo !== null && (
        <AuditInfoModal
          title="Audit Log Details"
          auditInfo={auditInfo}
          onClose={() => setAuditInfo(null)}
        />
      )}
    </div>
  );
};

export default ActivityLog;
