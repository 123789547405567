import React from 'react';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { Modal, Button, Dropdown } from 'app/components';

import './index.scss';

const BoolFilter = (props) => {
  const { title, onClose, onApply, onClear, currentFilter } = props;

  return (
    <Formik
      initialValues={{
        condition: currentFilter?.value?.toString() || '',
      }}
      validationSchema={object().shape({
        condition: string().required('Selection is required'),
      })}
      onSubmit={(values) => {
        onApply({
          columnType: 'bool',
          type: 'equals',
          value: values.condition === 'true' ? 'true' : 'false',
        });
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        submitCount,
        setFieldValue,
      }) => (
        <Modal
          className="bool-filter"
          title={title}
          hideButtons={true}
          onClose={onClose}
        >
          <Dropdown
            label="Value"
            name="condition"
            value={values.condition}
            onChange={(e) => {
              handleChange(e);
              setFieldValue('condition', e.target.value);
            }}
            options={[
              { value: 'true', label: 'Is True' },
              { value: 'false', label: 'Is False' },
            ]}
            errorMessage={submitCount > 0 && errors.condition}
          />
          <div className="action-buttons">
            <Button
              variant="secondary"
              size="small"
              label="Cancel"
              onClick={onClose}
            />
            <Button
              variant="secondary"
              size="small"
              label="Clear Filter"
              onClick={onClear}
            />
            <Button
              variant="primary"
              size="small"
              label="Apply Filter"
              onClick={handleSubmit}
              disabled={!values.condition}
            />
          </div>
        </Modal>
      )}
    </Formik>
  );
};

export default BoolFilter;
