import { createAction } from 'redux-actions';

const prefix = "INSIGHT";

export const getAllQueries = createAction(`${prefix}/GET_ALL_QUERIES`);
export const getAllQueriesSuccess = createAction(`${prefix}/GET_ALL_QUERIES_SUCCESS`);
export const getAllQueriesFailure = createAction(`${prefix}/GET_ALL_QUERIES_FAILURE`);

export const runQueryById = createAction(`${prefix}/RUN_QUERY_BY_ID`);
export const runQueryByIdSuccess = createAction(`${prefix}/RUN_QUERY_BY_ID_SUCCESS`);
export const runQueryByIdFailure = createAction(`${prefix}/RUN_QUERY_BY_ID_FAILURE`);

export const getAllExports = createAction(`${prefix}/GET_ALL_EXPORTS`);
export const getAllExportsSuccess = createAction(`${prefix}/GET_ALL_EXPORTS_SUCCESS`);
export const getAllExportsFailure = createAction(`${prefix}/GET_ALL_EXPORTS_FAILURE`);

export const getExportRunHistory = createAction(`${prefix}/GET_EXPORT_RUN_HISTORY`);
export const getExportRunHistorySuccess = createAction(`${prefix}/GET_EXPORT_RUN_HISTORY_SUCCESS`);
export const getExportRunHistoryFailure = createAction(`${prefix}/GET_EXPORT_RUN_HISTORY_FAILURE`);

export const getAvailableExportColumns = createAction(`${prefix}/GET_AVAILABLE_EXPORT_COLUMNS`);
export const getAvailableExportColumnsSuccess = createAction(`${prefix}/GET_AVAILABLE_EXPORT_COLUMNS_SUCCESS`);
export const getAvailableExportColumnsFailure = createAction(`${prefix}/GET_AVAILABLE_EXPORT_COLUMNS_FAILURE`);

export const createExport = createAction(`${prefix}/CREATE_EXPORT`);
export const createExportSuccess = createAction(`${prefix}/CREATE_EXPORT_SUCCESS`);
export const createExportFailure = createAction(`${prefix}/CREATE_EXPORT_FAILURE`);

export const runExport = createAction(`${prefix}/RUN_EXPORT`);
export const runExportSuccess = createAction(`${prefix}/RUN_EXPORT_SUCCESS`);
export const runExportFailure = createAction(`${prefix}/RUN_EXPORT_FAILURE`);

export const pollRunningExport = createAction(`${prefix}/POLL_RUNNING_EXPORT`);

export const getExportRunDetails = createAction(`${prefix}/GET_EXPORT_RUN_DETAILS`);

export const deleteExport = createAction(`${prefix}/DELETE_EXPORT`);
export const deleteExportSuccess = createAction(`${prefix}/DELETE_EXPORT_SUCCESS`);
export const deleteExportFailure = createAction(`${prefix}/DELETE_EXPORT_FAILURE`);

export const getExportPreviewData = createAction(`${prefix}/GET_EXPORT_PREVIEW_DATA`);
export const getExportPreviewDataSuccess = createAction(`${prefix}/GET_EXPORT_PREVIEW_DATA_SUCCESS`);
export const getExportPreviewDataFailure = createAction(`${prefix}/GET_EXPORT_PREVIEW_DATA_FAILURE`);

export const clearExportPreviewData = createAction(`${prefix}/CLEAR_EXPORT_PREVIEW_DATA`);

export const getSpecificExport = createAction(`${prefix}/GET_SPECIFIC_EXPORT`);
export const getSpecificExportSuccess = createAction(`${prefix}/GET_SPECIFIC_EXPORT_SUCCESS`);
export const getSpecificExportFailure = createAction(`${prefix}/GET_SPECIFIC_EXPORT_FAILURE`);

export const updateExport = createAction(`${prefix}/UPDATE_EXPORT`);
export const updateExportSuccess = createAction(`${prefix}/UPDATE_EXPORT_SUCCESS`);
export const updateExportFailure = createAction(`${prefix}/UPDATE_EXPORT_FAILURE`);

export const reset = createAction(`${prefix}/RESET`);