import React from 'react';
import { Formik } from 'formik';
import { object, date, ref } from 'yup';
import { Modal, Button } from 'app/components';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './index.scss';

const DateFilter = (props) => {
  const { title, onClose, onApply, onClear, currentFilter } = props;

  const getInitialValues = () => {
    const parseDate = (dateStr) => {
      if (!dateStr) return null;
      // Handle ISO string format
      if (dateStr.includes('T')) {
        const date = new Date(dateStr);
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
      }
      // Handle MM/DD/YYYY format (fallback)
      const [month, day, year] = dateStr.split('/');
      return new Date(year, month - 1, day);
    };

    return {
      date1: currentFilter ? parseDate(currentFilter.value) : null,
      date2: currentFilter ? parseDate(currentFilter.value2) : null,
    };
  };

  const validationSchema = object().shape({
    date1: date().nullable().required('Start date is required'),
    date2: date()
      .nullable()
      .required('End date is required')
      .min(ref('date1'), 'End date must be after start date')
      .test(
        'within-one-year',
        'Date range cannot exceed 1 year',
        function (value) {
          const { date1 } = this.parent;
          if (date1 && value) {
            const diffInDays = Math.abs((value.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24));
            return diffInDays <= 365;
          }
          return true;
        }
      ),
  });

  const formatFilterValue = (values) => {
    const formatDate = (date) => {
      if (!date) return null;
      const d = new Date(date);
      return `${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getDate().toString().padStart(2, '0')}/${d.getFullYear()}`;
    };

    return {
      columnType: 'date',
      type: 'between',
      value: formatDate(values.date1),
      value2: formatDate(values.date2),
    };
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onApply(formatFilterValue(values));
      }}
    >
      {({ values, errors, setFieldValue, submitCount, handleSubmit }) => (
        <Modal
          className="date-filter"
          title={title}
          hideButtons={true}
          onClose={onClose}
        >
          <div className="fixed-date-filter">
            <div className="date-picker-container">
              <div className="date-picker-wrapper">
                <label>From</label>
                <DatePicker
                  onChange={(date) => setFieldValue('date1', date)}
                  value={values.date1}
                  format="MM/dd/yyyy"
                  clearIcon={null}
                />
                {submitCount > 0 && errors.date1 && (
                  <div className="error-message">{errors.date1}</div>
                )}
              </div>
              <div className="date-picker-wrapper">
                <label>To</label>
                <DatePicker
                  onChange={(date) => setFieldValue('date2', date)}
                  value={values.date2}
                  format="MM/dd/yyyy"
                  clearIcon={null}
                />
                {submitCount > 0 && errors.date2 && (
                  <div className="error-message">{errors.date2}</div>
                )}
              </div>
            </div>
            <div className="action-buttons">
              <Button
                variant="secondary"
                size="small"
                label="Cancel"
                onClick={onClose}
              />
              <Button
                variant="secondary"
                size="small"
                label="Clear Filter"
                onClick={onClear}
              />
              <Button
                variant="primary"
                size="small"
                label="Apply Filter"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </Modal>
      )}
    </Formik>
  );
};

export default DateFilter;
