import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getAllExports } from 'app/store/actions/insight';
import { exportsSelector, exportsLoadingSelector, exportsErrorsSelector, runExportLoadingSelector } from 'app/store/selectors/insight';
import { Card, Button, LoadingAnimation, SearchBar, MessageBar, Pagination } from 'app/components';
import { usePermission } from 'app/permissions';
import ExportItem from '../ExportItem';
import './index.scss';

const ExportsView = () => {
  const dispatch = useDispatch();
  const [allExports, setAllExports] = useState(null);

  const exportsData = useSelector(exportsSelector);
  const exportsLoading = useSelector(exportsLoadingSelector);
  const exportsErrors = useSelector(exportsErrorsSelector);
  const runExportLoading = useSelector(runExportLoadingSelector);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '10', 10);
  const searchString = searchParams.get('searchString') || '';

  const canCreateExports = usePermission('insight', 'report_create');

  useEffect(() => {
    dispatch(getAllExports({ 
      page: currentPage, 
      pageSize,
      q: searchString || undefined
    }));
  }, [currentPage, pageSize, searchString, dispatch]);

  useEffect(() => {
    if (exportsData) {
      setAllExports(exportsData.reports);
    }
  }, [exportsData]);

  const onSearchStringUpdated = (searchString) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    searchString ? updatedSearchParams.set('searchString', searchString) : updatedSearchParams.delete('searchString');
    updatedSearchParams.set('page', '1');
    setSearchParams(updatedSearchParams.toString());
  };

  const exports = allExports || [];

  return (
    <div className="exports-list">
      {(exportsLoading || runExportLoading) && <LoadingAnimation />}
      {(allExports || exportsErrors) && (
        <div className="exports-container">
          <SearchBar
            searchPlaceholderText="Search Exports"
            onSearchStringUpdated={onSearchStringUpdated}
            disabled={exportsErrors ? true : false}
            debounceDelay={500}
          />
          {exports.length === 0 && !exportsErrors && (
            <MessageBar>
              {`No exports found. ${canCreateExports ? `Click the "Create Export" button above to create an export.` : ''}`}
            </MessageBar>
          )}
          {exports.length > 0 && (
            <div className="export-item-container">
              {exports.map((export_) => (
                <ExportItem key={export_.id} data={export_} />
              ))}
              {exportsData?.total > 10 && (
                <Pagination
                  currentPage={currentPage}
                  pageSize={pageSize}
                  totalItems={exportsData?.total}
                  pageSizes={[5, 10, 20, 50]}
                  hideBelow={10}
                />
              )}
            </div>
          )}
          {exportsErrors && (
            <Card>
              <MessageBar color="yellow" className="preview-error-message">
                The Data Warehouse is booting up. Please try again shortly.{' '}
                <Button
                  variant="primary"
                  size="tiny"
                  label="Retry"
                  onClick={() => dispatch(getAllExports({ page: currentPage, pageSize, searchString }))}
                />
              </MessageBar>
            </Card>
          )}
        </div>
      )}
    </div>
  );
};

export default ExportsView;