import React, { useState } from 'react';
import { Card, Table, TableHeader, Pagination, AuditInfoModal, Link, StatusChip } from 'app/components'
import moment from 'moment';
import './index.scss';

const WebhookLogs = (props) => {
  const { auditInfo, sortBy, sortDir} = props;

  const [viewAuditInfo, setViewAuditInfo] = useState(null);

  return (
    <Card className="webhook-logs">
      <Card.Header>
        Logs
      </Card.Header>
      <Card.Body>
        <Table size="small" verticalAlign="top" highlightRows={false}>
          <TableHeader
            sortBy={sortBy}
            sortDir={sortDir}
            options={[
              { id: 'dateCreated', label: 'Date Created', orderable: true },
              { id: 'id', label: 'Log ID' },
              { id: 'userId', label: 'User ID' },
              { id: 'eventType', label: 'Event Type' },
              { id: 'status', label: 'Status', align: "center" },
              { id: 'action', label: 'Action' },
            ]}
          />
          <tbody className="table-body">
            {auditInfo.events
              .sort((a, b) => b.version - a.version)
              .map((log) => {
              let resource = {};
              try {
                resource = JSON.parse(log.resource);
              } catch (error) {
                console.error(`Failed to parse resource for log ${log.id}:`, error);
              }
              return (
                <tr className="history-row" key={log.requestId}>
                  <td>{moment(log.version).local().format('MMM DD, YYYY h:mm:ss a')}</td>
                  <td>{log.id}</td>
                  <td>{log.userId}</td>
                  <td>{resource?.Event?.EventType || 'No event type provided'}</td>
                  <td className="webhook-status"><StatusChip type="webhooks" status={log.action} /></td>
                  <td className="actions">
                    <Link
                      label="View Log"
                      onClick={() => {
                        const data = [
                          {
                            resource: log.resource,
                          }
                        ]
                        setViewAuditInfo(data)
                      }}
                    />
                  </td>
                </tr>
              )
            })}
            {auditInfo.events.length === 0 && (
              <tr>
                <td colSpan="6" className="no-results">No logs found</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination totalItems={auditInfo.events.total || 0} />
      </Card.Body>
      {viewAuditInfo !== null && (
        <AuditInfoModal
          title="Audit Log Details"
          auditInfo={viewAuditInfo}
          onClose={() => setViewAuditInfo(null)}
        />
      )}
    </Card>
  )
}

export default WebhookLogs;