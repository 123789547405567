import { put, takeEvery, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getAllTransformations,
  getAllTransformationsSuccess,
  getAllTransformationsFailure,
  getTransformationDetails,
  getTransformationDetailsSuccess,
  getTransformationDetailsFailure,
  createTransformation,
  createTransformationSuccess,
  createTransformationFailure,
  addCustomizationToTransformation,
  addCustomizationToTransformationSuccess,
  addCustomizationToTransformationFailure,
  testDeployedTransformation,
  testDeployedTransformationSuccess,
  testDeployedTransformationFailure,
  getAuditInfo,
  getAuditInfoSuccess,
  getAuditInfoFailure,
} from "app/store/actions/transformation";
import { getWebhookDetailsSuccess } from "app/store/actions/notification";
import TransformationServices from 'app/services/transformationServices';
import NotificationServices from 'app/services/notificationServices';

function* fetchAllTransformations(action) {
  const { searchString, currentPage, pageSize, sortBy, sortDir } = action.payload;

  try {
    const resp = yield call([TransformationServices, TransformationServices.getAllTransformations], searchString, currentPage, pageSize, sortBy, sortDir);

    yield put(getAllTransformationsSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getAllTransformationsFailure(error));
    toast.error("Loading transformations failed", {
      theme: 'colored',
    });
  }
}

function* fetchTransformationDetails(action) {
  const { transformationId } = action.payload;

  try {
    const resp = yield call([TransformationServices, TransformationServices.getTransformationDetails], transformationId);
    yield put(getTransformationDetailsSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getTransformationDetailsFailure(error));
    toast.error("Loading Transformations failed", {
      theme: 'colored',
    });
  }
}

function* doCreateTransformation(action) {
  const { data, cb } = action.payload;
  try {
    const resp = yield call([TransformationServices, TransformationServices.createTransformation], data);
    const resp2 = yield call([TransformationServices, TransformationServices.getTransformationDetails], data.creatorType, data.topic, data.transformationType, data.entityId);
    yield put(getTransformationDetailsSuccess(resp2));
    toast.success("Transformation successfully created", {
      theme: 'colored',
    });
    if(cb) cb(data, resp);
    yield put(createTransformationSuccess());
  } catch (error) {
    console.error('error', error);
    yield put(createTransformationFailure(error));
    toast.error("Transformation creation failed", {
      theme: 'colored',
    });
  }
}

function* doAddCustomizationToTranformation(action) {
  const { transformationId, data, webhookDetails, cb } = action.payload;

  try {
    // first create the transformation
    let transformationResponse = null;
    if (transformationId) {  
      transformationResponse = yield call([TransformationServices, TransformationServices.createSnippet], transformationId, data);
    } else {
      transformationResponse = yield call([TransformationServices, TransformationServices.createTransformation], data); 
    }

    // then promote the transformation
    yield call([TransformationServices, TransformationServices.promoteTransformation], transformationResponse.transformationId, transformationResponse.snippetId);

    // locate the event that matches the topic of the transformation and add the transformation id to the event
    const eventIndex = webhookDetails?.events?.findIndex(event => event.eventType === data.topic);

    // Create a new webhookDetails object with the updated events
    const updatedWebhookDetails = {
      ...webhookDetails,
      events: eventIndex >= 0
        ? webhookDetails.events.map((event, index) => 
            index === eventIndex 
              ? { 
                  ...event,
                  transformationId: transformationResponse.transformationId,
                  snippetId: transformationResponse.snippetId
                }
              : { ...event }
          )
        : [
            ...(webhookDetails.events || []).map(event => ({ ...event })),
            {
              eventType: data.topic,
              transformationId: transformationResponse.transformationId,
              snippetId: transformationResponse.snippetId
            }
          ]
    };

    // now update the webhook with the new event
    yield call([NotificationServices, NotificationServices.updateWebhook], updatedWebhookDetails);
    yield put(addCustomizationToTransformationSuccess(updatedWebhookDetails));
    // Update the notification store with the new webhook details
    yield put(getWebhookDetailsSuccess(updatedWebhookDetails));
    toast.success("Customization Added Successfully", {
      theme: 'colored',
      autoClose: 3000,
    });
    if (cb) cb(transformationResponse);
  } catch (error) {
    console.error('error', error);
    yield put(addCustomizationToTransformationFailure(error));
    toast.error("Customization Addition Failed", {
      theme: 'colored',
    });
  }
}

function* doTestDeployedTransformation(action) {
  const { data, cb } = action.payload;
  try {
    const resp = yield call([TransformationServices, TransformationServices.testDeployedTransformation], data);
    if(cb) cb(resp);
    yield put(testDeployedTransformationSuccess());
  } catch (error) {
    console.error('error', error);
    yield put(testDeployedTransformationFailure(error));
    if(cb) cb(error);
  }
}

function* doGetAuditInfo(action) {
  const { auditUrl, cb } = action.payload;
  try {
    const resp = yield call([TransformationServices, TransformationServices.getAuditInfo], auditUrl);
    yield put(getAuditInfoSuccess(resp));
    if(cb) cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(getAuditInfoFailure(error));
  }
}

function* watchData() {
  yield takeEvery(getAllTransformations().type, fetchAllTransformations);
  yield takeEvery(getTransformationDetails().type, fetchTransformationDetails);
  yield takeEvery(createTransformation().type, doCreateTransformation);
  yield takeEvery(addCustomizationToTransformation().type, doAddCustomizationToTranformation);
  yield takeEvery(testDeployedTransformation().type, doTestDeployedTransformation);
  yield takeEvery(getAuditInfo().type, doGetAuditInfo);
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}