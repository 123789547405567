import { put, takeEvery, call, all, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getAllQueries,
  getAllQueriesSuccess,
  getAllQueriesFailure,
  runQueryById,
  runQueryByIdSuccess,
  runQueryByIdFailure,
  getAllExports,
  getAllExportsSuccess,
  getAllExportsFailure,
  getExportRunHistory,
  getExportRunHistorySuccess,
  getExportRunHistoryFailure,
  getAvailableExportColumns,
  getAvailableExportColumnsSuccess,
  getAvailableExportColumnsFailure,
  createExport,
  createExportSuccess,
  createExportFailure,
  deleteExport,
  deleteExportSuccess,
  deleteExportFailure,
  runExport,
  runExportSuccess,
  runExportFailure,
  pollRunningExport,
  getExportPreviewData,
  getExportPreviewDataSuccess,
  getExportPreviewDataFailure,
  getSpecificExport,
  getSpecificExportSuccess,
  getSpecificExportFailure,
  updateExport,
  updateExportSuccess,
  updateExportFailure,
} from "app/store/actions/insight"
import InsightServices from 'app/services/insightServices';

function* fetchAllQueries() {
  try {
    const resp = yield call([InsightServices, InsightServices.getAllQueries]);
    yield put(getAllQueriesSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getAllQueriesFailure(error));
  }
}

function* doRunQueryById(action) {
  const { query: { queryId, runAs, merchantIds, vendorIds }, dateRange } = action.payload;

  try {
    const resp = yield call([InsightServices, InsightServices.runQueryById], queryId, runAs, dateRange, merchantIds, vendorIds);
    const refreshedAt = queryId === 'orders-count-by-status' ? new Date(resp.refreshedAt).toLocaleString() : null;
    yield put(runQueryByIdSuccess({ queryId, dateRange, resp, refreshedAt }));
  } catch (error) {
    yield put(runQueryByIdFailure({ queryId, dateRange, error }));
  }
}

function* fetchAllExports(action) {
  try {
    const { page, pageSize, q } = action.payload;
    const resp = yield call([InsightServices, InsightServices.getAllExports], { 
      page, 
      pageSize, 
      q
    });
    yield put(getAllExportsSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getAllExportsFailure(error));
  }
}

function* fetchExportRunHistory(action) {
  const { exportId, cb, skipPolling, page, pageSize } = action.payload;
  try {
    const resp = yield call([InsightServices, InsightServices.getExportRunHistory], exportId, page, pageSize);
    yield put(getExportRunHistorySuccess({ exportId, data: resp }));

    // Only check for running exports if we're not already polling
    if (!skipPolling) {
      let runId = null;
      resp.runs.forEach(run => {
        if (run.status === 'Running' || run.status === 'Pending') {
          runId = run.id;
        }
      });

      if (runId) {
        // Start polling for export completion
        yield put(pollRunningExport({ exportId, runId, cb }));
      }
    }

    if (cb) cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(getExportRunHistoryFailure({ exportId, error }));
  }
}

function* fetchAvailableExportColumns(action) {
  try {
    const { runAs } = action.payload;
    const resp = yield call([InsightServices, InsightServices.getAvailableExportColumns], runAs);
    yield put(getAvailableExportColumnsSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getAvailableExportColumnsFailure(error));
  }
}

function* doCreateExport(action) {
  const { data, showToast = true, cb } = action.payload;
  try {
    const resp = yield call([InsightServices, InsightServices.createExport], data);
    
    // Automatically run the export after creation
    yield put(runExport({ 
      exportId: resp.id, 
      runAs: data.runAs, 
      merchantIds: data.merchantIds, 
      vendorIds: data.vendorIds 
    }));

    yield put(createExportSuccess(resp));

    if (showToast) {
      toast.success("Export Created Successfully", {
        theme: 'colored',
      });
    }
    if (cb) cb(resp.id);
  } catch (error) {
    yield put(createExportFailure(error));
    toast.error("Export Failed To Create", {
      theme: 'colored',
    });
  }
}

function* doUpdateExport(action) {
  const { exportId, data, showToast = true, cb } = action.payload;
  try {
    const resp = yield call([InsightServices, InsightServices.updateExport], exportId, data);
    
    // Automatically run the export after update
    yield put(runExport({
      exportId,
      runAs: data.runAs,
      merchantIds: data.merchantIds,
      vendorIds: data.vendorIds
    }));

    yield put(updateExportSuccess(resp));
    
    if (showToast) {
      toast.success("Export Updated Successfully", {
        theme: 'colored',
      });
    }
    if (cb) cb();
  } catch (error) {
    yield put(updateExportFailure(error));
    toast.error("Export Failed To Update", {
      theme: 'colored',
    });
  }
}

function* doDeleteExport(action) {
  const { exportId } = action.payload;
  
  try {
    yield call([InsightServices, InsightServices.deleteExport], exportId);
    yield put(deleteExportSuccess(exportId));

    try {
      const resp = yield call([InsightServices, InsightServices.getAllExports], { 
        page: 1, 
        pageSize: 10, 
        q: ''
      });
      yield put(getAllExportsSuccess(resp));
    } catch (error) {
      // Silently ignore errors
    }
    
    toast.success('Export deleted successfully', {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(deleteExportFailure(error));
    toast.error('Failed to delete export', {
      theme: 'colored',
    });
  }
}

function* doRunExport(action) {
  const { exportId, runAs, merchantIds, vendorIds, cb, page = 1, pageSize = 3 } = action.payload;
  let runId = null;
  try {
    const resp = yield call([InsightServices, InsightServices.runExport], exportId, runAs, merchantIds, vendorIds);
    runId = resp.id;
    // Update the run history for this export and wait for it to complete
    yield call(fetchExportRunHistory, { 
      payload: { 
        exportId, 
        skipPolling: true,
        page,
        pageSize
      } 
    });
    yield put(runExportSuccess({ exportId, resp }));
  } catch (error) {
    yield put(runExportFailure({ exportId, error }));
    toast.error("Export Failed To Run", {
      theme: 'colored',
    });
    return;
  }

  try {
    // Start polling for export completion
    let isCompleted = false;
    let attemptCount = 0;

    while (!isCompleted) {
      // Keep first 5 attempts at 1 second, then start increasing
      // After 5 attempts, multiply by small factor (1.2) and add attempt count in seconds
      // This gives us roughly: 1s, 1s, 1s, 1s, 1s, 1.8s, 2.9s, 4.3s, 6.2s, 8.6s, 11.8s, etc.
      const delayMs = attemptCount < 5
        ? 1000
        : Math.min(1000 * (Math.pow(1.2, attemptCount - 5) + (attemptCount - 5)), 60000);
      yield delay(delayMs);

      const runDetails = yield call([InsightServices, InsightServices.getExportRunDetails], exportId, runId);
      attemptCount++;

      if (runDetails?.run?.status === 'Completed' || runDetails?.run?.status === 'Failed') {
        isCompleted = true;

        yield call(fetchExportRunHistory, { 
          payload: { 
            exportId, 
            skipPolling: true,
            page,
            pageSize,
            cb: cb ? () => cb(runId) : undefined
          } 
        });
      }
    }
  } catch (error) {
    yield put(runExportFailure({ exportId, error }));
    toast.info("Export completion check failed.  Refresh the page.", {
      theme: 'colored',
    });
    return;
  }
}

function* doPollRunningExport(action) {
  const { exportId, runId, cb } = action.payload;
  try {
    let isCompleted = false;
    let attemptCount = 0;

    while (!isCompleted) {
      const delayMs = attemptCount < 5
        ? 3000
        : Math.min(1000 * (Math.pow(1.2, attemptCount - 5) + (attemptCount - 5)), 60000);
      yield delay(delayMs);

      const runDetails = yield call([InsightServices, InsightServices.getExportRunDetails], exportId, runId);
      attemptCount++;

      if (runDetails?.run?.status === 'Completed' || runDetails?.run?.status === 'Failed') {
        isCompleted = true;

        yield put(getExportRunHistory({
          exportId,
          skipPolling: true,
          cb: cb ? () => cb(runId) : undefined
        }));
      }
    }
  } catch (error) {
    console.error('Polling error:', error);
  }
}

function* fetchExportPreviewData(action) {
  const data = action.payload;
  try {
    const resp = yield call([InsightServices, InsightServices.getExportPreviewData], data);
    yield put(getExportPreviewDataSuccess(resp));
  } catch (error) {
    yield put(getExportPreviewDataFailure(error));
  }
}

function* fetchSpecificExport(action) {
  try {
    const resp = yield call([InsightServices, InsightServices.getSpecificExport], action.payload);
    if (resp?.reports?.length > 0 && resp.reports[0].report) {
      yield put(getSpecificExportSuccess(resp.reports[0].report));
    } else {
      console.error('Unexpected response while fetching specific export:', resp);
      yield put(getSpecificExportFailure(new Error('Unexpected response')));
    }
  } catch (error) {
    console.error('error', error);
    yield put(getSpecificExportFailure(error));
  }
}

function* watchData() {
  yield all([
    takeEvery(getAllQueries, fetchAllQueries),
    takeEvery(runQueryById, doRunQueryById),
    takeEvery(getAllExports, fetchAllExports),
    takeEvery(getExportRunHistory, fetchExportRunHistory),
    takeEvery(getAvailableExportColumns, fetchAvailableExportColumns),
    takeEvery(createExport, doCreateExport),
    takeEvery(updateExport, doUpdateExport),
    takeEvery(deleteExport, doDeleteExport),
    takeEvery(runExport, doRunExport),
    takeEvery(pollRunningExport, doPollRunningExport),
    takeEvery(getExportPreviewData, fetchExportPreviewData),
    takeEvery(getSpecificExport, fetchSpecificExport),
  ]);
}

function* rootSaga() {
  yield all([
    watchData(),
  ]);
}

export default rootSaga;