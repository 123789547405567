import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotes } from "app/store/actions/note";
import { notesDataSelector, notesErrorsSelector, noteCreationSuccessSelector } from "app/store/selectors/note";
import { Note, NoteEditor, Pagination } from 'app/components';
import { FileText, Pencil } from "react-bootstrap-icons";
import PropTypes from "prop-types";
import "./index.scss";

const NoteList = ({ entityId, entityType, isPrivate, merchantId }) => {
  const dispatch = useDispatch();
  const notesData = useSelector(notesDataSelector);
  const notesDataError = useSelector(notesErrorsSelector);
  const noteCreationSuccess = useSelector(noteCreationSuccessSelector);
  const [page, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  
  useEffect(() => {
    dispatch(getNotes({ entityId, entityType, isPrivate, page, pageSize }));
  }, [dispatch, entityId, entityType, isPrivate, noteCreationSuccess, page, pageSize]);

  return (
    <div className="notes-list-note-card-data">
      {notesData && notesData.notes?.length > 0 ? (
        <div className="notes-list-note-card-data">
          {notesData.notes.map((note) => (
            <div className="note-row" key={note.id}>
              <div className="note-row-left">
                <div className="note-icon">{note.isBeingEdited ? <Pencil /> : <FileText />}</div>
                <div className="vertical-line" />
              </div>
              <div className="note-row-right">
                {note.isBeingEdited ? (
                  <NoteEditor
                    entityId={entityId}
                    entityType={entityType}
                    merchantId={merchantId}
                    vendorId={null}
                    editableNote={note}
                  />
                ) : (
                  <Note note={note} />
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        notesData?.notes?.length === 0 && <div>No notes found.</div>
      )}
      {notesDataError && <div>{notesDataError}</div>}
      <Pagination
          totalItems={notesData?.total || 0}
          pageSizes={[3, 10, 50]}
          hideBelow={3}
          currentPage={page}
          pageSize={pageSize}
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          }}
        />
    </div>
  );
};

NoteList.propTypes = {
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  isPrivate: PropTypes.bool,
  merchantId: PropTypes.string,
};

export default NoteList;
