import React, { useEffect, useState, useMemo } from 'react';
import { ButtonIcon, StatusChip } from 'app/components';
import { ThreeDotsVertical, Download } from 'react-bootstrap-icons';
import { getExportRunHistory } from 'app/store/actions/insight';
import { exportRunHistoryByIdSelector } from 'app/store/selectors/insight';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LoadingIcon } from "app/assets/images/icons/loading.svg";
import { toast } from 'react-toastify';
import DeleteExportModal from './DeleteExportModal';
import moment from 'moment';
import './index.scss';

const ExportItem = (props) => {
  const { data } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const exportHistory = useSelector(exportRunHistoryByIdSelector(data.id));

  const [highlightedRunId, setHighlightedRunId] = useState(null);
  const [deleteExportModal, showDeleteExportModal] = useState(false);
  const [downloadingRunIds, setDownloadingRunIds] = useState([]);

  const latestRun = useMemo(() => {
    return exportHistory?.data?.runs?.[0] || null;
  }, [exportHistory?.data?.runs]);

  const handleRunIdCallback = (runId) => {
    if (runId) {
      setHighlightedRunId(runId);
      // Ensure animation has time to complete
      setTimeout(() => setHighlightedRunId(null), 2500);
    }
  };

  useEffect(() => {
    dispatch(getExportRunHistory({
      exportId: data.id,
      page: 1,
      pageSize: 1,
      cb: handleRunIdCallback
    }));
  }, []);

  const exportOptions = () => [
    {
      label: 'Edit Export',
      value: 'editExport',
      onClick: () => {
        navigate(`/admin/exports/${data.id}`);
      }
    },
    {
      label: 'Delete Export',
      value: 'deleteExport',
      destructive: true,
      onClick: () => {
        showDeleteExportModal(true);
      }
    }
  ];

  const handleDownload = async (downloadUrl, name, runId) => {
    try {
      setDownloadingRunIds(prev => [...prev, runId]);
      const response = await fetch(downloadUrl);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      // Create anchor element
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `${name || 'export'}.csv`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Cleanup blob URL
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      toast.error("Error downloading file", { theme: 'colored' });
    } finally {
      setDownloadingRunIds(prev => prev.filter(id => id !== runId));
    }
  };

  return (
    <div className={`export-item ${highlightedRunId === latestRun?.id ? 'highlight-row' : ''}`}>
      <div className="left-side">
        <div className="export-title" onClick={() => navigate(`/admin/exports/${data.id}`)}>
          {data.name || 'Unnamed Export'}
        </div>
        <div className="created-by">Created By: {data.createdBy || '-'}</div>
      </div>
      <div className="created-date">Created Date: {data.createdAt ? moment(data.createdAt).local().format('MMM DD, YYYY h:mm:ss a') : '-'}</div>
      <div className="right-side">
        {latestRun?.status ? (
          <StatusChip
            size="small"
            type="export"
            status={latestRun?.status}
            iconLeft={latestRun?.status === 'Running' || latestRun?.status === 'Pending' ? <LoadingIcon /> : null}
            iconRight={latestRun?.generatedFileUrl ? downloadingRunIds.includes(latestRun?.id) ? <LoadingIcon className="spin" /> : <Download /> : null}
            onClick={latestRun?.generatedFileUrl ? () => handleDownload(latestRun?.generatedFileUrl, data.name, latestRun?.id) : null}
          />
        ) : (
          <StatusChip
            size="small"
            type="export"
            status="Loading"
            iconLeft={<LoadingIcon />}
          />
        )}
        <ButtonIcon
          icon={<ThreeDotsVertical />}
          options={exportOptions()}
        />
      </div>
      {deleteExportModal && (
        <DeleteExportModal
          exportData={data}
          showDeleteExportModal={showDeleteExportModal}
        />
      )}
    </div>
  );
};

export default ExportItem;