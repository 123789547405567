import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Card, LoadingAnimation, TabbedMenu, NoteList, NoteEditor } from 'app/components';
import { noteLoadingSelector, notesLoadingSelector } from 'app/store/selectors/note';
import './index.scss';

const NotesView = ({ entityType, entityId, merchantId, vendorId }) => {
  const noteLoading = useSelector(noteLoadingSelector);
  const notesLoading = useSelector(notesLoadingSelector);

  const tabs = [
    {
      label: 'All',
      path: '',
      query: 'all',
      content: NoteList,
      props: { entityId, entityType, isPrivate: null },
    },
    {
      label: 'Public Notes',
      path: 'public',
      query: 'public',
      content: NoteList,
      props: { merchantId, entityId, entityType, isPrivate: false },
    },
    {
      label: 'Private Notes',
      path: 'private',
      query: 'private',
      content: NoteList,
      props: { merchantId, entityId, entityType, isPrivate: true },
    },
  ];

  return (
    <Card className="notes-view">
      <Card.Header>Note Details</Card.Header>
      {(notesLoading || noteLoading) && <LoadingAnimation />}

      <Card.Body>
        <NoteEditor
          entityId={entityId}
          entityType={entityType}
          merchantId={merchantId}
          vendorId={vendorId}
        />

        <TabbedMenu tabs={tabs} showTabs={true} queryKey="notesTab" />
      </Card.Body>
    </Card>
  );
};

NotesView.propTypes = {
  entityType: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  merchantId: PropTypes.string,
  vendorId: PropTypes.string,
};

export default NotesView;
