import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, ToggleSwitch, Input, Dropdown } from 'app/components';
import { getMerchantSchema, getConfiguration, updateMerchantConfiguration } from 'app/store/actions/merchant';
import {
  configurationSelector, configurationLoadingSelector, schemaSelector, schemaLoadingSelector,
  settingsSelector, SchemaTypes
} from 'app/store/selectors/merchant';
import { LoadingAnimation } from 'app/components'
import debounce from 'lodash/debounce';

import './index.scss';

const SettingsView = props => {
  const { merchantId } = props;
  const dispatch = useDispatch();
  const configuration = useSelector(configurationSelector);
  const schema = useSelector(schemaSelector);
  const settings = useSelector(settingsSelector);
  const configLoading = useSelector(configurationLoadingSelector);
  const schemaLoading = useSelector(schemaLoadingSelector)
  const [localValues, setLocalValues] = useState(configuration?.data || {});

  const debouncedUpdate = useCallback(
    debounce((key, value, latestConfiguration, latestSchema, inputType) => {
      if (!latestSchema) {
        console.warn("schema is null");
        return;
      }

      const updatedConfig = JSON.parse(JSON.stringify(latestConfiguration));
      updatedConfig.data[key] = inputType === 'number' ? parseInt(value, 10) : value;
      updatedConfig.schemaVersion = latestSchema.version;

      dispatch(updateMerchantConfiguration({ data: updatedConfig, cb: handleConfigurationUpdate }));
    }, 1500), []);

  useEffect(() => {
    setLocalValues(configuration?.data || {});
  }, [configuration]);

  useEffect(() => {
    if (merchantId) {
      dispatch(getMerchantSchema());
      dispatch(getConfiguration({ merchantId: merchantId }));
    }
  }, [merchantId]);

  const handleConfigurationUpdate = () => {
    dispatch(getConfiguration({ merchantId: merchantId }));
  }

  const handleToggleChange = (obj, value) => {
    const updatedConfig = JSON.parse(JSON.stringify(configuration));

    switch (obj.parentKey) {
      default:
        updatedConfig.data[obj.key] = value;
        break;
    }
    updatedConfig.schemaVersion = schema.version;
    dispatch(updateMerchantConfiguration({ data: updatedConfig, cb: handleConfigurationUpdate }));
  }

  const handleInputChange = useCallback((obj, e) => {
    const newValue = e.target.value;
    const inputType = e.target.type;

    setLocalValues((prev) => ({
      ...prev,
      [obj.key]: newValue,
    }));

    debouncedUpdate(obj.key, newValue, configuration, schema, inputType);
  }, [schema, configuration]);

  const handleDropdownChange = (obj, e) => {
    const allKeysInDropdown = obj.subSection.settings.map(m => m.key);
    const selectedKey = e.target.value;

    // only selectedKey set to true, rest to false
    const updatedConfig = JSON.parse(JSON.stringify(configuration));
    allKeysInDropdown.forEach(key => {
      updatedConfig.data[key] = key === selectedKey;
    });
    updatedConfig.schemaVersion = schema.version;
    dispatch(updateMerchantConfiguration({ data: updatedConfig, cb: handleConfigurationUpdate }));
  }

  const renderSettingsItem = (item) => {
    const dataSource = configuration?.data;

    const toggleItem =
      <div className='settings-item-bool'>
        <ToggleSwitch
          isOn={dataSource[item?.key]}
          onToggle={handleToggleChange.bind(this, { key: item?.key })}
        />
        <div className='settings-item-title'>{item?.description}</div>
      </div>

    const inputItem =
      <div className='settings-item-input'>
        <div className='settings-item-title'>{item?.description}</div>
        <Input
          type="text"
          className={'settings-item-input-child'}
          value={localValues[item?.key] || ""}
          onChange={handleInputChange.bind(this, { key: item?.key })}
        />
      </div>

    const integerItem =
      <div className='settings-item-integer'>
        <div className='settings-item-title'>{item?.description}</div>
        <Input
          type="number"
          className={'settings-item-integer-child'}
          value={localValues[item?.key] || 0}
          onChange={handleInputChange.bind(this, { key: item?.key })}
        />
      </div>

    switch (item?.type) {
      case SchemaTypes.BOOLEAN:
        return toggleItem;
      case SchemaTypes.INTEGER:
        return integerItem;
      case SchemaTypes.STRING:
        return inputItem;
      default:
        return null;
    }
  }

  const renderDropdown = subSection => {
    const dataSource = configuration?.data;
    const allKeysInDropdown = subSection?.settings.map(m => m.key);
    const selectedKey = allKeysInDropdown.find(key => dataSource[key]);

    const options = subSection?.settings.map(m => {
      return {
        value: m.key,
        label: m.description
      }
    });

    return (
      <Dropdown
        className='settings-item-dropdown'
        name="dropdownSettings"
        value={selectedKey}
        disabled={false}
        onChange={handleDropdownChange.bind(this, { subSection })}
        options={options || []}
      />
    );
  }

  const renderSubSection = item => {
    if (!item.subSections?.length) return null;

    return item.subSections.map(subSection => {
      return (
        <div className='settings-sub-section' key={subSection.title}>
          <div className='settings-sub-section-title'>{subSection.title}</div>
          {subSection.type === SchemaTypes.DROPDOWN ?
            renderDropdown(subSection) :
            subSection.settings.map(m => renderSettingsItem(m))
          }
        </div>
      )
    })
  }

  const renderSettings = item => {
    return (
      <div className='settings-holder'>
        {renderSubSection(item)}
        {item.settings.map(m => renderSettingsItem(m))}
      </div>
    )
  }

  return (
    <div className="merchant-settings-view">
      {(configLoading || schemaLoading) && <LoadingAnimation />}
      {settings.map(item => {
        return (
          <Card key={item.title}>
            <Card.Header>
              {item.title}
            </Card.Header>
            {renderSettings(item)}
          </Card>
        )
      })}
    </div>
  )
}

export default SettingsView;