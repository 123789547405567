import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { deleteRoutingStrategyDataSet, getRoutingStrategyDataSet, getRoutingStrategyDataSets } from "app/store/actions/routing";
import { getDataSetLoadingSelector, getDataSetSelector, getDataSetsLoadingSelector, getDataSetsSelector } from "app/store/selectors/routing";
import { LoadingAnimation, Table, TableHeader, ButtonIcon, Card, Button, Modal } from "app/components";
import { ThreeDotsVertical } from "react-bootstrap-icons";

import "./index.scss";
import { dataSetCSVExampleUrl } from "../UploadCSV";
import { convertDataSetToCSV, downloadCSV } from "app/pages/Routing/utils";

const DataSets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { strategyId, merchantId } = useParams();

  const [deleteDataSet, setDeleteDataSet] = useState(false);
  const [selectedDataSet, setSelectedDataSet] = useState(null);

  const isLoading = useSelector(getDataSetsLoadingSelector);
  const isLoadingSingleDataSet = useSelector(getDataSetLoadingSelector);
  const dataSets = useSelector(getDataSetsSelector);
  const dataSetWithVars = useSelector(getDataSetSelector);

  useEffect(() => {
    dispatch(getRoutingStrategyDataSets({ strategyId }));
  }, [strategyId]);

  const handleUploadDataSetCSVClick = () => {
    navigate(`/admin/routing/${merchantId}/${strategyId}/upload-csv`);
  };

  const handleDownloadTemplate = useCallback(() => {
    window.open(dataSetCSVExampleUrl, '_blank');
  }, []);

  const handleGetDataSet = (dataSet) => {
    dispatch(getRoutingStrategyDataSet({
      strategyId: dataSet?.routingStrategyId,
      dataSetId: dataSet?.id,
      cb: () => {
        const csv = convertDataSetToCSV(dataSetWithVars);
        if (csv !== null && csv !== undefined) {
          downloadCSV(csv, `${dataSet?.name}.csv`);
        }
      }
    }));
  }

  const handleEditDataSet = (dataSet) => {
    navigate(`/admin/routing/${merchantId}/${dataSet?.routingStrategyId}/edit-data-set/${dataSet?.id}`);
  }

  const actionsMenu = (dataSet) => [
    {
      label: "Edit",
      value: "Edit",
      onClick: () => {
        handleEditDataSet(dataSet);
      },
    },
    {
      label: "Download",
      value: "Download",
      onClick: () => {
        handleGetDataSet(dataSet);
      },
    },
    {
      label: "Delete",
      value: "Delete",
      destructive: true,
      onClick: () => {
        setSelectedDataSet(dataSet);
        setDeleteDataSet(true);
      }
    },
  ];

  ////////////////////////////
  // UI Components
  ////////////////////////////

  const deleteDataSetModal = () => {
    return deleteDataSet && (
      <Modal
        secondaryButtonLabel="Cancel"
        secondaryButtonOnClick={() => {
          setDeleteDataSet(false);
          setSelectedDataSet(null);
        }}
        primaryButtonLabel="Yes, Delete It"
        primaryButtonVariant="primary"
        primaryButtonDestructive
        primaryButtonOnClick={() => {
          setDeleteDataSet(false);
          dispatch(
            deleteRoutingStrategyDataSet({
              strategyId: selectedDataSet?.routingStrategyId,
              dataSetId: selectedDataSet?.id,
              cb: () => {
                dispatch(getRoutingStrategyDataSets({ strategyId: selectedDataSet?.routingStrategyId }))
                setSelectedDataSet(null);
              }
            })
          );
        }}
        onClose={() => {
          setDeleteDataSet(false);
          setSelectedDataSet(null);
        }}
      >
        <div>
          Are you sure you want to delete {" "}
          <b>{selectedDataSet?.name}</b>?
        </div>
        <div>This action is not reversible.</div>
      </Modal>
    )
  }

  return <div className="strategy-data-sets">
    {(isLoading || isLoadingSingleDataSet) && <LoadingAnimation />}
    <Card>
      <div className="data-sets-header d-flex">
        <h5>Uploaded Data Sets</h5>
        <div className="d-flex">
          <Button
            className="mx-2"
            variant="secondary"
            size="small"
            onClick={handleUploadDataSetCSVClick}
            label={"Upload Data Set"} />
          <Button
            variant="secondary"
            size="small"
            onClick={handleDownloadTemplate}
            label={"Download Template"} />
        </div>
      </div>
      <Table
        className="mt-4"
        size="medium">
        <TableHeader
          options={[
            { id: "name", label: "Name", orderable: true },
            { id: "variables: ", label: "Variables", orderable: true },
            { id: "actions", label: "Actions", align: "center" }
          ]}
        />
        <tbody className="table-body">
          {dataSets && dataSets.map((dataSet) => (
            <tr className="data-set-row" key={dataSet?.id}>
              <td>{dataSet?.name}</td>
              <td>{dataSet?.variables?.filter(v => v.mapTo !== "facilityId").map(v => v.name).join(', ')}</td>
              <td className="data-set-actions-menu">
                <ButtonIcon
                  icon={<ThreeDotsVertical />}
                  options={actionsMenu(dataSet)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {deleteDataSetModal()}
    </Card>
  </div>;
};

export default DataSets;