import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { merchantDetailsSelector, merchantTemplatesLoadingSelector, merchantTemplatesSelector, templateLoadingSelector } from 'app/store/selectors/merchant';
import { deleteTemplate, getTemplates, renderTemplate } from 'app/store/actions/merchant';
import './index.scss';
import { Card, Button, Table, TableHeader, LoadingAnimation, Link, ButtonIcon, Modal } from 'app/components'
import { ThreeDotsVertical } from "react-bootstrap-icons";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { formatCategoryName } from './utils';
import { toast } from 'react-toastify';
import { usePermission } from 'app/permissions';

const TemplatesView = () => {
  const dispatch = useDispatch();
  const merchantDetails = useSelector(merchantDetailsSelector);
  const groupedTemplates = useSelector(merchantTemplatesSelector);
  const loading = useSelector(merchantTemplatesLoadingSelector);
  const loadingRender = useSelector(templateLoadingSelector);
  const [deleteTemplateModal, setDeleteTemplateModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const navigate = useNavigate();
  const canCreateTemplates = usePermission('templates', 'create');

  useEffect(() => {
    // fetch templates data
    if (merchantDetails?.id) {
      dispatch(getTemplates(merchantDetails.id));
    }
  }, [])

  const renderTableView = templates => {
    return (
      <Table
        size={"medium"}>
        <TableHeader
          options={[
            { label: 'Template Name', id: 'template-name' },
            { label: 'Template ID', id: 'template-id' },
            { label: 'Date Created', id: 'date-created' },
            { label: 'Actions', id: 'actions', align: "center" }
          ]} />
        <tbody className="table-body">
          {templates.map(template => renderItemView(template))}
        </tbody>
      </Table>
    )
  }

  const renderItemView = template => {

    return (
      <tr className='template-row' key={template.id}>
        <td>
          <Link
            url={`templates/create-template/${template.category}/${template.id}`}
            label={template.name}
            onClick={() => navigate(`templates/create-template/${template.category}/${template.id}`)}
          />
        </td>
        <td>{template.id}</td>
        <td>{moment(template.createdAt).format('MMM DD, YYYY')}</td>
        <td className="template-actions-menu">
          <ButtonIcon
            icon={<ThreeDotsVertical />}
            options={actionOptions(template)}
          />
        </td>
      </tr>
    )
  }

  const renderDeleteTemplateModal = () => {
    return (
      <Modal
        secondaryButtonLabel="Cancel"
        secondaryButtonOnClick={() => {
          setDeleteTemplateModal(false);
        }}
        primaryButtonLabel="Yes, Delete It"
        primaryButtonVariant="primary"
        primaryButtonDestructive
        primaryButtonOnClick={() => {
          setDeleteTemplateModal(false);
          dispatch(deleteTemplate(
            {
              templateId: selectedTemplate.id,
              merchantId: merchantDetails.id
            }));
        }}
        onClose={() => {
          setDeleteTemplateModal(false);
        }}
      >
        <div>
          Are you sure you want to delete template{" "}
          <b>{selectedTemplate?.name}</b>?
        </div>
        <div>This action is not reversible.</div>
      </Modal>)
  }

  const handleRenderTemplate = (file) => {
    window.open(file?.fileInfo?.url);
  }

  const handlePreviewTemplate = template => {
    if (template) {
      dispatch(renderTemplate({
        templateId: template.id,
        cb: handleRenderTemplate
      }))
    } else {
      toast.error("Please save the template first.")
    }
  }

  const actionOptions = template => [
    {
      label: 'Edit',
      value: 'edit',
      onClick: () => navigate(`templates/create-template/${template.category}/${template.id}`)
    },
    {
      label: 'Preview',
      value: 'preview',
      onClick: () => handlePreviewTemplate(template)

    },
    {
      label: 'Delete', destructive: true,
      value: 'delete',
      onClick: () => {
        setSelectedTemplate(template);
        setDeleteTemplateModal(true);
      }
    }
  ]

  return (
    <div className='merchant-templates-view'>
      {(loading || loadingRender) && <LoadingAnimation />}
      {groupedTemplates && Object.keys(groupedTemplates).filter(key => key == 'packing-slip').map(key => {
        return (
          <Card key={key}>
            <Card.Header>
              {formatCategoryName(key)}
              {canCreateTemplates && <Button label='Add New Template' onClick={() => navigate(`templates/create-template/${key}`)} />}
            </Card.Header>
            {groupedTemplates[key].length ? renderTableView(groupedTemplates[key]) : 'No templates found'}
          </Card>
        )
      })}
      {groupedTemplates && !Object.keys(groupedTemplates).includes('packing-slip') &&
         (
          <Card key={'packing-slip'}>
            <Card.Header>
              {"Packing slip"}
              {canCreateTemplates && <Button label='Add New Template' onClick={() => navigate(`templates/create-template/${'packing-slip'}`)} />}
            </Card.Header>
            {'No templates found'}
          </Card>
        )
      }
      {deleteTemplateModal && renderDeleteTemplateModal()}
    </div>
  );
}

export default TemplatesView;